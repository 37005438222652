<template>
  <special :repoType="repoType"></special>
  <div class="flex m-auto .mian-min-h repoMain">
    <div v-if="repoType !== 'space'" class="w-[320px] border-r border-[#DCDFE6]  p-[16px]">
      <div class="leftBox">
        <div class="titleBox" >
          <span class="buttonCardTitle"><span class="buttonBoxIcon" />{{getRepoType(repoType)}}分类</span>
          <div class="buttonBox" v-if="['model', 'dataset'].includes(repoType)">
            <span :class="activationTab ? 'tabButton activation' : 'tabButton noAtivation'"
              @click="TabClick(true)">按功能</span>
            <span :class="!activationTab ? 'tabButton activation' : 'tabButton noAtivation'"
              @click="TabClick(false)">按行业</span>
          </div>
        </div>
        <div class="leftTagBox">
          <TagSidebar :taskTags="formatTaskTags" :frameworkTags="props.frameworkTags" :languageTags="props.languageTags"
            :licenseTags="props.licenseTags" :selectedTag="props.selectedTag" :selectedTagType="props.selectedTagType"
            @resetTags="resetTags" :type="repoType" v-if="activationTab" />
          <template v-else>
            <industrySidebar :industryTagsList="industryTagsList" :SelectedID="SelectedID"
              @industryClick="industryClick" />
          </template>
        </div>
      </div>
    </div>
    <div class="p-[16px]  position right-box">
      <div class="inputBox">
        <el-popover placement="bottom" :width="400" :visible="popoverVisible" popper-class="popoverCss" :offset="1">
          <template #reference>
            <el-input :placeholder="'搜搜你想要的' + getRepoType(repoType) + '仓库'" v-model="nameFilterInput"
              :suffix-icon="Search" class="w-[400px] h-[32px] searchInput" clearable @focus="popoverVisible = true"
              @blur="popoverVisible = false" @input="searchChange" @keyup.enter.native="handleKeyPress($event)" />
          </template>
          <div class="popoveBox">
            <div class="popoverMainBox">
              <searchLiTag v-for="item in searchList" :item="item" :key="item.id" :repo-type="repoType"></searchLiTag>
            </div>
            <div class="popoverBottmText">
              回车查看{{ getRepoType(repoType) }}仓库搜索结果
            </div>
          </div>
        </el-popover>
      </div>
      <div class="searchTips" v-if="searchTips != ''">
        共找到<span class="text-[#2047D3]">{{ reposData ? reposData.length: 0  }}</span>个结果 <span class="searchTipsTag">{{ nameFilterInput }}
          <el-icon @click="clearSearch">
            <Close />
          </el-icon></span>
        <sapn style="cursor: pointer;color:#2047D3; " @click="clearSearch">清空</sapn>
      </div>
      <div v-if="repoType === 'space'"
        class="grid grid-cols-3 xl:grid-cols-2 md:grid-cols-1 gap-6 mb-4 mt-[16px] xl:pl-[20px] mlg:pl-0">
        <application-space-item v-for="repo in reposData" :repo="repo" :repo-type="repoType" />
      </div>
      <div v-else class="w-[100%] gap-[16px] main-box " :key="repoItemKey">
        <noData v-if="!reposData"  />
        <repo-item v-for="repo in reposData" :repo="repo" :repo-type="repoType" v-else />
      </div>
      <CsgPagination :perPage="perPage" :currentPage="currentPage" @currentChange="currentChange" :total="totalRepos" />
    </div>
  </div>
</template>
<script setup lang="ts">
import { onMounted, ref, computed, inject, onUnmounted } from 'vue'
import { Search } from '@element-plus/icons-vue'
import { ElInput, ElMessage } from 'element-plus'
import RepoItem from '../shared/RepoItem.vue'
import ApplicationSpaceItem from '../application_spaces/ApplicationSpaceItem.vue'
import TagSidebar from '../tags/TagSidebar.vue'
import CsgPagination from './CsgPagination.vue'
import { useI18n } from 'vue-i18n'
import jwtFetch from '../../packs/jwtFetch'
import noData from './noData.vue'
import special from './special.vue'
import { getRepoType } from '../../stores/utils.js'
import searchLiTag from './searchLiTag.vue'
import industrySidebar from './industrySidebar.vue'

const props = defineProps({
  taskTags: String,
  frameworkTags: String,
  languageTags: String,
  licenseTags: String,
  selectedTag: String,
  selectedTagType: String,
  repoType: String
})
const repoItemKey = ref(0)

const formatTaskTags = ref('')
const onPremise = inject('onPremise', 'true')
const csghubServer = inject('csghubServer')
const { t } = useI18n()
const nameFilterInput = ref('')
const sortSelection = ref('trending')
const sourceSelection = ref('all')
const currentPage = ref(1)
const totalRepos = ref(0)
const taskTag = ref('')
const frameworkTag = ref('')
const languageTag = ref('')
const licenseTag = ref('')
const industryTag = ref('')
const reposData = ref(Array)
const innerHeight = ref(0)
const activationTab = ref(true)
const industryTagsList = ref([])
const SelectedID = ref(null)
const popoverVisible = ref(false)
const searchTips = ref('')

const sortOptions = [
  {
    value: 'trending',
    label: t('all.trending')
  },
  {
    value: 'recently_update',
    label: t('all.recentlyUpdate')
  },
  {
    value: 'most_download',
    label: t('all.mostDownload')
  },
  {
    value: 'most_favorite',
    label: t('all.mostFavorite')
  }
]
const debounceTimer = ref(null)
const searchList = ref([])

const searchChange = () => {
  clearTimeout(debounceTimer.value);
  // 使用防抖来避免频繁请求接口
  debounceTimer.value = setTimeout(() => {
    searchApi()
  }, 500)
}

const searchApi = () => {
  const api = async (url) => {
    const response = await jwtFetch(url)
    if (!response.ok) {
      ElMessage({
        message: '加载模型数据报错',
        type: 'warning'
      })
    } else {
      response.json().then((res_json) => {
        searchList.value = res_json.data ? res_json.data.slice(0, 6) : []
      })
    }
  }
  reloadRepos(1, api)
}

const handleKeyPress = (event) => {
  if (event) {
    event.target.blur()
    console.log(nameFilterInput.value)
    searchTips.value = nameFilterInput.value
    filterChange()
  }
}


const getIndustryTags = async () => {
  const getIndustryOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  }
  const response = await jwtFetch(`${csghubServer}/api/v1/tags`, getIndustryOptions)
  if (!response.ok) {
    response.json().then(({ err }) => {
      ElMessage({ message: err.message, type: "warning" })
    })
  } else {
    response.json().then(({ data }) => {
      industryTagsList.value = data.filter(item => item.category === 'industry' && item.scope === props.repoType)
    })
  }
}

const industryClick = (item) => {
  if (item.id === SelectedID.value) {
    SelectedID.value = null
    industryTag.value = ''
  } else {
    SelectedID.value = item.id
    industryTag.value = item.name
  }
  // 清空按功能选中数据
  taskTag.value = ''
  reloadRepos()
}

const TabClick = (stata) => {
  if (stata === activationTab.value) return false
  // 切换状态
  activationTab.value = !activationTab.value
}

const getformatTaskTags = () => {
  const task = JSON.parse(props.taskTags)
  delete task.multimodal
  for (let key in task) {
    task[key].isOpen = false
  }
  formatTaskTags.value = task
}
const getInnerHeight = () => {
  innerHeight.value = window.innerHeight - 420
  // console.log(innerHeight)
}
const sourceOptions = [
  {
    value: 'all',
    label: t('repo.source.all')
  },
  {
    value: 'opencsg',
    label: t('repo.source.opencsg')
  },
  {
    value: 'local',
    label: t('repo.source.local')
  }
]

const perPage = computed(() => {
  if (props.repoType === 'space') {
    return 8
  } else {
    return 12
  }
})

const resetTags = (task, framework, language, license) => {
  taskTag.value = task
  frameworkTag.value = framework
  languageTag.value = language
  licenseTag.value = license
  // 清空按行业选中状态
  SelectedID.value = ''
  industryTag.value = ''
  reloadRepos()
}

const currentChange = (val) => {
  currentPage.value = val
  reloadRepos(val)
}
// 点击搜索后将页面默认修改未1
const filterChange = () => {
  currentPage.value = 1
  reloadRepos()
}

const clearSearch = () => {
  nameFilterInput.value = ''
  searchTips.value = ''
  filterChange()
}

const reloadRepos = (childCurrent, api) => {
  let url = `${csghubServer}/api/v1/${props.repoType}s`
  url = url + `?page=${childCurrent ? childCurrent : currentPage.value}`
  url = url + `&per=${perPage.value}`
  url = url + `&search=${nameFilterInput.value}`
  url = url + `&sort=${sortSelection.value}`
  url = url + `&task_tag=${taskTag.value}`
  url = url + `&framework_tag=${frameworkTag.value}`
  url = url + `&language_tag=${languageTag.value}`
  url = url + `&license_tag=${licenseTag.value}`
  url = url + `&industry_tag=${industryTag.value}`
  url = url + `&source=${sourceSelection.value === 'all' ? '' : sourceSelection.value}`
  api ? api(url) : loadRepos(url)
}



async function loadRepos(url) {
  const response = await jwtFetch(url)
  if (!response.ok) {
    ElMessage({
      message: '加载模型数据报错',
      type: 'warning'
    })
  } else {
    response.json().then((res_json) => {
      repoItemKey.value++
      reposData.value = res_json.data
      totalRepos.value = res_json.total
    })
  }
}

onMounted(() => {
  getInnerHeight()
  reloadRepos()
  getformatTaskTags()
  if (['model', 'dataset'].includes(props.repoType)) {
    getIndustryTags()
  }
  // window.addEventListener('keypress', handleKeyPress);
})
onUnmounted(() => {
  // window.removeEventListener('keypress', handleKeyPress);
})
</script>
<style scoped>
.inputBox {
  width: 100%;
  height: 64px;
  padding: 16px 0;
  position: sticky;
  top: 56px;
  backdrop-filter: blur(20px);
  background-color: rgba(255, 255, 255, 0.7);
}

.leftBox {
  position: sticky;
  top: 72px;
  left: 0;
  width: 100%;
  height: calc(100vh - 176px);
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.leftBox::-webkit-scrollbar{
  display: none;
}

.searchInput {
  height: 32px;
  width: 400px;
  --el-input-focus-border: #2047D3;
  --el-input-focus-border-color: #2047D3;
  --el-input-hover-border: #C9CDD4;
}

.searchInput:focus {
  box-shadow: none;
  border-color: #2047D3;
}

.searchInput .el-input-group__append .el-button {
  border: none !important;
  height: 30px !important;
  background-color: #2047D3 !important;
  color: #FFFFFF;
  font-weight: 400;
  border-radius: 0 4px 4px 0 !important;
}

.searchInput .el-input-group__append .el-button:hover {
  background-color: #4369DC !important;
  color: #FFFFFF;
  border-radius: 0 4px 4px 0 !important;
}

.position {
  position: relative;
}

.main-box {
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  padding-right: 8px;
  min-height: 448px;
}

.right-box {
  width: calc(100% - 320px);
  padding-top: 0px;
}

.titleBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  position: sticky;
  top: 0px;
  background-color: #FDFDFD;
  z-index: 10;
}

.buttonBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 128px;
  height: 32px;
  //padding: 4px;
  //background-color: #F2F3F5;
  border-radius: 2px;
}

.buttonCardTitle {
  font-family: PingFang SC;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #1D2129;
  display: flex;
  align-items: center;
}

.buttonBoxIcon {
  height: 16px;
  width: 2px;
  background-color: #2047D3;
  display: inline-block;
  margin-right: 8px;
}

.tabButton {
  width: 66px;
  height: 32px;
  border-radius: 2px;
  display: inline-block;
  font-family: PingFang SC;
  background-color: #F2F3F5;
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 400;
  line-height: 32px;
  text-align: center;
  cursor: pointer;
}

.noAtivation:hover {
  color: #2047D3;
}

.activation {
  background-color: #2047D3;
  color: #FFFFFF;
}

.industrySidebar {
  height: 36px;
  width: 100%;
  padding: 7px 8px;
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  cursor: pointer;
  color: #1D2129;
  border-radius: 4px;
}

.industrySidebar:hover {
  background-color: #F7F8FA;
}

.activationIndustry {
  background-color: #F2F3F5;
  color: #2047D3;
  font-weight: 600;
}

.popoveBox {
  height: 300px;
  background-color: #F0F1FB;
}

.popoverMainBox {
  height: 270px;
  padding: 16px;
  padding-bottom: 0;
}

.popoverBottmText {
  height: 30px;
  background-color: #EDEBFD;
  color: #232631;
  line-height: 30px;
  font-size: 12px;
  text-align: center;
}

.searchTips {
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #6B7785;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}

.searchTipsTag {
  display: inline-block;
  padding: 0 8px;
  height: 24px;
  display: flex;
  align-items: center;
  border: 1px solid #C9CDD4;
  border-radius: 2px;
  margin: 0 8px;
}

.leftTagBox {
  height: calc(100vh - 127px);
}</style>
