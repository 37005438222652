import { createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, vShow as _vShow, withCtx as _withCtx, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, resolveDirective as _resolveDirective, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-20d7c30c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "w-full bg-[#FCFCFD] pt-9 pb-[60px] xl:px-10 md:px-0 md:pb-6 md:h-auto" }
const _hoisted_2 = { class: "mx-auto max-w-[1280px]" }
const _hoisted_3 = { class: "mx-auto max-w-[1280px] mt-[-40px] xl:px-10 md:px-0 relative" }
const _hoisted_4 = { class: "pt-[24px]" }
const _hoisted_5 = ["src"]
const _hoisted_6 = {
  key: 1,
  class: "flex items-center justify-start border border-[#D0D5DD] p-[16px] rounded-xl shadow-sm"
}
const _hoisted_7 = { class: "border border-[#D0D5DD] p-[10px] rounded-lg" }
const _hoisted_8 = { class: "ml-[16px]" }
const _hoisted_9 = { class: "text-[#344054] text-sm font-medium mb-[4px]" }
const _hoisted_10 = { class: "text-[#475467] text-sm font-light" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_button = _resolveComponent("el-button")
  const _component_SvgIcon = _resolveComponent("SvgIcon")
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")
  const _component_el_tabs = _resolveComponent("el-tabs")
  const _directive_loading = _resolveDirective("loading")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode($setup["RepoHeader"], {
          name: $setup.finetune.deploy_name,
          path: `${$props.namespace}/${$props.name}`,
          appStatus: $setup.appStatus,
          "space-resource": $setup.finetune.hardware,
          "resource-name": $setup.finetuneResource,
          "repo-type": "finetune",
          repoId: $setup.finetune.repository_id
        }, null, 8 /* PROPS */, ["name", "path", "appStatus", "space-resource", "resource-name", "repoId"])
      ])
    ]),
    _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_3, [
      _withDirectives(_createVNode(_component_el_button, {
        color: "#3250BD",
        style: {"border-radius":"8px !important"},
        class: "absolute top-0 right-0 z-10 cursor-pointer text-[#fff]",
        onClick: $setup.toNotebookPage
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('finetune.detail.notebook')), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }, 512 /* NEED_PATCH */), [
        [_vShow, $setup.activeName == 'page' && $setup.finetune.endpoint]
      ]),
      _createVNode(_component_el_tabs, {
        modelValue: $setup.activeName,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.activeName) = $event)),
        class: "demo-tabs",
        beforeLeave: $setup.handleTabLeave,
        onTabClick: $setup.tabChange
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_tab_pane, {
            label: _ctx.$t('finetune.detail.tab1'),
            name: "page"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_4, [
                ($setup.finetune.endpoint)
                  ? (_openBlock(), _createElementBlock("iframe", {
                      key: 0,
                      src: `https://${$setup.finetune.proxy_endpoint}?jwt=${$setup.jwtToken}`,
                      width: "100%",
                      height: "700",
                      frameborder: "0",
                      allowfullscreen: ""
                    }, null, 8 /* PROPS */, _hoisted_5))
                  : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      _createElementVNode("div", _hoisted_7, [
                        _createVNode(_component_SvgIcon, {
                          name: "finetune_tip",
                          width: "20",
                          height: "20"
                        })
                      ]),
                      _createElementVNode("div", _hoisted_8, [
                        _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.$t('finetune.detail.noDataTip1')), 1 /* TEXT */),
                        _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.$t('finetune.detail.noDataTip2')), 1 /* TEXT */)
                      ])
                    ]))
              ])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          _createVNode(_component_el_tab_pane, {
            label: _ctx.$t('billing.billing'),
            name: "billing"
          }, {
            default: _withCtx(() => [
              ($setup.finetune.svc_name)
                ? (_openBlock(), _createBlock($setup["BillingDetail"], {
                    key: 0,
                    type: "finetune",
                    instanceName: $setup.finetune.svc_name
                  }, null, 8 /* PROPS */, ["instanceName"]))
                : _createCommentVNode("v-if", true)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          _createVNode(_component_el_tab_pane, {
            label: _ctx.$t('finetune.detail.tab2'),
            name: "setting"
          }, {
            default: _withCtx(() => [
              _createVNode($setup["FinetuneSettings"], {
                finetune: $setup.finetune,
                finetuneId: $setup.finetune.deploy_id,
                finetuneName: $setup.finetune.deploy_name,
                appStatus: $setup.appStatus,
                modelId: $setup.finetune.model_id,
                userName: $props.userName,
                cloudResource: $setup.finetune.hardware,
                framework: $setup.finetune.runtime_framework,
                clusterId: $setup.finetune.cluster_id
              }, null, 8 /* PROPS */, ["finetune", "finetuneId", "finetuneName", "appStatus", "modelId", "userName", "cloudResource", "framework", "clusterId"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["modelValue"])
    ])), [
      [_directive_loading, $setup.dataLoading]
    ])
  ], 64 /* STABLE_FRAGMENT */))
}