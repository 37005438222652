<template>
  <div>
    <div class="rollHeaderBox" v-if="isRoll">
      <span class="max-w-full break-words lable-word rollLable-word">{{
        nickname.trim() === '' ? name : nickname
      }}</span>
      <span class="endpoint-word">
        {{ repoType === 'endpoint' ? name : path }}
        <div class="cursor-pointer">
          <img src="/images/cursor-pointer.png" @click="copyName">
        </div>
      </span>
    </div>

<!--    <div class="flex flex-col flex-wrap text-lg text-[#606266] font-semibold w-[100%] codeHearder" v-if="!isRoll && repoType === 'code' ">-->
<!--      <div class="flex flex-wrap w-full items-center">-->
<!--        <span class="max-w-full break-word lable-word">{{-->
<!--            nickname.trim() === '' ? name : nickname-->
<!--          }}</span>-->
<!--        <AppStatus v-if="appStatus" :appStatus="appStatus" :spaceResource="spaceResource" />-->
<!--        <p v-if="canWrite" class="cursor-pointer" @click="showSpaceLogs">-->
<!--          <SvgIcon name="instance_logs" width="16" height="16" />-->
<!--        </p>-->
<!--      </div>-->
<!--      <div class="flex gap-[8px] items-center">-->
<!--        <span class="endpoint-word">-->
<!--          {{ repoType === 'endpoint' ? name : path }}-->
<!--          <div class="cursor-pointer">-->
<!--            <img src="/images/cursor-pointer.png" @click="copyName">-->
<!--          </div>-->
<!--          ({{ totalDownloads }} 次下载-->
<!--          <div class="spacing" /> {{ parseTime(updatedAt, '{y}-{m}-{d}') }} 更新)-->
<!--        </span>-->
<!--      </div>-->
<!--    </div>-->

    <div class="flex flex-col flex-wrap text-lg text-[#606266] font-semibold w-[100%]" v-if="!isRoll">
      <div v-if="repoType === 'dataset'" class="w-full flex flex-wrap items-center">
        <span class="max-w-full break-words lable-word">{{
          nickname.trim() === '' ? name : nickname
        }}</span>
      </div>

      <div v-else-if="repoType === 'endpoint'" class="flex flex-wrap w-full gap-[16px] items-center mb-[16px]">
        <span class="max-w-full break-words">{{
          nickname.trim() === '' ? name : nickname
        }}</span>
        <div class="border border-[#DCDFE6] px-3 py-[2px] text-center text-xs text-[#606266] font-medium rounded">
          {{ repoDetailStore.isPrivate ? $t('all.private') : $t('all.public') }}
        </div>
        <AppStatus v-if="appStatus" :appStatus="appStatus" :spaceResource="spaceResource" />
      </div>

      <div v-else-if="repoType === 'finetune'" class="flex flex-wrap w-full items-center">
        <SvgIcon name="model_finetune_create" width="16" height="16" class="flex-shrink-0" />
        <span class="max-w-full break-words ">{{
          nickname.trim() === '' ? name : nickname
        }}</span>
        <AppStatus v-if="appStatus" :appStatus="appStatus" :spaceResource="spaceResource" />
      </div>

      <!-- other repo -->
      <div v-else class="flex flex-wrap w-full items-center">
        <span class="max-w-full break-word lable-word">{{
          nickname.trim() === '' ? name : nickname
        }}</span>
        <AppStatus v-if="appStatus" :appStatus="appStatus" :spaceResource="spaceResource" />
        <p v-if="canWrite" class="cursor-pointer" @click="showSpaceLogs">
          <SvgIcon name="instance_logs" width="16" height="16" />
        </p>
      </div>

      <div class="flex gap-[8px] items-center" v-if="repoType !== 'finetune'">
        <span class="endpoint-word">
          {{ repoType === 'endpoint' ? name : path }}
          <div class="cursor-pointer">
            <img src="/images/cursor-pointer.png" @click="copyName">
          </div>
          ({{ totalDownloads }} 次下载
          <div class="spacing" /> {{ parseTime(updatedAt, '{y}-{m}-{d}') }} 更新)
        </span>
      </div>
      <div v-else class="flex gap-[8px] items-center">
        <SvgIcon name="finetune_name_icon" width="16" height="16" class="flex-shrink-0" />
        <span class="md:ml-0 text-[#344054] font-normal hover:text-[#344054]">
          {{ path }}
        </span>
        <SvgIcon name="finetune_cpu_icon" width="16" height="16" class="flex-shrink-0 ml-6 md:ml-0" />
        <span class="text-[#344054] font-normal">{{ resourceName }}</span>
      </div>
    </div>
    <header-tags v-if="(repoType === 'model' || repoType === 'dataset') && !isRoll" :task-tags="tags.task_tags"
      :framework-tags="tags.framework_tags" :license-tags="tags.license_tags" :language-tags="tags.language_tags"
      :industry-tags="tags.industry_tags" :other-tags="tags.other_tags" :prefix="`${repoType}s/`"
      class="headerTag w-[100%]" />
    <div v-else-if="repoType === 'code' && !isRoll" class="flex licenseTagBox">
      <div class="licenseTag">
        {{ license }}
      </div>
    </div>
  </div>
</template>

<script setup>
import HeaderTags from '../shared/HeaderTags.vue'
import AppStatus from '../application_spaces/AppStatus.vue'
import { copyToClipboard } from '../../packs/clipboard'
import useRepoDetailStore from '../../stores/RepoDetailStore'
import { ref, inject } from 'vue'
import { computed } from 'vue'
import jwtFetch from '../../packs/jwtFetch'
import { ElMessage } from 'element-plus'

const repoDetailStore = useRepoDetailStore()

const emit = defineEmits(['toggleSpaceLogsDrawer'])

const props = defineProps({
  avatar: String,
  name: String,
  nickname: { type: String, default: '' },
  desc: String,
  path: String,
  license: String,
  tags: Object,
  ownerUrl: String,
  repoType: String,
  appStatus: String,
  spaceResource: String,
  canWrite: Boolean,
  repoId: Number,
  totalLikes: {
    type: Number,
    default: 0
  },
  hasLike: Boolean,
  resourceName: String,
  updatedAt: String,
  totalDownloads: {
    type: Number,
    default: 0
  },
  isRoll: {
    type: Object,
    default: false
  }
})
console.log(props)
const userLiked = ref(props.hasLike)
const likesNumber = ref(props.totalLikes)
const csghubServer = inject('csghubServer')

const parseTime = (time, pattern) => {
  if (!time) {
    return null
  }
  const format = pattern || '{y}-{m}-{d} {h}:{i}:{s}'
  let date = new Date(time)
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
  return time_str
}

const copyName = () => {
  copyToClipboard(props.path)
}

const likesNumberDisplayName = computed(() => {
  if (likesNumber.value > 9999) {
    return '1w+'
  } else if (likesNumber.value > 999) {
    return '1k+'
  } else {
    return likesNumber.value.toString()
  }
})

const clickLike = () => {
  userLiked.value === true ? removeLike() : addLike()
}

const addLike = async () => {
  const options = { method: 'PUT' }
  const response = await jwtFetch(
    `${csghubServer}/api/v1/user/${props.name}/likes/${props.repoId}`,
    options,
    true
  )
  if (!response.ok) {
    response.json().then((data) => {
      ElMessage({
        type: 'warning',
        message: data
      })
    })
  } else {
    userLiked.value = true
    likesNumber.value += 1
  }
}

const removeLike = async () => {
  const options = { method: 'DELETE' }
  const response = await jwtFetch(
    `${csghubServer}/api/v1/user/${props.name}/likes/${props.repoId}`,
    options
  )
  if (!response.ok) {
    response.json().then((data) => {
      ElMessage({
        type: 'warning',
        message: data
      })
    })
  } else {
    userLiked.value = false
    likesNumber.value -= 1
  }
}
</script>
<style scoped>
.lable-word {
  font-family: PingFang SC;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
  color: #1D2129;
}

.endpoint-word {
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #6B7785;
  display: flex;
  align-items: center;
}

.cursor-pointer {
  width: 16px;
  height: 16px;
  margin-right: 3px;
}

.cursor-pointer img {
  width: 100%;
  height: 100%;
}

.tiem-word {
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #A9AEB8;
}

.headerTag {
  height: 23px;
  margin: 20px 0;
}

.spacing {
  background: #E5E6EB;
  height: 14px;
  width: 1px;
  margin: 0 8px;
}

.rollLable-word {
  margin-right: 8px;
}

.rollHeaderBox {
  width: 100%;
  white-space: nowrap;
  /* 禁止文本换行 */
  overflow: hidden;
  /* 隐藏溢出部分 */
  text-overflow: ellipsis;
  display: flex;
  height: 32px;
  align-items: center;
  margin-bottom: 16px;
}
.codeHearder{
  margin-bottom: 16px;
}
.licenseTag{
  font-family: PingFang SC;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  padding: 2px 8px;
  background-color: rgb(232, 241, 255);
  color: rgb(32, 71, 211);
  border-radius: 2px;
}
.licenseTagBox{
  height: 23px;
  margin: 20px 0;
}
</style>
