<template>
  <div class="p-[16px] border-t border-[#EBEEF5]">
    <h3 class="flex items-center gap-[8px]">
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 32 32" fill="none">
        <g opacity="0.12">
          <path d="M2.6665 6.93317C2.6665 5.4397 2.6665 4.69296 2.95715 4.12253C3.21282 3.62076 3.62076 3.21282 4.12253 2.95715C4.69296 2.6665 5.4397 2.6665 6.93317 2.6665H9.0665C10.56 2.6665 11.3067 2.6665 11.8771 2.95715C12.3789 3.21282 12.7869 3.62076 13.0425 4.12253C13.3332 4.69296 13.3332 5.4397 13.3332 6.93317V9.0665C13.3332 10.56 13.3332 11.3067 13.0425 11.8771C12.7869 12.3789 12.3789 12.7869 11.8771 13.0425C11.3067 13.3332 10.56 13.3332 9.0665 13.3332H6.93317C5.4397 13.3332 4.69296 13.3332 4.12253 13.0425C3.62076 12.7869 3.21282 12.3789 2.95715 11.8771C2.6665 11.3067 2.6665 10.56 2.6665 9.0665V6.93317Z" fill="#7A5AF8"/>
          <path d="M2.6665 22.9332C2.6665 21.4397 2.6665 20.693 2.95715 20.1225C3.21282 19.6208 3.62076 19.2128 4.12253 18.9572C4.69296 18.6665 5.4397 18.6665 6.93317 18.6665H9.0665C10.56 18.6665 11.3067 18.6665 11.8771 18.9572C12.3789 19.2128 12.7869 19.6208 13.0425 20.1225C13.3332 20.693 13.3332 21.4397 13.3332 22.9332V25.0665C13.3332 26.56 13.3332 27.3067 13.0425 27.8771C12.7869 28.3789 12.3789 28.7869 11.8771 29.0425C11.3067 29.3332 10.56 29.3332 9.0665 29.3332H6.93317C5.4397 29.3332 4.69296 29.3332 4.12253 29.0425C3.62076 28.7869 3.21282 28.3789 2.95715 27.8771C2.6665 27.3067 2.6665 26.56 2.6665 25.0665V22.9332Z" fill="#7A5AF8"/>
          <path d="M18.6665 22.9332C18.6665 21.4397 18.6665 20.693 18.9572 20.1225C19.2128 19.6208 19.6208 19.2128 20.1225 18.9572C20.693 18.6665 21.4397 18.6665 22.9332 18.6665H25.0665C26.56 18.6665 27.3067 18.6665 27.8771 18.9572C28.3789 19.2128 28.7869 19.6208 29.0425 20.1225C29.3332 20.693 29.3332 21.4397 29.3332 22.9332V25.0665C29.3332 26.56 29.3332 27.3067 29.0425 27.8771C28.7869 28.3789 28.3789 28.7869 27.8771 29.0425C27.3067 29.3332 26.56 29.3332 25.0665 29.3332H22.9332C21.4397 29.3332 20.693 29.3332 20.1225 29.0425C19.6208 28.7869 19.2128 28.3789 18.9572 27.8771C18.6665 27.3067 18.6665 26.56 18.6665 25.0665V22.9332Z" fill="#7A5AF8"/>
        </g>
        <path d="M2.6665 6.93317C2.6665 5.4397 2.6665 4.69296 2.95715 4.12253C3.21282 3.62076 3.62076 3.21282 4.12253 2.95715C4.69296 2.6665 5.4397 2.6665 6.93317 2.6665H9.0665C10.56 2.6665 11.3067 2.6665 11.8771 2.95715C12.3789 3.21282 12.7869 3.62076 13.0425 4.12253C13.3332 4.69296 13.3332 5.4397 13.3332 6.93317V9.0665C13.3332 10.56 13.3332 11.3067 13.0425 11.8771C12.7869 12.3789 12.3789 12.7869 11.8771 13.0425C11.3067 13.3332 10.56 13.3332 9.0665 13.3332H6.93317C5.4397 13.3332 4.69296 13.3332 4.12253 13.0425C3.62076 12.7869 3.21282 12.3789 2.95715 11.8771C2.6665 11.3067 2.6665 10.56 2.6665 9.0665V6.93317Z" stroke="#7A5AF8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M18.6665 6.93317C18.6665 5.4397 18.6665 4.69296 18.9572 4.12253C19.2128 3.62076 19.6208 3.21282 20.1225 2.95715C20.693 2.6665 21.4397 2.6665 22.9332 2.6665H25.0665C26.56 2.6665 27.3067 2.6665 27.8771 2.95715C28.3789 3.21282 28.7869 3.62076 29.0425 4.12253C29.3332 4.69296 29.3332 5.4397 29.3332 6.93317V9.0665C29.3332 10.56 29.3332 11.3067 29.0425 11.8771C28.7869 12.3789 28.3789 12.7869 27.8771 13.0425C27.3067 13.3332 26.56 13.3332 25.0665 13.3332H22.9332C21.4397 13.3332 20.693 13.3332 20.1225 13.0425C19.6208 12.7869 19.2128 12.3789 18.9572 11.8771C18.6665 11.3067 18.6665 10.56 18.6665 9.0665V6.93317Z" stroke="#7A5AF8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M2.6665 22.9332C2.6665 21.4397 2.6665 20.693 2.95715 20.1225C3.21282 19.6208 3.62076 19.2128 4.12253 18.9572C4.69296 18.6665 5.4397 18.6665 6.93317 18.6665H9.0665C10.56 18.6665 11.3067 18.6665 11.8771 18.9572C12.3789 19.2128 12.7869 19.6208 13.0425 20.1225C13.3332 20.693 13.3332 21.4397 13.3332 22.9332V25.0665C13.3332 26.56 13.3332 27.3067 13.0425 27.8771C12.7869 28.3789 12.3789 28.7869 11.8771 29.0425C11.3067 29.3332 10.56 29.3332 9.0665 29.3332H6.93317C5.4397 29.3332 4.69296 29.3332 4.12253 29.0425C3.62076 28.7869 3.21282 28.3789 2.95715 27.8771C2.6665 27.3067 2.6665 26.56 2.6665 25.0665V22.9332Z" stroke="#7A5AF8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M18.6665 22.9332C18.6665 21.4397 18.6665 20.693 18.9572 20.1225C19.2128 19.6208 19.6208 19.2128 20.1225 18.9572C20.693 18.6665 21.4397 18.6665 22.9332 18.6665H25.0665C26.56 18.6665 27.3067 18.6665 27.8771 18.9572C28.3789 19.2128 28.7869 19.6208 29.0425 20.1225C29.3332 20.693 29.3332 21.4397 29.3332 22.9332V25.0665C29.3332 26.56 29.3332 27.3067 29.0425 27.8771C28.7869 28.3789 28.3789 28.7869 27.8771 29.0425C27.3067 29.3332 26.56 29.3332 25.0665 29.3332H22.9332C21.4397 29.3332 20.693 29.3332 20.1225 29.0425C19.6208 28.7869 19.2128 28.3789 18.9572 27.8771C18.6665 27.3067 18.6665 26.56 18.6665 25.0665V22.9332Z" stroke="#7A5AF8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      <span class="font-[500] text-[#344054] text-[16px] leading-[24px]"> Space using </span>
      <span class="text-[#344054]"> {{ namespacePath }} </span>
      <span class="text-[#667085]"> {{ spaces.length }} </span>
    </h3>
    <div class="mt-[16px] flex gap-[12px]">
      <a v-for="space in spaces" :href="`/spaces/${space.path}`">
        <p class="rounded-md border border-gray-300 bg-white py-0.5 pr-2.5 pl-1.5">
          {{ space.path }}
        </p>
      </a>
    </div>
  </div>
</template>

<script setup>
  const props = defineProps({
    namespacePath: String,
    spaces: Array
  })
</script>