import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { style: {"padding-bottom":"16px","width":"100%","height":"calc(100% - 16px)"} }
const _hoisted_2 = ["src"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_dialog = _resolveComponent("el-dialog")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createVNode(_component_el_button, {
        style: {"width":"100%"},
        onClick: $setup.loadRepos,
        class: "Default"
      }, {
        default: _withCtx(() => [
          _createTextVNode("在线试用")
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _createVNode(_component_el_dialog, {
      modelValue: $setup.dialogVisible,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.dialogVisible) = $event)),
      width: "75%",
      style: {"height":"75%"},
      title: "在线试用",
      class: "iframeDialog dialogLoading",
      "destroy-on-close": "",
      "close-on-click-modal": false
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          (_openBlock(), _createElementBlock("iframe", {
            src: $setup.url,
            width: "100%",
            height: "100%",
            frameborder: "0",
            key: $setup.iframeKey
          }, null, 8 /* PROPS */, _hoisted_2))
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue"])
  ], 64 /* STABLE_FRAGMENT */))
}