import { createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "border border-[#DCDFE6] rounded-[8px] md:my-0 md:border-none px-[24px] py-[24px]" }
const _hoisted_2 = { class: "flex xl:flex-col gap-[32px]" }
const _hoisted_3 = { class: "w-[380px] sm:w-full flex flex-col" }
const _hoisted_4 = { class: "text-[14px] text-[#344054] leading-[20px] font-medium" }
const _hoisted_5 = { class: "text-[14px] text-[#475467] leading-[20px]" }
const _hoisted_6 = { class: "flex flex-col gap-[6px]" }
const _hoisted_7 = { class: "text-[#344054] text-[14px]" }
const _hoisted_8 = { class: "w-[512px] sm:w-full rounded-[8px] bg-[#F9FAFB] px-[14px] py-[10px] border" }
const _hoisted_9 = { class: "flex xl:flex-col gap-[32px]" }
const _hoisted_10 = { class: "w-[380px] sm:w-full flex flex-col" }
const _hoisted_11 = { class: "text-[14px] text-[#344054] leading-[20px] font-medium" }
const _hoisted_12 = { class: "text-[14px] text-[#475467] leading-[20px]" }
const _hoisted_13 = { class: "flex flex-col gap-[6px]" }
const _hoisted_14 = { class: "flex xl:flex-col gap-[32px]" }
const _hoisted_15 = { class: "w-[380px] sm:w-full flex flex-col" }
const _hoisted_16 = { class: "text-[14px] text-[#344054] leading-[20px] font-medium" }
const _hoisted_17 = { class: "text-[14px] text-[#475467] leading-[20px]" }
const _hoisted_18 = { class: "flex flex-col gap-[6px]" }
const _hoisted_19 = { class: "flex xl:flex-col gap-[32px]" }
const _hoisted_20 = { class: "w-[380px] sm:w-full flex flex-col" }
const _hoisted_21 = { class: "text-[14px] text-[#344054] leading-[20px] font-medium" }
const _hoisted_22 = { class: "text-[14px] text-[#475467] leading-[20px]" }
const _hoisted_23 = {
  class: "flex flex-col gap-[6px]",
  ref: "tagListContainer"
}
const _hoisted_24 = { class: "text-[#344054] text-[14px]" }
const _hoisted_25 = { class: "flex xl:flex-col gap-[32px]" }
const _hoisted_26 = { class: "w-[380px] sm:w-full flex flex-col" }
const _hoisted_27 = { class: "text-[14px] text-[#344054] leading-[20px] font-medium" }
const _hoisted_28 = { class: "text-[14px] text-[#475467] leading-[20px]" }
const _hoisted_29 = {
  class: "flex flex-col gap-[6px]",
  ref: "IndustryTagListContainer"
}
const _hoisted_30 = { class: "text-[#344054] text-[14px]" }
const _hoisted_31 = { class: "flex xl:flex-col gap-[32px]" }
const _hoisted_32 = { class: "w-[380px] sm:w-full flex flex-col gap-[6px]" }
const _hoisted_33 = { class: "text-[14px] text-[#344054] leading-[20px] font-medium" }
const _hoisted_34 = { class: "text-[14px] text-[#475467] leading-[20px]" }
const _hoisted_35 = { class: "text-black font-medium" }
const _hoisted_36 = { class: "text-black font-medium break-words" }
const _hoisted_37 = { class: "text-[14px] text-[#475467] leading-[20px]" }
const _hoisted_38 = { class: "text-black font-medium break-words" }
const _hoisted_39 = { class: "flex flex-col gap-[8px]" }
const _hoisted_40 = { class: "text-[#344054] text-[14px]" }
const _hoisted_41 = { class: "flex" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_divider = _resolveComponent("el-divider")
  const _component_el_input = _resolveComponent("el-input")
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_option = _resolveComponent("el-option")
  const _component_el_select = _resolveComponent("el-select")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createCommentVNode(" 展示英文名 "),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('models.modelName')), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('models.modelNickName')), 1 /* TEXT */)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t('models.namespaceModelName')), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_8, _toDisplayString($data.modelPath), 1 /* TEXT */)
      ])
    ]),
    _createVNode(_component_el_divider),
    _createCommentVNode(" 更新模型别名 "),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t('models.modelNickName')), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$t('models.edit.tips')), 1 /* TEXT */)
      ]),
      _createElementVNode("div", _hoisted_13, [
        _createVNode(_component_el_input, {
          modelValue: $data.theModelNickname,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($data.theModelNickname) = $event)),
          clearable: "",
          size: "large",
          class: "!w-[512px] sm:!w-full",
          maxlength: "20",
          "show-word-limit": ""
        }, null, 8 /* PROPS */, ["modelValue"]),
        _createVNode(_component_el_button, {
          onClick: $options.updateNickname,
          class: "w-[100px]"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('all.update')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["onClick"])
      ])
    ]),
    _createVNode(_component_el_divider),
    _createCommentVNode(" 更新模型简介 "),
    _createElementVNode("div", _hoisted_14, [
      _createElementVNode("div", _hoisted_15, [
        _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.$t('models.modelDesc')), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.$t('models.edit.tips2')), 1 /* TEXT */)
      ]),
      _createElementVNode("div", _hoisted_18, [
        _createVNode(_component_el_input, {
          modelValue: $data.theModelDesc,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($data.theModelDesc) = $event)),
          clearable: "",
          size: "large",
          type: "textarea",
          class: "!w-[512px] sm:!w-full",
          maxlength: "50",
          "show-word-limit": ""
        }, null, 8 /* PROPS */, ["modelValue"]),
        _createVNode(_component_el_button, {
          onClick: $options.updateModelDesc,
          class: "w-[100px]"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('all.update')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["onClick"])
      ])
    ]),
    _createVNode(_component_el_divider),
    _createCommentVNode(" 模型标签 "),
    _createElementVNode("div", _hoisted_19, [
      _createElementVNode("div", _hoisted_20, [
        _createElementVNode("div", _hoisted_21, _toDisplayString(_ctx.$t('models.modelTag')), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_22, _toDisplayString(_ctx.$t('models.edit.tips3')), 1 /* TEXT */)
      ]),
      _createElementVNode("div", _hoisted_23, [
        _createElementVNode("p", _hoisted_24, _toDisplayString(_ctx.$t('models.modelTag')), 1 /* TEXT */),
        _createVNode(_component_el_select, {
          modelValue: $data.tagModelList,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($data.tagModelList) = $event)),
          filterable: "",
          multiple: "",
          placeholder: "",
          class: "!w-[512px] filterableSelect"
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.tagList, (item) => {
              return (_openBlock(), _createBlock(_component_el_option, {
                key: item.name,
                label: item.zh_name,
                value: item.name
              }, null, 8 /* PROPS */, ["label", "value"]))
            }), 128 /* KEYED_FRAGMENT */))
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue"]),
        _createCommentVNode("        <div class=\"flex flex-col gap-[6px] w-[512px] md:w-full\">"),
        _createCommentVNode("          <div class=\"flex gap-[4px] flex-wrap items-center w-full border rounded-[4px] border-gray-300 min-h-[40px] p-[6px]\">"),
        _createCommentVNode("            <div class=\"scroll-container flex gap-[4px] flex-wrap max-h-[120px] overflow-y-auto\">"),
        _createCommentVNode("              <span v-for=\"tag in selectedTags\" class=\"flex items-center gap-[5px] border rounded-[5px] border-gray-300 px-[5px] py-[2px]\">"),
        _createCommentVNode("                {{ this.$i18n.locale === 'zh'? (tag.zh_name || tag.name) : tag.name }}"),
        _createCommentVNode("                <el-icon><Close @click=\"removeTag(tag.name)\" /></el-icon>"),
        _createCommentVNode("              </span>"),
        _createCommentVNode("            </div>"),
        _createCommentVNode("            <input class=\"w-full max-h-[36px] outline-none el-input__inner\""),
        _createCommentVNode("                    v-model=\"tagInput\""),
        _createCommentVNode("                    @input=\"showTagList\" />"),
        _createCommentVNode("          </div>"),
        _createCommentVNode("          <div v-show=\"shouldShowTagList\" class=\"rounded-md max-h-[300px] overflow-y-auto border border-gray-200 bg-white shadow-lg py-[4px] px-[6px]\">"),
        _createCommentVNode("            <p v-for=\"tag in theTagList\""),
        _createCommentVNode("                @click=\"selectTag(tag)\""),
        _createCommentVNode("                class=\"flex gap-[8px] items-center cursor-pointer p-[10px]\""),
        _createCommentVNode("            >"),
        _createCommentVNode("              {{ this.$i18n.locale === 'zh'? (tag.zh_name || tag.name) : tag.name}}"),
        _createCommentVNode("            </p>"),
        _createCommentVNode("          </div>"),
        _createVNode(_component_el_button, {
          onClick: $options.updateTags,
          class: "w-[100px]"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('all.update')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["onClick"]),
        _createCommentVNode("        </div>")
      ], 512 /* NEED_PATCH */)
    ]),
    _createVNode(_component_el_divider),
    _createCommentVNode(" 行业标签 "),
    _createElementVNode("div", _hoisted_25, [
      _createElementVNode("div", _hoisted_26, [
        _createElementVNode("div", _hoisted_27, _toDisplayString(_ctx.$t('models.modelIndustryTag')), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_28, _toDisplayString(_ctx.$t('models.edit.tips3')), 1 /* TEXT */)
      ]),
      _createElementVNode("div", _hoisted_29, [
        _createElementVNode("p", _hoisted_30, _toDisplayString(_ctx.$t('models.modelIndustryTag')), 1 /* TEXT */),
        _createVNode(_component_el_select, {
          modelValue: $data.IndustryModelList,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => (($data.IndustryModelList) = $event)),
          filterable: "",
          multiple: "",
          placeholder: "",
          class: "!w-[512px] filterableSelect"
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.industryTagsList, (item) => {
              return (_openBlock(), _createBlock(_component_el_option, {
                key: item.name,
                label: item.show_name,
                value: item.name
              }, null, 8 /* PROPS */, ["label", "value"]))
            }), 128 /* KEYED_FRAGMENT */))
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue"]),
        _createCommentVNode("        <div class=\"flex flex-col gap-[6px] w-[512px] md:w-full\">"),
        _createCommentVNode("          <div class=\"flex gap-[4px] flex-wrap items-center w-full border rounded-[4px] border-gray-300 min-h-[40px] p-[6px]\">"),
        _createCommentVNode("            <div class=\"scroll-container flex gap-[4px] flex-wrap max-h-[120px] overflow-y-auto\">"),
        _createCommentVNode("              <span v-for=\"tag in selectedIndustryTags\" class=\"flex items-center gap-[5px] border rounded-[5px] border-gray-300 px-[5px] py-[2px]\">"),
        _createCommentVNode("                {{ tag.show_name || tag.name }}"),
        _createCommentVNode("                <el-icon><Close @click=\"removeIndustryTag(tag.name)\" /></el-icon>"),
        _createCommentVNode("              </span>"),
        _createCommentVNode("            </div>"),
        _createCommentVNode("            <input class=\"w-full max-h-[36px] outline-none el-input__inner\""),
        _createCommentVNode("                    v-model=\"industryTagInput\""),
        _createCommentVNode("                    @input=\"showIndustryTagList\" />"),
        _createCommentVNode("          </div>"),
        _createCommentVNode("          <div v-show=\"shouldShowIndustryTagList\" class=\"rounded-md max-h-[300px] overflow-y-auto border border-gray-200 bg-white shadow-lg py-[4px] px-[6px]\">"),
        _createCommentVNode("            <p v-for=\"tag in theIndustryTagsList\""),
        _createCommentVNode("                @click=\"selectIndustryTag(tag)\""),
        _createCommentVNode("                class=\"flex gap-[8px] items-center cursor-pointer p-[10px]\""),
        _createCommentVNode("            >"),
        _createCommentVNode("              {{ tag.show_name || tag.name }}"),
        _createCommentVNode("            </p>"),
        _createCommentVNode("          </div>"),
        _createVNode(_component_el_button, {
          onClick: $options.updateIndustryTags,
          class: "w-[100px]"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('all.update')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["onClick"]),
        _createCommentVNode("        </div>")
      ], 512 /* NEED_PATCH */)
    ]),
    _createVNode(_component_el_divider),
    _createCommentVNode(" 修改可见性 "),
    _createCommentVNode("    <div class=\"flex xl:flex-col gap-[32px]\">"),
    _createCommentVNode("      <div class=\"w-[380px] sm:w-full flex flex-col\">"),
    _createCommentVNode("        <div class=\"text-[14px] text-[#344054] leading-[20px] font-medium\">"),
    _createCommentVNode("          {{ $t('models.edit.changeVisibility')}}"),
    _createCommentVNode("        </div>"),
    _createCommentVNode("        <div class=\"text-[14px] text-[#475467] leading-[20px]\">"),
    _createCommentVNode("          {{ $t('models.edit.statusText')}}"),
    _createCommentVNode("          <span class=\"text-black font-semibold\">【{{ isPrivate ? this.$t('all.private') : this.$t('all.public') }}】</span>"),
    _createCommentVNode("          {{ $t('models.edit.status')}}。{{ isPrivate ? this.$t('models.edit.privateVis') : this.$t('models.edit.publicVis')}}"),
    _createCommentVNode("        </div>"),
    _createCommentVNode("      </div>"),
    _createCommentVNode("      <div class=\"flex flex-col gap-[6px]\">"),
    _createCommentVNode("        <p class=\"text-[#344054] text-[14px]\">{{ $t('models.edit.modelVisibility')}}</p>"),
    _createCommentVNode("        <el-select v-model=\"visibilityName\""),
    _createCommentVNode("                   @change=\"changeVisibility\""),
    _createCommentVNode("                   placeholder=\"Select\""),
    _createCommentVNode("                   size=\"large\""),
    _createCommentVNode("                   class=\"!w-[512px] sm:!w-full\""),
    _createCommentVNode("        >"),
    _createCommentVNode("          <el-option"),
    _createCommentVNode("              v-for=\"item in options\""),
    _createCommentVNode("              :key=\"item.value\""),
    _createCommentVNode("              :label=\"item.label\""),
    _createCommentVNode("              :value=\"item.value\""),
    _createCommentVNode("          />"),
    _createCommentVNode("        </el-select>"),
    _createCommentVNode("      </div>"),
    _createCommentVNode("    </div>"),
    _createCommentVNode("    <el-divider/>"),
    _createCommentVNode(" 删除模型 "),
    _createElementVNode("div", _hoisted_31, [
      _createElementVNode("div", _hoisted_32, [
        _createElementVNode("div", _hoisted_33, _toDisplayString(_ctx.$t('models.edit.delModel')), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_34, [
          _createTextVNode(_toDisplayString(_ctx.$t('models.edit.delTips')) + " ", 1 /* TEXT */),
          _createElementVNode("span", _hoisted_35, _toDisplayString(_ctx.$t('all.canNot')), 1 /* TEXT */),
          _createTextVNode(" " + _toDisplayString(_ctx.$t('models.edit.delTips2')) + " ", 1 /* TEXT */),
          _createElementVNode("span", _hoisted_36, _toDisplayString($props.path), 1 /* TEXT */),
          _createTextVNode(" " + _toDisplayString(_ctx.$t('models.edit.delTips3')), 1 /* TEXT */)
        ]),
        _createElementVNode("div", _hoisted_37, [
          _createTextVNode(_toDisplayString(_ctx.$t('all.enterPls')) + " ", 1 /* TEXT */),
          _createElementVNode("span", _hoisted_38, _toDisplayString($props.path), 1 /* TEXT */),
          _createTextVNode(" " + _toDisplayString(_ctx.$t('all.sureDel')), 1 /* TEXT */)
        ])
      ]),
      _createElementVNode("div", _hoisted_39, [
        _createElementVNode("p", _hoisted_40, _toDisplayString(_ctx.$t('models.modelName')), 1 /* TEXT */),
        _createVNode(_component_el_input, {
          modelValue: $data.delDesc,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => (($data.delDesc) = $event)),
          clearable: "",
          size: "large",
          class: "!w-[512px] sm:!w-full"
        }, null, 8 /* PROPS */, ["modelValue"]),
        _createElementVNode("div", _hoisted_41, [
          _createElementVNode("div", {
            id: "confirmDelete",
            onClick: _cache[5] || (_cache[5] = (...args) => ($options.clickDelete && $options.clickDelete(...args))),
            class: _normalizeClass(["text-[#98A2B3] py-[8px] px-[12px] text-[14px] leading-[20px] rounded-[8px]", $data.delDesc === $data.modelPath ?'bg-[#D92D20] text-[#FFFFFF] cursor-pointer active:shadow-box active:space-y-0 active:space-x-0 active:ring-4 active:ring-red-400 active:ring-opacity-25 active:bg-[#D92D20] hover:text-white':'bg-[#F2F4F7]']),
            onMouseover: _cache[6] || (_cache[6] = (...args) => ($options.handleMouseOver && $options.handleMouseOver(...args))),
            onMouseleave: _cache[7] || (_cache[7] = (...args) => ($options.handleMouseLeave && $options.handleMouseLeave(...args)))
          }, _toDisplayString(_ctx.$t('models.edit.confirmDel')), 35 /* TEXT, CLASS, NEED_HYDRATION */)
        ])
      ])
    ])
  ]))
}