import { createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c310e999"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "repo-tabs" }
const _hoisted_2 = { class: "relative" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")
  const _component_el_tabs = _resolveComponent("el-tabs")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_el_tabs, {
        modelValue: $setup.activeName,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.activeName) = $event)),
        beforeLeave: $setup.handleTabLeave,
        onTabClick: $setup.tabClick,
        class: "modelTabs"
      }, {
        default: _withCtx(() => [
          _createCommentVNode(" repo/endpoint summary "),
          _createVNode(_component_el_tab_pane, {
            label: $setup.reoiTypeName + $setup.summaryLabel,
            name: "summary"
          }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "summary", {}, undefined, true)
            ]),
            _: 3 /* FORWARDED */
          }, 8 /* PROPS */, ["label"]),
          _createCommentVNode(" repo files "),
          ($setup.showFiles)
            ? (_openBlock(), _createBlock(_component_el_tab_pane, {
                key: 0,
                label: $setup.reoiTypeName + _ctx.$t('all.files'),
                name: "files"
              }, {
                default: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "files", {}, undefined, true)
                ]),
                _: 3 /* FORWARDED */
              }, 8 /* PROPS */, ["label"]))
            : _createCommentVNode("v-if", true),
          _createCommentVNode(" repo community "),
          _createCommentVNode("      <el-tab-pane"),
          _createCommentVNode("        v-if=\"repoType !== 'endpoint' && !!localRepoId\""),
          _createCommentVNode("        :label=\"$t('all.community')\""),
          _createCommentVNode("        name=\"community\""),
          _createCommentVNode("        class=\"min-h-[300px]\""),
          _createCommentVNode("      >"),
          _createCommentVNode("        <slot name=\"community\"></slot>"),
          _createCommentVNode("      </el-tab-pane>"),
          _createCommentVNode(" endpoint logs "),
          ($props.repoType === 'endpoint')
            ? (_openBlock(), _createBlock(_component_el_tab_pane, {
                key: 1,
                label: $setup.reoiTypeName + _ctx.$t('all.logs'),
                name: "logs"
              }, {
                default: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "logs", {}, undefined, true)
                ]),
                _: 3 /* FORWARDED */
              }, 8 /* PROPS */, ["label"]))
            : _createCommentVNode("v-if", true),
          _createCommentVNode(" billing "),
          (($props.repoType === 'endpoint' || $props.repoType === 'space') && $props.settingsVisibility)
            ? (_openBlock(), _createBlock(_component_el_tab_pane, {
                key: 2,
                label: _ctx.$t('billing.billing'),
                name: "billing"
              }, {
                default: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "billing", {}, undefined, true)
                ]),
                _: 3 /* FORWARDED */
              }, 8 /* PROPS */, ["label"]))
            : _createCommentVNode("v-if", true),
          _createCommentVNode(" repo settings "),
          ($props.settingsVisibility)
            ? (_openBlock(), _createBlock(_component_el_tab_pane, {
                key: 3,
                label: $setup.reoiTypeName + _ctx.$t('all.settings'),
                name: "settings"
              }, {
                default: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "settings", {}, undefined, true)
                ]),
                _: 3 /* FORWARDED */
              }, 8 /* PROPS */, ["label"]))
            : _createCommentVNode("v-if", true)
        ]),
        _: 3 /* FORWARDED */
      }, 8 /* PROPS */, ["modelValue"])
    ])
  ]))
}