import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "border-r border-[#EAECF0]" }
const _hoisted_2 = { class: "w-[294px] rounded-[8px] mx-[24px] py-[24px]" }
const _hoisted_3 = { class: "flex p-[16px] pt-[16px]" }
const _hoisted_4 = { class: "ml-[10px]" }
const _hoisted_5 = ["title"]
const _hoisted_6 = ["title"]
const _hoisted_7 = { class: "p-[16px] text-[20px] leading-[30px] text-[#344054]" }
const _hoisted_8 = { class: "flex flex-col" }
const _hoisted_9 = ["href"]
const _hoisted_10 = { class: "flex flex-col" }
const _hoisted_11 = ["href"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_avatar = _resolveComponent("el-avatar")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_el_avatar, {
          size: 60,
          src: $props.logo
        }, null, 8 /* PROPS */, ["src"]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", {
            title: $props.nickName,
            class: "max-w-[190px] text-ellipsis overflow-hidden whitespace-nowrap text-[24px] leading-[32px] font-semibold"
          }, _toDisplayString($props.nickName || $props.name), 9 /* TEXT, PROPS */, _hoisted_5),
          _createElementVNode("div", {
            class: "max-w-[190px] text-ellipsis overflow-hidden whitespace-nowrap text-[16px] text-[#909399] leading-[24px]",
            title: $props.homepage
          }, _toDisplayString($props.homepage), 9 /* TEXT, PROPS */, _hoisted_6)
        ])
      ]),
      _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t('organization.orgSetting')), 1 /* TEXT */),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("a", {
          href: `/organizations/${this.name}/edit`,
          class: _normalizeClass(["py-[8px] px-[12px] hover:bg-[#FAFAFA] text-[16px] text-[#667085] leading-[24px] cursor-pointer", $options.menuClass(`/organizations/${this.name}/edit`)])
        }, _toDisplayString(_ctx.$t('organization.orgInfo')), 11 /* TEXT, CLASS, PROPS */, _hoisted_9)
      ]),
      _createCommentVNode(" 组织成员 "),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("a", {
          href: `/organizations/${this.name}/members`,
          class: _normalizeClass(["py-[8px] px-[12px] hover:bg-[#FAFAFA] text-[16px] text-[#667085] leading-[24px] cursor-pointer", $options.menuClass(`/organizations/${this.name}/members`)])
        }, _toDisplayString(_ctx.$t('organization.orgMembers')), 11 /* TEXT, CLASS, PROPS */, _hoisted_11)
      ])
    ])
  ]))
}