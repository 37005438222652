<template>
  <a href="/spaces"
     class="flex items-center hover:text-[#009933]"
     :class="this.active ? 'text-[#009933]' : ''"
  >
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="currentColor"
         :class="this.active ? 'text-[#009933]' : 'text-white'"
    >
      <path d="M1.5 4.5C1.5 3.56812 1.5 3.10218 1.65224 2.73463C1.85523 2.24458 2.24458 1.85523 2.73463 1.65224C3.10218 1.5 3.56812 1.5 4.5 1.5V1.5C5.43188 1.5 5.89782 1.5 6.26537 1.65224C6.75542 1.85523 7.14477 2.24458 7.34776 2.73463C7.5 3.10218 7.5 3.56812 7.5 4.5V4.5C7.5 5.43188 7.5 5.89782 7.34776 6.26537C7.14477 6.75542 6.75542 7.14477 6.26537 7.34776C5.89782 7.5 5.43188 7.5 4.5 7.5V7.5C3.56812 7.5 3.10218 7.5 2.73463 7.34776C2.24458 7.14477 1.85523 6.75542 1.65224 6.26537C1.5 5.89782 1.5 5.43188 1.5 4.5V4.5Z" stroke="#2F384C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M10.5 4.5C10.5 3.56812 10.5 3.10218 10.6522 2.73463C10.8552 2.24458 11.2446 1.85523 11.7346 1.65224C12.1022 1.5 12.5681 1.5 13.5 1.5V1.5C14.4319 1.5 14.8978 1.5 15.2654 1.65224C15.7554 1.85523 16.1448 2.24458 16.3478 2.73463C16.5 3.10218 16.5 3.56812 16.5 4.5V4.5C16.5 5.43188 16.5 5.89782 16.3478 6.26537C16.1448 6.75542 15.7554 7.14477 15.2654 7.34776C14.8978 7.5 14.4319 7.5 13.5 7.5V7.5C12.5681 7.5 12.1022 7.5 11.7346 7.34776C11.2446 7.14477 10.8552 6.75542 10.6522 6.26537C10.5 5.89782 10.5 5.43188 10.5 4.5V4.5Z" stroke="#2F384C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M1.5 13.5C1.5 12.5681 1.5 12.1022 1.65224 11.7346C1.85523 11.2446 2.24458 10.8552 2.73463 10.6522C3.10218 10.5 3.56812 10.5 4.5 10.5V10.5C5.43188 10.5 5.89782 10.5 6.26537 10.6522C6.75542 10.8552 7.14477 11.2446 7.34776 11.7346C7.5 12.1022 7.5 12.5681 7.5 13.5V13.5C7.5 14.4319 7.5 14.8978 7.34776 15.2654C7.14477 15.7554 6.75542 16.1448 6.26537 16.3478C5.89782 16.5 5.43188 16.5 4.5 16.5V16.5C3.56812 16.5 3.10218 16.5 2.73463 16.3478C2.24458 16.1448 1.85523 15.7554 1.65224 15.2654C1.5 14.8978 1.5 14.4319 1.5 13.5V13.5Z" stroke="#2F384C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M10.5 13.5C10.5 12.5681 10.5 12.1022 10.6522 11.7346C10.8552 11.2446 11.2446 10.8552 11.7346 10.6522C12.1022 10.5 12.5681 10.5 13.5 10.5V10.5C14.4319 10.5 14.8978 10.5 15.2654 10.6522C15.7554 10.8552 16.1448 11.2446 16.3478 11.7346C16.5 12.1022 16.5 12.5681 16.5 13.5V13.5C16.5 14.4319 16.5 14.8978 16.3478 15.2654C16.1448 15.7554 15.7554 16.1448 15.2654 16.3478C14.8978 16.5 14.4319 16.5 13.5 16.5V16.5C12.5681 16.5 12.1022 16.5 11.7346 16.3478C11.2446 16.1448 10.8552 15.7554 10.6522 15.2654C10.5 14.8978 10.5 14.4319 10.5 13.5V13.5Z" stroke="#2F384C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
    <span class="ml-[8px]"> {{ $t('navbar.spaces') }} </span>
  </a>
</template>
<script>
export default {
  data() {
    return {
      active: false
    }
  },
  beforeMount() {
    if (window.location.pathname.match(/\/spaces|\/application_space/)) {
      this.active = true
    }
  }
}
</script>