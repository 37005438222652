import { createVNode as _createVNode, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-62e7beb0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "min-h-[300px] pt-[0px]" }
const _hoisted_2 = { class: "flex items-center justify-between" }
const _hoisted_3 = { class: "flex items-center flex-wrap gap-4" }
const _hoisted_4 = {
  key: 0,
  class: "flex items-center text-sm text-[#606266] mr-2"
}
const _hoisted_5 = ["href"]
const _hoisted_6 = ["href"]
const _hoisted_7 = ["href"]
const _hoisted_8 = ["href"]
const _hoisted_9 = ["href"]
const _hoisted_10 = { class: "hover:underline text-ellipsis overflow-hidden max-w-[280px] textClass" }
const _hoisted_11 = {
  key: 0,
  class: "textClass"
}
const _hoisted_12 = {
  key: 0,
  class: "textClass"
}
const _hoisted_13 = {
  key: 1,
  class: "textClass"
}
const _hoisted_14 = { class: "textClass" }
const _hoisted_15 = ["href"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")
  const _component_arrow_down = _resolveComponent("arrow-down")
  const _component_el_icon = _resolveComponent("el-icon")
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")
  const _component_el_dropdown = _resolveComponent("el-dropdown")
  const _component_el_popover = _resolveComponent("el-popover")
  const _component_el_table_column = _resolveComponent("el-table-column")
  const _component_el_table = _resolveComponent("el-table")
  const _component_el_skeleton = _resolveComponent("el-skeleton")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode($setup["BranchDropdown"], {
          onChangeBranch: $setup.changeBranch,
          "current-branch": $props.currentBranch,
          branches: $props.branches
        }, null, 8 /* PROPS */, ["current-branch", "branches"]),
        _createCommentVNode("        <el-breadcrumb separator=\"/\">"),
        _createCommentVNode("          <el-breadcrumb-item>"),
        _createCommentVNode("            <a :href=\"`/${prefixPath}/${namespacePath}/files/${currentBranch}`\">{{ namespacePath.split('/')[1] }}</a>"),
        _createCommentVNode("          </el-breadcrumb-item>"),
        _createCommentVNode("          <el-breadcrumb-item v-for=\"path in breadcrumb\" :key=\"path\">"),
        _createCommentVNode("            <a :href=\"`/${prefixPath}/${namespacePath}/files/${currentBranch}${path}`\">{{ extractNameFromPath(path) }}</a>"),
        _createCommentVNode("          </el-breadcrumb-item>"),
        _createCommentVNode("        </el-breadcrumb>")
      ]),
      ($setup.lastCommit)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createCommentVNode("        <div class=\"flex items-center mr-4 py-[1px] md:hidden\">"),
            _createCommentVNode("          <el-avatar :size=\"24\" class=\"mr-1\" src=\"https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png\" />"),
            _createCommentVNode("          1 {{ $t('all.contributors') }}"),
            _createCommentVNode("        </div>"),
            _createElementVNode("a", {
              href: `/${$setup.prefixPath}/${$props.namespacePath}/commits`,
              class: "ml-4 flex items-center px-4 py-[5px] border border-[#DCDFE6] rounded-[100px]"
            }, [
              _createVNode(_component_SvgIcon, {
                name: "commits",
                class: "mr-2"
              }),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('all.commits')), 1 /* TEXT */)
            ], 8 /* PROPS */, _hoisted_5),
            ($props.canWrite)
              ? (_openBlock(), _createBlock(_component_el_dropdown, {
                  key: 0,
                  class: "ml-4"
                }, {
                  dropdown: _withCtx(() => [
                    _createVNode(_component_el_dropdown_menu, null, {
                      default: _withCtx(() => [
                        _createElementVNode("a", {
                          href: `/${$setup.prefixPath}/${$props.namespacePath}/main/new`
                        }, [
                          _createVNode(_component_el_dropdown_item, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('all.createNewFile')), 1 /* TEXT */)
                            ]),
                            _: 1 /* STABLE */
                          })
                        ], 8 /* PROPS */, _hoisted_6),
                        _createElementVNode("a", {
                          href: `/${$setup.prefixPath}/${$props.namespacePath}/main/upload`
                        }, [
                          _createVNode(_component_el_dropdown_item, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('all.uploadFile')), 1 /* TEXT */)
                            ]),
                            _: 1 /* STABLE */
                          })
                        ], 8 /* PROPS */, _hoisted_7)
                      ]),
                      _: 1 /* STABLE */
                    })
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_el_button, { type: "default" }, {
                      default: _withCtx(() => [
                        _createTextVNode(" + " + _toDisplayString(_ctx.$t('all.addFile')) + " ", 1 /* TEXT */),
                        _createVNode(_component_el_icon, { class: "el-icon--right pl-1" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_arrow_down)
                          ]),
                          _: 1 /* STABLE */
                        })
                      ]),
                      _: 1 /* STABLE */
                    })
                  ]),
                  _: 1 /* STABLE */
                }))
              : _createCommentVNode("v-if", true)
          ]))
        : _createCommentVNode("v-if", true)
    ]),
    ($setup.files)
      ? (_openBlock(), _createBlock(_component_el_table, {
          key: 0,
          data: $setup.files,
          "row-class-name": $setup.tableRowClassName,
          style: {"width":"100%"},
          class: "filesTable"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_table_column, {
              prop: "name",
              label: "文件名"
            }, {
              default: _withCtx((scope) => [
                (scope.row.type === 'dir')
                  ? (_openBlock(), _createElementBlock("a", {
                      key: 0,
                      href: `/${$setup.prefixPath}/${$props.namespacePath}/files/${$props.currentBranch}/${scope.row.path}`,
                      class: "hover:underline whitespace-nowrap text-ellipsis overflow-hidden textClass"
                    }, _toDisplayString(scope.row.name), 9 /* TEXT, PROPS */, _hoisted_8))
                  : ($setup.canPreview(scope.row))
                    ? (_openBlock(), _createElementBlock("a", {
                        key: 1,
                        href: `/${$setup.prefixPath}/${$props.namespacePath}/blob/${$props.currentBranch}/${scope.row.path}`,
                        class: "hover:underline whitespace-nowrap text-ellipsis overflow-hidden textClass"
                      }, _toDisplayString(scope.row.name), 9 /* TEXT, PROPS */, _hoisted_9))
                    : (_openBlock(), _createBlock(_component_el_popover, {
                        key: 2,
                        placement: "top",
                        width: 270,
                        trigger: "hover",
                        effect: "dark",
                        content: !!$setup.lastCommit ? _ctx.$t('all.notSupportPreview') : _ctx.$t('all.syncNotSupportPreview')
                      }, {
                        reference: _withCtx(() => [
                          _createElementVNode("div", _hoisted_10, _toDisplayString(scope.row.name), 1 /* TEXT */)
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["content"]))
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_el_table_column, { label: "大小" }, {
              default: _withCtx((scope) => [
                (scope.row.type === 'file')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString($setup.formatBytes(scope.row.size)), 1 /* TEXT */))
                  : _createCommentVNode("v-if", true)
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_el_table_column, { label: "类型" }, {
              default: _withCtx((scope) => [
                (scope.row.type === 'dir')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_12, "文件夹"))
                  : _createCommentVNode("v-if", true),
                (scope.row.type === 'file')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(scope.row.name.split('.')[scope.row.name.split('.').length - 1]) + "文件", 1 /* TEXT */))
                  : _createCommentVNode("v-if", true)
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_el_table_column, { label: "修改时间" }, {
              default: _withCtx((scope) => [
                _createElementVNode("span", _hoisted_14, _toDisplayString($setup.parseTime(scope.row.commit.committer_date)), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_el_table_column, {
              label: "操作",
              width: "120"
            }, {
              default: _withCtx((scope) => [
                (scope.row.type === 'file' && $setup.canDownload(scope.row))
                  ? (_openBlock(), _createElementBlock("a", {
                      key: 0,
                      class: "downloadButton",
                      href: `/${$setup.prefixPath}/${$props.namespacePath}/resolve/${$props.currentBranch}/${scope.row.path}?download=true&lfs=${scope.row.lfs}&lfs_path=${scope.row.lfs_relative_path}`,
                      download: ""
                    }, " 下载 ", 8 /* PROPS */, _hoisted_15))
                  : _createCommentVNode("v-if", true)
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["data"]))
      : _createCommentVNode("v-if", true),
    _createCommentVNode("    <div v-if=\"!loading\" class=\"flex items-center justify-between min-h-[36px] mt-4 px-3 py-2 border border-[#DCDFE6] bg-[#F5F7FA] rounded-t-[4px]\">"),
    _createCommentVNode("      <div v-if=\"lastCommit\" class=\"flex items-center text-sm overflow-hidden mr-2\">"),
    _createCommentVNode("        <div class=\"flex items-center mr-2\">"),
    _createCommentVNode("          <el-avatar :size=\"24\" class=\"mr-2\" :src=\"lastCommitAvatar\" />"),
    _createCommentVNode("          <a href=\"#\" class=\"text-[#303133] hover:underline\">{{ lastCommit.author_name }}</a>"),
    _createCommentVNode("        </div>"),
    _createCommentVNode("        <a :href=\"`/${prefixPath}/${namespacePath}/commit/${lastCommit.id}`\" class=\"mr-2 text-[#606266] truncate hover:underline\">{{ lastCommit.message }}</a>"),
    _createCommentVNode("        <a :href=\"`/${prefixPath}/${namespacePath}/commit/${lastCommit.id}`\" class=\"rounded border border-[#DCDFE6] text-xs text-[#606266] px-3 py-[2px] hover:underline\">"),
    _createCommentVNode("          {{ lastCommit.id && lastCommit.id.substring(0, 7) }}"),
    _createCommentVNode("        </a>"),
    _createCommentVNode("      </div>"),
    _createCommentVNode("      <div v-if=\"lastCommit\" class=\"text-[#909399] text-sm cursor-pointer flex-shrink-0 md:hidden\">"),
    _createCommentVNode("        <el-popover"),
    _createCommentVNode("          width=\"158\""),
    _createCommentVNode("          placement=\"top\""),
    _createCommentVNode("          effect=\"dark\""),
    _createCommentVNode("          trigger=\"hover\""),
    _createCommentVNode("          :content=\"beiJingTimeParser(lastCommit.committer_date)\""),
    _createCommentVNode("        >"),
    _createCommentVNode("          <template #reference>"),
    _createCommentVNode("            {{ format(beiJingTimeParser(lastCommit.committer_date), locale=='en' ? 'en_US' : 'zh_CN') }}"),
    _createCommentVNode("          </template>"),
    _createCommentVNode("        </el-popover>"),
    _createCommentVNode("      </div>"),
    _createCommentVNode("    </div>"),
    _createCommentVNode("    <div v-if=\"files\" v-for=\"file in files\""),
    _createCommentVNode("      class=\"flex items-center justify-between px-3 py-2 border border-t-0 border-[#DCDFE6] last-of-type:rounded-b-[4px]\">"),
    _createCommentVNode("      <div class=\"flex items-center w-[34%]\" :title=\"file.name\">"),
    _createCommentVNode("        <svg class=\"flex-shrink-0\" v-if=\"file.type === 'dir'\" xmlns=\"http://www.w3.org/2000/svg\" width=\"14\" height=\"15\""),
    _createCommentVNode("          viewBox=\"0 0 14 15\" fill=\"none\">"),
    _createCommentVNode("          <path"),
    _createCommentVNode("            d=\"M3.52949 1.229C2.5494 1.229 2.05935 1.229 1.68501 1.41974C1.35573 1.58752 1.08801 1.85524 0.920231 2.18452C0.729492 2.55887 0.729492 3.04891 0.729492 4.02901V10.0373C0.729492 11.3441 0.729492 11.9975 0.98381 12.4966C1.20751 12.9357 1.56447 13.2926 2.00351 13.5164C2.50264 13.7707 3.15604 13.7707 4.46283 13.7707H9.53783C10.8446 13.7707 11.498 13.7707 11.9971 13.5164C12.4362 13.2926 12.7931 12.9357 13.0168 12.4966C13.2712 11.9975 13.2712 11.3441 13.2712 10.0373V7.29567C13.2712 5.98888 13.2712 5.33549 13.0168 4.83636C12.7931 4.39731 12.4362 4.04036 11.9971 3.81666C11.498 3.56234 10.8446 3.56234 9.53783 3.56234H8.89755C8.58581 3.56234 8.42993 3.56234 8.2892 3.52677C8.05664 3.46799 7.84784 3.33894 7.69126 3.15722C7.59651 3.04725 7.5268 2.90784 7.38738 2.629V2.629C7.17826 2.21076 7.0737 2.00163 6.93157 1.83668C6.6967 1.56409 6.3835 1.37053 6.03465 1.28236C5.82356 1.229 5.58975 1.229 5.12213 1.229H3.52949Z\""),
    _createCommentVNode("            fill=\"#8AA2FF\" />"),
    _createCommentVNode("        </svg>"),
    _createCommentVNode("        <svg class=\"flex-shrink-0\" v-else xmlns=\"http://www.w3.org/2000/svg\" width=\"14\" height=\"15\" viewBox=\"0 0 14 15\""),
    _createCommentVNode("          fill=\"none\">"),
    _createCommentVNode("          <path"),
    _createCommentVNode("            d=\"M8.16634 1.95817V1.95817C8.16634 3.08384 8.16634 3.64668 8.38433 4.0745C8.57608 4.45083 8.88204 4.75679 9.25836 4.94853C9.68618 5.16652 10.249 5.16652 11.3747 5.16652V5.16652M11.6663 5.90865V10.1332C11.6663 11.2533 11.6663 11.8133 11.4484 12.2412C11.2566 12.6175 10.9506 12.9234 10.5743 13.1152C10.1465 13.3332 9.58645 13.3332 8.46634 13.3332H5.53301C4.4129 13.3332 3.85285 13.3332 3.42503 13.1152C3.0487 12.9234 2.74274 12.6175 2.55099 12.2412C2.33301 11.8133 2.33301 11.2533 2.33301 10.1332V4.86651C2.33301 3.7464 2.33301 3.18635 2.55099 2.75852C2.74274 2.3822 3.0487 2.07624 3.42503 1.88449C3.85285 1.6665 4.4129 1.6665 5.53301 1.6665H7.42419C7.91337 1.6665 8.15796 1.6665 8.38814 1.72176C8.59221 1.77076 8.7873 1.85157 8.96624 1.96122C9.16808 2.08491 9.34103 2.25786 9.68693 2.60376L10.7291 3.64591C11.075 3.99182 11.2479 4.16477 11.3716 4.3666C11.4813 4.54555 11.5621 4.74063 11.6111 4.94471C11.6663 5.17488 11.6663 5.41947 11.6663 5.90865Z\""),
    _createCommentVNode("            stroke=\"#606266\" stroke-linecap=\"round\" stroke-linejoin=\"round\" />"),
    _createCommentVNode("        </svg>"),
    _createCommentVNode("        <a v-if=\"file.type === 'dir'\" :href=\"`/${prefixPath}/${namespacePath}/files/${currentBranch}/${file.path}`\""),
    _createCommentVNode("          class=\"ml-2 text-sm text-[#303133] hover:underline whitespace-nowrap text-ellipsis overflow-hidden\">"),
    _createCommentVNode("          {{ file.name }}"),
    _createCommentVNode("        </a>"),
    _createCommentVNode("        <a v-else-if=\"canPreview(file)\" :href=\"`/${prefixPath}/${namespacePath}/blob/${currentBranch}/${file.path}`\""),
    _createCommentVNode("          class=\"ml-2 text-sm text-[#303133] hover:underline whitespace-nowrap text-ellipsis overflow-hidden\">"),
    _createCommentVNode("          {{ file.name }}"),
    _createCommentVNode("        </a>"),
    _createCommentVNode("        <el-popover v-else placement=\"top\" :width=\"270\" trigger=\"hover\" effect=\"dark\""),
    _createCommentVNode("          :content=\"!!lastCommit ? $t('all.notSupportPreview') : $t('all.syncNotSupportPreview')\">"),
    _createCommentVNode("          <template #reference>"),
    _createCommentVNode("            <div class=\"ml-2 text-sm text-[#303133] hover:underline text-ellipsis overflow-hidden max-w-[280px]\">{{"),
    _createCommentVNode("              file.name }}</div>"),
    _createCommentVNode("          </template>"),
    _createCommentVNode("        </el-popover>"),
    _createCommentVNode("      </div>"),
    _createCommentVNode("      <div class=\"flex items-center justify-end pr-3 text-sm text-[#606266] flex-shrink-0 text-right w-[14%]\">"),
    _createCommentVNode("        <span v-if=\"file.type === 'file'\">{{ formatBytes(file.size) }}</span>"),
    _createCommentVNode("        <span v-if=\"file.lfs\""),
    _createCommentVNode("          class=\"flex items-center gap-1 text-xs text-[#344054] ml-2 rounded px-1 border border-[#909399]\">"),
    _createCommentVNode("          <svg width=\"12\" height=\"12\" viewBox=\"0 0 12 12\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">"),
    _createCommentVNode("            <path opacity=\"0.12\""),
    _createCommentVNode("              d=\"M11 6.0001V3.64101C11 3.55359 10.9541 3.47258 10.8792 3.4276C10.7998 3.37995 10.7005 3.38034 10.6214 3.42859L8.3853 4.79381C7.58955 5.27964 7.19167 5.52255 6.77093 5.63443C6.26575 5.76876 5.73425 5.76876 5.22907 5.63443C4.80833 5.52255 4.41045 5.27964 3.61469 4.79381L1.37857 3.42859C1.29953 3.38034 1.20024 3.37995 1.12083 3.4276C1.04587 3.47258 1 3.55359 1 3.64101V6.0001C1 6.77867 1 7.16795 1.0882 7.5236C1.22 8.05511 1.49477 8.5404 1.88271 8.92687C2.14231 9.18548 2.47612 9.38577 3.14373 9.78634L3.53042 10.0183C4.42832 10.5571 4.87727 10.8265 5.35653 10.9317C5.78046 11.0248 6.21954 11.0248 6.64346 10.9317C7.12273 10.8265 7.57168 10.5571 8.46958 10.0183L8.85627 9.78634L8.85627 9.78633C9.52389 9.38577 9.85769 9.18548 10.1173 8.92687C10.5052 8.5404 10.78 8.05511 10.9118 7.5236C11 7.16795 11 6.77867 11 6.0001Z\""),
    _createCommentVNode("              fill=\"#2F384C\" />"),
    _createCommentVNode("            <path"),
    _createCommentVNode("              d=\"M3.74994 4.75L8.24998 2M6 6.25L10.5 3.5M6 6.25L1.5 3.5M6 6.25V11.25M1 4.4059V7.5941C1 7.92444 1 8.08961 1.0474 8.23802C1.08934 8.36936 1.15799 8.49061 1.24903 8.59414C1.35191 8.71114 1.49354 8.79612 1.77681 8.96608L5.17681 11.0061C5.47611 11.1857 5.62576 11.2755 5.78551 11.3105C5.92682 11.3416 6.07318 11.3416 6.21449 11.3105C6.37424 11.2755 6.52389 11.1857 6.82319 11.0061L10.2232 8.96608C10.5065 8.79612 10.6481 8.71114 10.751 8.59414C10.842 8.49061 10.9107 8.36936 10.9526 8.23802C11 8.08961 11 7.92444 11 7.5941V4.4059C11 4.07556 11 3.91039 10.9526 3.76198C10.9107 3.63064 10.842 3.50939 10.751 3.40586C10.6481 3.28886 10.5065 3.20388 10.2232 3.03392L6.82319 0.993916C6.52389 0.814336 6.37424 0.724546 6.21449 0.689464C6.07318 0.658433 5.92682 0.658433 5.78551 0.689464C5.62576 0.724546 5.47611 0.814336 5.17681 0.993916L1.77681 3.03392C1.49354 3.20388 1.35191 3.28886 1.24903 3.40586C1.15799 3.50939 1.08934 3.63064 1.0474 3.76198C1 3.91039 1 4.07556 1 4.4059Z\""),
    _createCommentVNode("              stroke=\"#2F384C\" stroke-linecap=\"round\" stroke-linejoin=\"round\" />"),
    _createCommentVNode("          </svg>"),
    _createCommentVNode("          LFS"),
    _createCommentVNode("        </span>"),
    _createCommentVNode("        <a v-if=\"file.type === 'file' && canDownload(file)\" class=\"ml-2\""),
    _createCommentVNode("          :href=\"`/${prefixPath}/${namespacePath}/resolve/${currentBranch}/${file.path}?download=true&lfs=${file.lfs}&lfs_path=${file.lfs_relative_path}`\""),
    _createCommentVNode("          download>"),
    _createCommentVNode("          <svg xmlns=\"http://www.w3.org/2000/svg\" width=\"14\" height=\"15\" viewBox=\"0 0 14 15\" fill=\"none\">"),
    _createCommentVNode("            <path"),
    _createCommentVNode("              d=\"M6.99967 1.6665V10.4165M6.99967 10.4165L10.4997 6.9165M6.99967 10.4165L3.49967 6.9165M2.33301 10.9998V11.7332C2.33301 12.2932 2.33301 12.5732 2.442 12.7872C2.53787 12.9753 2.69086 13.1283 2.87902 13.2242C3.09293 13.3332 3.37296 13.3332 3.93301 13.3332H10.0663C10.6264 13.3332 10.9064 13.3332 11.1203 13.2242C11.3085 13.1283 11.4615 12.9753 11.5573 12.7872C11.6663 12.5732 11.6663 12.2932 11.6663 11.7332V10.9998\""),
    _createCommentVNode("              stroke=\"#606266\" stroke-linecap=\"round\" stroke-linejoin=\"round\" />"),
    _createCommentVNode("          </svg>"),
    _createCommentVNode("        </a>"),
    _createCommentVNode("      </div>"),
    _createCommentVNode("      <a href=\"#\" class=\"text-[#606266] w-[37%] pl-3 text-sm truncate hover:underline\">"),
    _createCommentVNode("        {{ file.commit.message }}"),
    _createCommentVNode("      </a>"),
    _createCommentVNode("      <div class=\"text-[#909399] w-[15%] text-sm text-right cursor-pointer md:hidden\">"),
    _createCommentVNode("        <el-popover width=\"158\" placement=\"top\" effect=\"dark\" trigger=\"hover\""),
    _createCommentVNode("          :content=\"beiJingTimeParser(file.commit.committer_date)\">"),
    _createCommentVNode("          <template #reference>"),
    _createCommentVNode("            {{ format(beiJingTimeParser(file.commit.committer_date), locale == 'en' ? 'en_US' : 'zh_CN') }}"),
    _createCommentVNode("          </template>"),
    _createCommentVNode("        </el-popover>"),
    _createCommentVNode("      </div>"),
    _createCommentVNode("    </div>"),
    ($setup.loading)
      ? (_openBlock(), _createBlock(_component_el_skeleton, {
          key: 1,
          class: "mt-4",
          rows: 5,
          animated: ""
        }))
      : _createCommentVNode("v-if", true)
  ]))
}