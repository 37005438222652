<template>
  <div
  >
    <el-button
      v-if="showSyncButton"
      type="default"
      class="!rounded-lg"
      :disabled="syncInprogress"
      @click="handleSyncRepo"
    >
<!--      <SvgIcon name="sync" class="mr-2" />-->
      {{ syncInprogress ? $t("repo.source.syncing") : $t("repo.source.syncButton") }}
    </el-button>
    <DeployDropdown
      v-if="repoType === 'model' && admin && enableEndpoint && !!httpCloneUrl"
      :modelId="namespacePath"
    />
    <el-button type="primary"  v-if="repoType === 'model' && admin && enableFinetune && !!httpCloneUrl"  @click="toFinetunePage" style="width: 100%">
      {{ $t('finetune.title') }}
    </el-button>
<!--    <div-->
<!--      class="flex px-[12px] py-[5px] mr-4 justify-center items-center gap-1 rounded-lg bg-[#FFF] border border-[#D0D5DD] shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] cursor-pointer"-->
<!--      v-if="repoType === 'model' && admin && enableFinetune && !!httpCloneUrl"-->
<!--      @click="toFinetunePage"-->
<!--    >-->
<!--&lt;!&ndash;      <SvgIcon&ndash;&gt;-->
<!--&lt;!&ndash;        name="model_finetune_create"&ndash;&gt;-->
<!--&lt;!&ndash;        class="mr-1"&ndash;&gt;-->
<!--&lt;!&ndash;      />&ndash;&gt;-->
<!--      <div class="text-sm"></div>-->
<!--    </div>-->
    <el-button type="primary"  v-if="!!httpCloneUrl"  @click="clickDownload" style="width: 100%">
      {{ $t(downloadButtonKey) }}
    </el-button>
<!--    <div-->
<!--      v-if="!!httpCloneUrl"-->
<!--      class="flex px-[12px] py-[5px] justify-center items-center gap-1 rounded-lg bg-[#3250BD] shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] cursor-pointer"-->
<!--      @click="clickDownload"-->
<!--    >-->
<!--&lt;!&ndash;      <SvgIcon&ndash;&gt;-->
<!--&lt;!&ndash;        name="download"&ndash;&gt;-->
<!--&lt;!&ndash;        class="mr-1"&ndash;&gt;-->
<!--&lt;!&ndash;      />&ndash;&gt;-->
<!--      <div class="text-[#fff] text-sm">{{ $t(downloadButtonKey) }}</div>-->
<!--    </div>-->
    <el-dialog
        v-model="informationVisible"
        title="完善信息"
        class="w-[300px]" :before-close="beroreClose">
      <el-alert :title="'完善信息后即可下载' + getRepoType(repoType)" type="warning" show-icon :closable="false" class="alertClass" />
      <el-form ref="ruleFormRef" :model="form" :rules="rules" label-width="auto">
        <el-form-item label="企业名称" prop="enterpriseName">
          <el-input v-model="form.enterpriseName" placeholder="请输入企业名称" />
        </el-form-item>
        <el-form-item label="联系人姓名" prop="contactName">
          <el-input v-model="form.contactName" placeholder="请输入联系人姓名" />
        </el-form-item>
        <el-form-item label="联系人电话" prop="phone">
          <el-input v-model="form.phone" placeholder="请输入联系人电话" />
        </el-form-item>
        <el-form-item label="联系人职位" prop="position">
          <el-input v-model="form.position" placeholder="请输入联系人职位" />
        </el-form-item>
      </el-form>
      <div class="text-right">
        <el-button type="primary" @click="submitForm" >
          提交
        </el-button>
        <el-button @click="resetForm">取消</el-button>
      </div>
    </el-dialog>
    <el-dialog
      v-model="cloneRepositoryVisible"
      title="下载模型"
      class="md:!w-[80%]"
    >
      <div class="rounded-t border-t border-x border-[#EBEEF5] mt-4">
        <div class="flex items-center px-3 py-2 bg-[#F5F7FA] text-[#303133]">
<!--          <SvgIcon-->
<!--            name="clone"-->
<!--            class="mr-1"-->
<!--          />-->
          {{ $t(downloadModalKey) }}
        </div>
      </div>
      <el-tabs
        v-model="activeCloneType"
        class="border border-[#EBEEF5] mb-8 clone-tabs"
      >
        <el-tab-pane
          label="HTTPS"
          name="https"
        >
          <div
            class="flex flex-col gap-1 px-3 py-2 border-t border-[#EBEEF5] bg-[#ffffff] text-[#303133] break-all"
          >
            <div
              class="my-[4px]"
              v-if="currentUser"
            >
              <el-checkbox
                v-model="useToken"
                :label="$t('application_spaces.gradioGuide.useToken')"
                size="large"
              />
            </div>
            <div class="text-[#909399]"># {{ $t('all.lfsTips') }}</div>
            <markdown-viewer
              v-if="useToken"
              :content="httpsCloneCodeWithTokenMarkdown"
            ></markdown-viewer>
            <markdown-viewer
              v-else
              :content="httpsCloneCodeMarkdown"
            ></markdown-viewer>
            <div class="text-[#909399]"># {{ $t('all.lfsTips2') }}</div>
            <markdown-viewer
              :content="getMarkdownCode('  GIT_LFS_SKIP_SMUDGE=1', 'bash')"
            ></markdown-viewer>
          </div>
        </el-tab-pane>
        <el-tab-pane
          label="SSH"
          name="ssh"
        >
          <div
            class="flex flex-col gap-1 px-3 py-2 border-t border-[#EBEEF5] bg-[#ffffff] text-[#303133] break-all"
          >
            <div class="text-[#909399]"># {{ $t('all.lfsTips') }}</div>
            <markdown-viewer :content="sshCloneCodeMarkdown"></markdown-viewer>
            <div class="text-[#909399]"># {{ $t('all.lfsTips2') }}</div>
            <markdown-viewer
              :content="getMarkdownCode('  GIT_LFS_SKIP_SMUDGE=1', 'bash')"
            ></markdown-viewer>
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
    <el-dialog
        v-model="MessageBoxVisible"
        title="提示"
        width="420"
    >
      <div class="message">下载{{getRepoType(repoType)}}前请先登录</div>
      <template #footer>
        <div class="dialog-footer">
          <el-button type="primary" @click="MessageBoxVisible = false">
            确定
          </el-button>
          <el-button @click="MessageBoxVisible = false">取消</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
  import { computed, ref, inject, onMounted, watch,reactive } from 'vue'
  import MarkdownViewer from '../shared/viewers/MarkdownViewer.vue'
  import DeployDropdown from './DeployDropdown.vue'
  import SvgIcon from './SvgIcon.vue'
  import { getRepoType } from '../../stores/utils'
  import { useCookies } from 'vue3-cookies'
  import jwtFetch from '../../packs/jwtFetch'
  import csrfFetch from "../../packs/csrfFetch"
  import { ElMessage,ElMessageBox } from "element-plus"


  const { cookies } = useCookies()

  const props = defineProps({
    httpCloneUrl: String,
    sshCloneUrl: String,
    repoType: String,
    userName: String,
    namespacePath: String,
    admin: Boolean,
    repo: Object,
    enableEndpoint: Boolean,
    enableFinetune: Boolean,
    improveInformation:Boolean
  })
  const improveInformation = ref(props.improveInformation)
  const csghubServer = inject('csghubServer')
  const activeCloneType = ref('https')
  const cloneRepositoryVisible = ref(false)
  const useToken = ref(false)
  const currentUser = ref(cookies.get('current_user'))
  const accessToken = ref('')
  const informationVisible = ref(false)
  const ruleFormRef = ref(null)
  const MessageBoxVisible = ref(null)
  const form = reactive({
    enterpriseName:'',
    contactName:'',
    phone:'',
    position:''
  })
  const validatePhone = (rule, value, callback) => {
    const phoneRegex = /^(13[0-9]|14[5|7|9]|15[0-3|5-9]|16[2|5|6|7]|17[0-8]|18[0-9]|19[8|9])\d{8}$/
    if (!value) {
      return callback(new Error('联系人电话不能为空'))
    } else if (!phoneRegex.test(value)) {
      return callback(new Error('联系人电话格式不正确'))
    } else {
      return callback()
    }
  }
  const rules = reactive({
    enterpriseName: [
      { required: true, message: '请输入企业名称', trigger: 'blur' },
    ],
    contactName: [
      { required: true, message: '请输入联系人姓名', trigger: 'blur' },
    ],
    phone: [
      { validator: validatePhone, trigger: 'blur' },
      { required: true, message: '请输入联系人电话', trigger: 'blur' },
    ],
    position: [
      { required: true, message: '请输入联系人职位', trigger: 'blur' },
    ],

  })

  const beroreClose = () => {
    ruleFormRef.value.resetFields()
    informationVisible.value = false
  }

  const submitForm = async () => {
    await ruleFormRef.value.validate(async (valid, fields) => {
      if (valid) {
        const submitUrl = `/internal_api/user_data/information`;
        const formData = new FormData();
        formData.append("enterpriseName", form.enterpriseName);
        formData.append("contactName", form.contactName);
        formData.append("phone", form.phone);
        formData.append("position", form.position);
        formData.append("userName", props.userName);
        const options = {
          method: "POST",
          body: formData
        }
        try {
          const response = await csrfFetch(submitUrl, options);
          if (!response.ok) {
            response.json().then(data => {
              ElMessage({
                message: data.message,
                type: "warning",
              })
            })
          } else {
            // 处理成功响应
            informationVisible.value = false
            ruleFormRef.value.resetFields()
            cloneRepositoryVisible.value = true
            improveInformation.value = true
            ElMessage({
              message: '提交成功',
              type: "success",
            });
          }
        } catch (error) {
          console.error(error);
        }
      }
    })
  }

  const clickDownload = () => {
    // 判断是否登录
    if(!props.userName){
      MessageBoxVisible.value = true
      return false
      // return ElMessageBox.alert(`下载${downloadButtonvalue()}前请登录`,'提示', {
      //   confirmButtonText: '确定'
      // })
    }
    // 判断是否已经完成信息完善
    if(improveInformation.value){
      cloneRepositoryVisible.value = true
    }else {
      informationVisible.value = true
    }
  }

  const resetForm = () => {
    informationVisible.value = false
    ruleFormRef.value.resetFields()
  }

  const showSyncButton = computed(() =>
    props.admin &&
    props.repo.source === 'opencsg' &&
    ['pending', 'inprogress', 'failed'].includes(props.repo.sync_status)
  )

  // 同步按钮禁用
  const syncInprogress = computed(() => {
    return props.repo.source === 'opencsg' && props.repo.sync_status === 'inprogress'
  })

  const getMarkdownCode = (code, lang, multiline = false) => {
    return `\`\`\`${lang}${multiline ? '' : '\n'}${code}${
      multiline ? '' : '\n'
    }\`\`\``
  }

  const httpsCloneCode = `
  git lfs install
  git clone ${props.httpCloneUrl}
`

  const httpsCloneCodeWithToken = ref(`
  git lfs install
  git clone https://${currentUser.value}:${
    accessToken.value
  }@${props.httpCloneUrl.replace('https://', '')}
  `)

  watch(accessToken, async (newAccessToken) => {
    httpsCloneCodeWithToken.value = `
  git lfs install
  git clone https://${
    currentUser.value
  }:${newAccessToken}@${props.httpCloneUrl.replace('https://', '')}
`
  })

  const httpsCloneCodeMarkdown = computed(() => {
    return getMarkdownCode(httpsCloneCode, 'bash', true)
  })

  const httpsCloneCodeWithTokenMarkdown = computed(() => {
    return getMarkdownCode(httpsCloneCodeWithToken.value, 'bash', true)
  })

  const sshCloneCode = `
  git lfs install
  git clone ${props.sshCloneUrl}
`

  const sshCloneCodeMarkdown = computed(() => {
    return getMarkdownCode(sshCloneCode, 'bash', true)
  })

  const downloadButtonKey = computed(() => {
    switch (props.repoType) {
      case 'dataset':
        return 'datasets.downloadDataset'
      case 'model':
        return 'models.download'
      case 'code':
        return 'codes.downloadCode'
      case 'space':
        return 'application_spaces.download'
      default:
        return ''
    }
  })

  const downloadButtonvalue = () => {
    switch (props.repoType) {
      case 'dataset':
        return '数据集'
      case 'model':
        return '模型'
      case 'code':
        return '仓库'
      default:
        return ''
    }
  }

  const downloadModalKey = computed(() => {
    switch (props.repoType) {
      case 'dataset':
        return 'datasets.downloadDataset'
      case 'model':
        return 'models.downloadModel'
      case 'code':
        return 'codes.downloadCode'
      case 'space':
        return 'application_spaces.downloadSpace'
      default:
        return ''
    }
  })

  function toFinetunePage() {
    window.location.href = `/finetune/new?model_id=${props.namespacePath}&repoType=${props.repoType}`
  }

  const fetchUserToken = async() => {
    if (!currentUser.value) return

    const res = await jwtFetch(
      `${csghubServer}/api/v1/user/${currentUser.value}/tokens?app=git`
    )
    if (!res.ok) {
      res.json().then((error) => {
        ElMessage({ message: error.msg, type: 'warning' })
      })
    } else {
      res.json().then((body) => {
        if (body.data) {
          accessToken.value = body.data[0].token
        }
      })
    }
  }

  const handleSyncRepo =  async () => {
    const syncUrl = `${csghubServer}/api/v1/${props.repoType}s/${props.namespacePath}/mirror_from_saas`
    const res = await jwtFetch(syncUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })

    if (!res.ok) {
      res.json().then(error => {
        ElMessage({message: error.msg, type: "warning"})
      })
    } else {
      res.json().then(body => {
        ElMessage({message: 'Sync repo success', type: "success"})
        setTimeout(() => {
          location.reload()
        }, 2000)
      })
    }
  }

  onMounted(() => {
    fetchUserToken()
  })
</script>
<style scoped>
.alertClass{
  margin-bottom: 8px;
}
.message{
  width: 100%;
  text-align: center;
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
  color: #1D2129;
  padding: 24px;
}
</style>
