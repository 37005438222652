<template>
  <div class="special" v-if="repoType === 'model'">
    <div class="classification flex mb-[16px]">
      <div class="classificationLable">
        <span class="icon-left"/>
        热门分类
      </div>
      <div class="classificationText">
        汇聚热门行业和技术资讯，包括技术趋势、应用场景、模型推荐、实践案例等
      </div>
    </div>
    <div class="specialBox">
      <specialTag :key="index" v-for="(item,index) in specialList" :item="item" @click="tagClick(item)">
        {{item.name}}
      </specialTag>
    </div>
  </div>
</template>
<script setup>
import {inject, ref,onMounted} from 'vue'
import specialTag from './specialTag.vue'
import { ElMessage } from "element-plus";

const props = defineProps({
  repoType:String
})

const csghubServer = inject('csghubServer')
const specialSytleList = ref([
  {
    shadow:'0px 0px 32px 0px #5B8FF933 inset',
    background: '#5B8FF91A',
    color:'#5B8FF9'
  },
  {
    shadow:'0px 0px 32px 0px #9580F933 inset',
    background: '#9580F91A',
    color:'#9580F9'
  },
  {
    shadow:'0px 0px 32px 0px #FA617833 inset',
    background: '#FA61781A',
    color:'#FA6178'
  },
  {
    shadow:'0px 0px 32px 0px #E8794A33 inset',
    background: '#E8794A1A',
    color:'#E8794A'
  },
  {
    shadow:'0px 0px 32px 0px #5AD8A633 inset',
    background: '#5AD8A629',
    color:'#5AD8A6'
  },
])

const specialList  = ref([])

const getAllSpecial = async () => {
  const response = await fetch(
      `${csghubServer}/api/v1/topics`
  )
  if (response.ok) {
    response.json().then((body) => {
     let list = body.data ? body.data.splice(0,5) : []
      list.forEach((item,index)=>{
        list[index] = {...item,...specialSytleList.value[index]}
      })
      list.push({
        name:'全部专题',
        shadow:'0px 0px 32px 0px #5D709233 inset',
        background: '#5D70921A',
        color:'#5D7092',
        href:'/allspecial'
      })
      specialList.value = list
    })
  } else {
    ElMessage.error('数据繁忙请稍后在试！')
  }
}

const tagClick = (item) => {
  if(item.href) return location.href = item.href
  location.href = `/special?id=${item.id}`
}

onMounted(()=>{
  getAllSpecial()
})
</script>
<style scoped>
.special{
    height: 132px;
    border: 1px solid #FFFFFF;
    background-color: #FFFFFFB2;
    padding: 16px;
    gap: 16px;
    margin-bottom: 16px;
}
.classificationLable{
  font-family: PingFang SC;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  display: flex;
  align-items: center;
  padding-right: 8px;
  display: flex;
}
.icon-left{
  display: inline-block;
  width: 2px;
  height: 16px;
  background-color: #2047D3;
  margin-right: 8px;
}
.classificationText{
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #6B7785;
}
.specialBox{
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
}
</style>