<template>
  <backPublic label="登录">
    <el-tabs v-model="activeName" class="demo-tabs" @tab-change="tabChange">
      <el-tab-pane label="密码登录" name="password" />
      <el-tab-pane label="短信登录" name="Code" />
    </el-tabs>
    <div>
      <el-form ref="ruleFormRef" :model="form" :rules="rules" label-width="auto">
        <el-form-item prop="name" v-if="activeName === 'password'">
          <el-input v-model="form.name" placeholder="请输入用户名(必填)" class="publicFromInput" />
        </el-form-item>
        <el-form-item prop="password" v-if="activeName === 'password'">
          <el-input v-model="form.password" type="password" placeholder="请输入密码(必填)" class="publicFromInput" />
        </el-form-item>
        <el-form-item prop="phone" v-if="activeName === 'Code'">
          <el-input v-model="form.phone" placeholder="请输入电话(必填)" class="publicFromInput" />
        </el-form-item>
        <el-form-item prop="code" v-if="activeName === 'Code'">
          <el-input v-model="form.code" placeholder="请输入短信验证码" class="publicFromInput codeInput">
            <template #append>
              <dev class="getCodeButtom" >
                <!-- 获取验证码 -->
                <el-button link type="primary" :disabled="isSending" @click="getCode">获取验证码{{ isSending ? `(${SendingTime})` : '' }}</el-button>
              </dev>
            </template>
          </el-input>
        </el-form-item>
      </el-form>
    </div>
    <el-button type="primary" class="publicButton" @click="login">登录</el-button>
    <div class="bottonText">
      <a class="bottomButtom" href="/signup">立即注册</a>
    </div>
  </backPublic>
</template>
<script setup>
import { reactive, ref } from 'vue'
import backPublic from './public.vue'
import csrfFetch from "../../packs/csrfFetch"
import { ElMessage } from "element-plus"

const ruleFormRef = ref(null)
const isSending = ref(false)
const SendingTime = ref(60)
const interval = ref(null)
const form = reactive({
  name: '',
  password: '',
  code: '',
  phone: ''
})
const activeName = ref('password')

const validatePhone = (rule, value, callback) => {
  const phoneRegex = /^(13[0-9]|14[5|7|9]|15[0-3|5-9]|16[2|5|6|7]|17[0-8]|18[0-9]|19[8|9])\d{8}$/
  if (!value) {
    return callback(new Error('电话不能为空'))
  } else if (!phoneRegex.test(value)) {
    return callback(new Error('电话格式不正确'))
  } else {
    return callback()
  }
}

const rules = reactive({
  name: [
    {
      required: true,
      message: '用户名不能为空',
      trigger: 'blur',
    },
  ],
  password: [
    {
      required: true,
      message: '密码不能为空',
      trigger: 'blur',
    },
  ],
  phone: [
    { validator: validatePhone, trigger: 'blur' }
  ],
  code: [
    {
      required: true,
      message: '短信验证码不能为空',
      trigger: 'blur',
    },
  ],
})

const tabChange = () => {
  if (!ruleFormRef.value) return
  ruleFormRef.value.resetFields()
}

const getCode = async () => {
  if (!ruleFormRef.value) return
  await ruleFormRef.value.validateField('phone', async (valid) => {
    if (valid) {
      const formData = new FormData();
      formData.append("phone", form.phone);
      const options = {
        method: "POST",
        body: formData
      }
      const response = await csrfFetch('/sendCaptcha', options);
      if (!response.ok) {
        response.json().then((val) => {
          ElMessage({
            message: val.message,
            type: "warning",
          })
        })
      } else {
        response.json().then((val) => {
          ElMessage({
            message: '发出成功',
            type: "success",
          })
          sendingCode()
        })
      }
    }
  })
}

const sendingCode = () => {
  isSending.value = true
  interval.value = setInterval(() => {
    if(SendingTime.value === 0) {
      isSending.value = false
      SendingTime.value = 60
      clearInterval(interval.value)
      return false
    }
    SendingTime.value--
  },1000)
}

const login = async () => {
  if (!ruleFormRef.value) return
  await ruleFormRef.value.validate((valid, fields) => {
    if (valid) {
      if (activeName.value === 'password') passwordLogin()
      if (activeName.value === 'Code') CodeLogin()
    }
  })
}

const passwordLogin = async () => {
  const formData = new FormData();
  formData.append("name", form.name);
  formData.append("password", form.password);
  const options = {
    method: "POST",
    body: formData
  }
  const response = await csrfFetch('/login', options);
  if (!response.ok) {
    response.json().then((val) => {
      ElMessage({
        message: val.message,
        type: "warning",
      })
    })
  } else {
    response.json().then((val) => {
      ElMessage({
        message: '登录成功',
        type: "success",
      })
      window.location.href = '/models';
    })
  }
}

const CodeLogin = async () => {
  const formData = new FormData();
  formData.append("phone", form.phone);
  formData.append("code", form.code);
  const options = {
    method: "POST",
    body: formData
  }
  const response = await csrfFetch('/captchaLogin', options);
  if (!response.ok) {
    response.json().then((val) => {
      ElMessage({
        message: val.message,
        type: "warning",
      })
    })
  } else {
    ElMessage({
      message: '登录成功',
      type: "success",
    })
    window.location.href = '/models';
  }
}

</script>
<style scoped>
.codeInput /deep/ .el-input-group__append {
  background-color: var(--el-input-bg-color, var(--el-fill-color-blank));
  box-shadow: 1px 0 0 0 var(--el-input-border-color, var(--el-border-color)), 
            0 1px 0 0 var(--el-input-border-color, var(--el-border-color)), 
            0 -1px 0 0 var(--el-input-border-color, var(--el-border-color));
  padding: 0 8px;
  width: 130px;
}

.codeInput /deep/ .el-input__wrapper {
  box-shadow: -1px 0 0 0 var(--el-input-border-color, var(--el-border-color)), 0 1px 0 0 var(--el-input-border-color, var(--el-border-color)), 0 -1px 0 0 var(--el-input-border-color, var(--el-border-color));
}

.getCodeButtom {
  
  text-align: center;
}

.demo-tabs /deep/ .el-tabs__header {
  margin: 0 0 20px;
}
.codeInput /deep/ .el-input-group__append button.el-button{
  color: #2047D3;
  /* border-color: #2047D3; */
  font-size: 16px;
}
.codeInput /deep/ .el-input-group__append button.el-button:hover{
  color: #409EFF;
}
</style>