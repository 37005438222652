import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex justify-center md:flex-col px-[24px] rounded-[8px] md:px-[50px] sm:px-[20px] max-w-[1280px] m-auto bg-white min-h-[calc(100vh-129px)]" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Menu = _resolveComponent("Menu")
  const _component_OrganizationEdit = _resolveComponent("OrganizationEdit")
  const _component_OrganizationMembers = _resolveComponent("OrganizationMembers")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Menu, {
      class: "max-w-[411px] md:mb-[24px]",
      nickName: $data.organizationNickname,
      homepage: $data.organizationHomepage,
      name: $props.organization.name,
      logo: $data.organizationAvatar
    }, null, 8 /* PROPS */, ["nickName", "homepage", "name", "logo"]),
    ($props.action === 'edit')
      ? (_openBlock(), _createBlock(_component_OrganizationEdit, {
          key: 0,
          class: "grow py-[24px]",
          organization: $props.organization,
          onUpdateOrganization: $options.updateOrganization
        }, null, 8 /* PROPS */, ["organization", "onUpdateOrganization"]))
      : _createCommentVNode("v-if", true),
    ($props.action === 'members')
      ? (_openBlock(), _createBlock(_component_OrganizationMembers, {
          key: 1,
          class: "grow py-[24px]",
          admin: $props.admin,
          organization: $props.organization
        }, null, 8 /* PROPS */, ["admin", "organization"]))
      : _createCommentVNode("v-if", true)
  ]))
}