import { createVNode as _createVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "flex justify-center md:flex-col px-[24px] py-[36px] my-[24px] rounded-[8px] md:px-[50px] sm:px-[20px] max-w-[1280px] m-auto bg-white" }
const _hoisted_2 = { class: "grow py-[24px]" }
const _hoisted_3 = { class: "max-w-[512px]" }
const _hoisted_4 = { class: "mb-[16px]" }
const _hoisted_5 = { class: "mb-[4px] text-[#303133] text-[20px] font-semibold" }
const _hoisted_6 = { class: "text-[#606266] text-[14px]" }
const _hoisted_7 = { class: "dialog-footer" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_input = _resolveComponent("el-input")
  const _component_el_form_item = _resolveComponent("el-form-item")
  const _component_el_form = _resolveComponent("el-form")
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_dialog = _resolveComponent("el-dialog")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["Menu"], {
      class: "max-w-[411px] md:mb-[24px]",
      name: $props.name,
      email: $props.email,
      displayName: $props.displayName,
      avatar: $props.avatar
    }, null, 8 /* PROPS */, ["name", "email", "displayName", "avatar"]),
    _createCommentVNode(" API Key list "),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h3", _hoisted_5, _toDisplayString(_ctx.$t('accessToken.starshipTitle')), 1 /* TEXT */),
          _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t('accessToken.starshipDesc')), 1 /* TEXT */)
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.userTokens, (token) => {
          return (_openBlock(), _createBlock($setup["StarshipAccessTokenCard"], {
            key: token.token_name,
            tokenName: token.token_name,
            tokenValue: token.token
          }, null, 8 /* PROPS */, ["tokenName", "tokenValue"]))
        }), 128 /* KEYED_FRAGMENT */))
      ]),
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = $event => ($setup.centerDialogVisible = true)),
        class: "rounded-[4px] border bg-[#FFF] py-[5px] px-[16px] text-[#606266] text-[14px] font-[500] mt-[32px]"
      }, _toDisplayString(_ctx.$t('accessToken.starshipAddToken')), 1 /* TEXT */)
    ]),
    _createCommentVNode(" new key dialog "),
    _createVNode(_component_el_dialog, {
      modelValue: $setup.centerDialogVisible,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => (($setup.centerDialogVisible) = $event)),
      title: _ctx.$t('accessToken.starshipAddToken'),
      width: "35%",
      class: "dialogWidth",
      style: {"border-radius":"0.5rem"},
      left: ""
    }, {
      footer: _withCtx(() => [
        _createElementVNode("span", _hoisted_7, [
          _createVNode(_component_el_button, {
            onClick: _cache[2] || (_cache[2] = $event => ($setup.centerDialogVisible = false))
          }, {
            default: _withCtx(() => [
              _createTextVNode("Cancel")
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_el_button, {
            type: "primary",
            onClick: $setup.submitAccessToken
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('all.add')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          })
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_el_form, {
          ref: "accessTokenFormRef",
          model: $setup.formData,
          rules: $setup.formRules,
          "label-position": "top"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, {
              label: _ctx.$t('accessToken.starshipAccessTokenName'),
              prop: "accessTokenName"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: $setup.formData.accessTokenName,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.formData.accessTokenName) = $event)),
                  maxlength: "20"
                }, null, 8 /* PROPS */, ["modelValue"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["label"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["model"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue", "title"])
  ]))
}