<template>
  <div>
    <el-button style="width: 100%" @click="loadRepos" class="Default">在线试用</el-button>
  </div>
  <el-dialog
      v-model="dialogVisible"
      width="75%"
      style="height: 75%"
      title="在线试用"
      class="iframeDialog dialogLoading"
      destroy-on-close
      :close-on-click-modal="false"
      >
    <div style="padding-bottom: 16px;width:100%;height: calc(100% - 16px);">
      <iframe :src="url" width="100%" height="100%" frameborder="0" :key="iframeKey"></iframe>
    </div>
  </el-dialog>
</template>
<script setup>
import {ref,inject,nextTick,onUnmounted } from 'vue'
import jwtFetch from '../../packs/jwtFetch'
import { ElLoading,ElMessage } from 'element-plus'

const props = defineProps({
  localRepoId: String,
  modelPath:String
})
const dialogVisible = ref(false)
const url = ref('')
const csghubServer = inject('csghubServer')
const timerId = ref(null)
const iframeKey = ref(0)

const loadRepos = async () => {
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      modelPath: props.modelPath
    })
  }
  url.value = ''
  dialogVisible.value = true
  let loading
  nextTick(() =>{
    loading = ElLoading.service({ target: '.dialogLoading', background: 'rgba(246, 246, 246, 0.7)'})
  })
  const responseUrl = csghubServer + '/api/v1/modelTrial'
  const res = await jwtFetch(responseUrl,options)
  if (!res.ok) {
    // ElMessage({message: t('application_spaces.new.failedFetchResources'), type: "warning"})
  }else {
    res.json().then((body) => {
      if(body.code === 200){
        getStatus(body.data,loading)
      }else {
        ElMessage({
          message: body.data,
          type: "warning",
        })
        loading.close()
      }
    })
  }
}
// 定义延时器
const getStatus = (data,loading) => {
  timerId.value = setInterval(() => {
    getStatusApi(data).then(res => {
      if(res.data.status === 1) {
        loading.close()
        url.value = data.url
        stopTimer()
      }
    })
  },10000)
}

// 关闭延时器
const stopTimer = () => {
  if(timerId.value) {
    clearInterval(timerId.value);
    timerId.value = null;
  }
}

const getStatusApi = async (data) => {
  return new Promise(async (resolve,reject) => {
    const options = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    }
    const responseUrl = csghubServer + '/api/v1/modelTrial/getStatus?id=' + data.id
    const res = await jwtFetch(responseUrl,options)
    if (!res.ok) {
      reject(res)
      // ElMessage({message: t('application_spaces.new.failedFetchResources'), type: "warning"})
    }else {
      res.json().then((body) => {
        resolve(body)
      })
    }
  })
}
onUnmounted(()=>{
  stopTimer()
})
</script>
<style>
.iframeDialog .el-dialog__body{
  width: 100%;
  height: 100%;
  padding: 0px !important;
}
</style>