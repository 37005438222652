<template>
  <a :href="detailLink" :class="`${repoType}-card  hover:active-${repoType}-card `"
    class="p-4 repoCardClass setWidth">
    <div class="newBox" v-if="repo.new">
      NEW
    </div>
    <div class="w-[100%]">
      <div class="repoCardTitle" ref="ComputedRef">
        <el-tooltip
        class="box-item"
        effect="dark"
        :content="getComputed.path.split('/')[1]"
        placement="top-end"
        :disabled="!isOverflowComputed"
      >
        {{ getComputed.path.split('/')[1] }}
        </el-tooltip>
      </div>

      <div class="repoCardPath" ref="repoCardPath">
        <el-tooltip
            class="box-item"
            effect="dark"
            :content="getComputed.englishPath"
            placement="top-end"
            :disabled="!englishPathComputed"
        >
        {{getComputed.englishPath}}
        </el-tooltip>
      </div>

      <div class="flex gap-1">
        <el-tooltip effect="light" :content="$t('repo.source.needSync')" placement="top">
          <SvgIcon v-if="!!needSyncIcon" :name="needSyncIcon" />
        </el-tooltip>
        <el-tooltip effect="light" :content="syncTooltip" placement="top">
          <SvgIcon v-if="!!sourceIcon && !needSyncIcon" :name="sourceIcon" />
        </el-tooltip>
      </div>
    </div>

    <div class="formatTaskTags" ref="TagItemBox" v-show="repoType === 'model' || repoType === 'dataset'">
        <TagItem v-for="(item,index) in VisibleTag" :tag="{ name: item.name, zh_name: item.show_name, label: null }"
          :color="{ backgroundColor: item.color?.split(',')[0], color: item.color?.split(',')[1] }" />
        <template v-if="hasMore">
          <el-popover placement="bottom" trigger="hover" width="auto" popper-class="popperClass">
            <template #reference>
              <span
                class="text-[14px] bg-[#E8F1FF] text-[#344054] p-[8px] rounded-[4px] cursor-pointer flex items-center gap-[10px]">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_13936_3164)">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M5 8C5 8.55228 4.55228 9 4 9C3.44772 9 3 8.55228 3 8C3 7.44772 3.44772 7 4 7C4.55228 7 5 7.44772 5 8ZM9 8C9 8.55228 8.55228 9 8 9C7.44772 9 7 8.55228 7 8C7 7.44772 7.44772 7 8 7C8.55228 7 9 7.44772 9 8ZM12 9C12.5523 9 13 8.55228 13 8C13 7.44772 12.5523 7 12 7C11.4477 7 11 7.44772 11 8C11 8.55228 11.4477 9 12 9Z"
                      fill="#2047D3" />
                  </g>
                  <defs>
                    <clipPath id="clip0_13936_3164">
                      <rect width="16" height="16" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </span>
              <!--              数据中有一条脏数据，多减一-->
            </template>
            <div class="popoverCard">
              <TagItem v-for="item in hideTage" :tag="{ name: item.name, zh_name: item.show_name, label: null }"
                :color="{ backgroundColor: item.color?.split(',')[0], color: item.color?.split(',')[1] }" />
            </div>
          </el-popover>
      </template>
    </div>
    <div v-show="repoType === 'code'" class="formatTaskTags">
      <div class="licenseTag">
        {{ repo.license }}
      </div>
    </div>

    <div class="repoCardDescription" ref="descriptionRef">
      <el-tooltip
          class="box-item"
          effect="dark"
          :content="repo.description"
          placement="top-end"
          :disabled="!isOverflowDescription"
      >
        {{ repo.description }}
      </el-tooltip>
    </div>

<!--    <div class="time">-->
<!--      {{ parseTime(repo.updated_at) }}-->
<!--    </div>-->
    <div class="tagBottm">
      <!-- <div class="tagLogo">
        <img src="/images/tagLogo.png">
      </div>
      <div class="spacing"/> -->
      <div class="time">
        {{ parseTime(repo.updated_at,'{y}-{m}-{d}') }} 更新
      </div>
      <div class="spacing"/>
      <div class="download">
        <div class="icon">
          <img src="/images/download.png">
        </div>
         {{repo.downloads}}
      </div>
      <div class="spacing"/>
      <div class="Collection">
        <div class="icon">
          <img src="/images/Collection.png">
        </div>
        {{repo.likes}}
      </div>
    </div>
    <!--    <div class="flex flex-nowrap overflow-hidden text-ellipsis items-center gap-[8px] text-xs text-[#909399]">-->
    <!--      <span v-if="getComputed.taskTag"-->
    <!--            class="max-w-[80px] xl:max-w-full overflow-hidden text-ellipsis whitespace-nowrap"-->
    <!--      >-->
    <!--            {{ getComputed.taskTag }}-->
    <!--      </span>-->

    <!--      <span v-if="getComputed.taskTag">-->
    <!--        <SvgIcon name="vertical_divider" />-->
    <!--      </span>-->

    <!--      <div class="overflow-hidden text-ellipsis whitespace-nowrap">-->
    <!--        {{$t('all.lastTime')}}：{{ repo.updated_at.substring(0, 10) }}-->
    <!--      </div>-->

    <!--      <span> <SvgIcon name="vertical_divider" /> </span>-->

    <!--      <span class="whitespace-nowrap">{{ getComputed.visibility }}</span>-->

    <!--      <span> <SvgIcon name="vertical_divider" /> </span>-->

    <!--      <span class="whitespace-nowrap">{{$t('all.downloadCount')}}：{{ repo.downloads }}</span>-->
    <!--    </div>-->
  </a>
</template>

<script setup>
import { computed, onMounted,ref,nextTick } from 'vue'
import { useI18n } from 'vue-i18n'
import TagItem from '../tags/TagItem.vue'
const props = defineProps({
  repo: Object,
  repoType: String,
  cardType: {
    type: String,
    default: 'index'
  }
})
const tags =  computed(() => {
  if(!props.repo.tags) return []
  return props.repo.tags.filter( x => x.show_name !== '')
})
const TagItemBox = ref(null)
const visibleTag = ref([])
const { t, locale } = useI18n()
const hasMore = ref(false)// 是否有更多盒子需要显示
const VisibleTag = ref([])  // 当前显示的盒子
const hideTage = ref([])// 当前隐藏的盒子
const isOverflowDescription = ref(false)
const descriptionRef = ref(null)
const isOverflowComputed = ref(false)
const ComputedRef = ref(null)
const englishPathComputed = ref(null)
const repoCardPath = ref(null)

// 获取文字宽度  + 14 为左右边距
const getTextWidth = (text, font = '14px PingFang SC') => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  context.font = font;
  const metrics = context.measureText(text);
  return metrics.width + 14;
}

const getVisibleTagBox = () => {
  let totalWidth = 0;
  const maxWidth = TagItemBox.value.offsetWidth - 40; // 获取容器宽度
  VisibleTag.value = tags.value.filter((box => {
    if(box.show_name){
      // 每个盒子有8的边距
      const boxWidth = getTextWidth(box.show_name) + 8
      const willFit = totalWidth + boxWidth <= maxWidth;
      if (willFit) {
        totalWidth +=boxWidth;
      }
      return willFit;
    }
  }))
  hasMore.value = tags.value.length > VisibleTag.value.length;
  if(hasMore.value) {
    // 隐藏的盒子取显示盒子后面的数据
    const uniqueData = tags.value.filter((item) => {
      const found = VisibleTag.value.find((i) => i.name === item.name);
      return !found;
    });
    hideTage.value = uniqueData
  }
  // console.log(hasMore.value)
}

const isTextClamped = (element) => {
  const lineHeight = parseFloat(window.getComputedStyle(element).lineHeight);
  const maxHeight = lineHeight * parseInt(window.getComputedStyle(element)['-webkit-line-clamp'], 10);

  return element.scrollHeight > maxHeight;
}

// 判断是否超出，超出显示el-tooltip
const isOverflow = () => {
  if(descriptionRef.value){
    isOverflowDescription.value = isTextClamped(descriptionRef.value)
  }
  if(ComputedRef.value){
    isOverflowComputed.value =  ComputedRef.value.offsetWidth < ComputedRef.value.scrollWidth
  }
  if(repoCardPath.value){
    englishPathComputed.value =  repoCardPath.value.offsetWidth < repoCardPath.value.scrollWidth
  }
}

onMounted(()=>{
  nextTick(()=>{
    getVisibleTagBox()
    isOverflow()
  })
})

const detailLink = computed(() => {
  switch (props.repoType) {
    case 'model':
      return `/models/${props.repo.path}`
    case 'dataset':
      return `/datasets/${props.repo.path}`
    case 'space':
      return `/spaces/${props.repo.path}`
    case 'code':
      return `/codes/${props.repo.path}`
    default:
      return ''
  }
})

const sourceIcon = computed(() => {
  if (props.repo.source !== 'opencsg') return ''

  return props.repo.sync_status === 'completed'
    ? 'repo_opencsg_completed'
    : 'repo_opencsg_sync'
})

const needSyncIcon = computed(() => {
  if (props.repo.source !== 'opencsg') return ''

  return props.repo.sync_status !== 'completed'
    && !!props.repo.repository.http_clone_url
    ? 'repo_opencsg_need_sync'
    : ''
})

const syncTooltip = computed(() => {
  if (props.repo.source !== 'opencsg') return ''

  return props.repo.sync_status === 'completed'
    ? t('repo.source.syncCompleted')
    : t('repo.source.remoteResource')
})

const parseTime = (time, pattern) => {
  if (!time) {
    return null
  }
  const format = pattern || '{y}-{m}-{d} {h}:{i}:{s}'
  let date = new Date(time)
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
  return time_str
}

const getComputed = computed(() => {
  const displayName = props.repo.nickname !== undefined && props.repo.nickname.trim().length > 0 ? props.repo.nickname : props.repo.name
  const path = props.repo.path.split('/')[0] + '/' + displayName

  const visibility = props.repo.private ? t('all.private') : t('all.public')
  const showDescription = props.cardType === 'index' || !!props.repo.description?.trim()

  let taskTag = (props.repo.tags || []).find(tag => tag.category === "task")
  if (locale.value === 'en') {
    taskTag = taskTag ? taskTag["name"].replace(/-/g, ' ') : null
  }
  else {
    taskTag = taskTag ? taskTag["show_name"] : null
  }
  const englishPath = props.repo.path
  return { path, visibility, taskTag, showDescription,englishPath }
})
</script>

<style scoped>
.dataset-card:hover .dataset-path {
  color: var(--blue-blue-5001-f-75-cb, #1F75CB);
}

.model-card:hover .model-path {
  color: var(--theme-dark-red-t-red-500-ad-4-a-3-b, #AD4A3B);
}

.repoCardClass {
  height: 246px;
  border-radius: 2px;
  display: inline-block;
  box-shadow: 0px 2px 4px 0px #001C4D08;
  border: 1px solid #F2F3F5;
  background: #FFFFFF;
  position: relative;
}
.repoCardClass:hover {
  border-bottom: 4px solid #2047D3;
}
.repoCardClass:focus {
  border-bottom: 4px solid #2047D3;
}

.repoCardTitle {
  font-family: PingFang SC;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
  color: #1D2129;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.repoCardPath{
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #6B7785;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.repoCardDescription {
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #6B7785;
  height: 44px;
  margin-bottom: 20px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}

.formatTaskTags {
  width: 100%;
  height: 24px;
  margin-top: 20px;
  margin-bottom: 8px;
  display: flex;
  gap: 8px;
}

.time {
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 400;
  //line-height: 32px;
  text-align: left;
  color: #A9AEB8;
  //padding: 0px 16px;
}

.popoverCard {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
.popperClass{
  border-radius: 4px;
  border-top: none;
}
.tagBottm{
  width:100%;
  height: 32px;
  display: flex;
  align-items: center;
}
.tagLogo{
  height: 100%;
  width: 73px;
}
.tagLogo img{
  width: 100%;
  height: 100%;
}
.spacing{
  height: 14px;
  width:1px;
  background-color: #E5E6EB;
  margin: 0 16px;
}
.download,.Collection{
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 400;
  color: #A9AEB8;
  display: flex;
  align-items: center;
}
.icon{
  margin-right: 2px;
}
.licenseTag{
  font-family: PingFang SC;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  padding: 2px 8px;
  background-color: rgb(232, 241, 255);
  color: rgb(32, 71, 211);
  border-radius: 2px;
}
</style>
