import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-[32px] p-[16px] pt-[24px]" }
const _hoisted_2 = { class: "text-[20px] text-[#344054] font-[500] leading-[30px]" }
const _hoisted_3 = { class: "py-[16px]" }
const _hoisted_4 = { class: "flex flex-col items-start rounded-[12px] border border-[1px] border-[#EAECF0] shadow-sm" }
const _hoisted_5 = { class: "px-[24px] py-[20px] w-full flex justify-between border-b border-[#EAECF0]" }
const _hoisted_6 = { class: "flex flex-col gap-[4px]" }
const _hoisted_7 = { class: "text-[18px] font-[500] leading-[28px] text-[#101828]" }
const _hoisted_8 = { class: "text-[14px] font-[300] leading-[20px] text-[#475467]" }
const _hoisted_9 = { class: "flex gap-[16px]" }
const _hoisted_10 = { class: "w-full z-0" }
const _hoisted_11 = { class: "flex gap-[12px] items-center pl-3" }
const _hoisted_12 = { class: "flex flex-col" }
const _hoisted_13 = {
  key: 0,
  class: "text-[14px] font-[400] leading-[20px] text-[#101828]"
}
const _hoisted_14 = {
  key: 1,
  class: "text-[14px] font-[300] leading-[20px] text-[#475467]"
}
const _hoisted_15 = { class: "text-[14px] font-[400] leading-[20px] text-[#101828]" }
const _hoisted_16 = { class: "text-[14px] font-[400] leading-[20px] text-[#101828]" }
const _hoisted_17 = { class: "flex gap-4 justify-end pr-4" }
const _hoisted_18 = { class: "mt-[12px] mb-[16px] flex justify-center" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_InviteMember = _resolveComponent("InviteMember")
  const _component_el_avatar = _resolveComponent("el-avatar")
  const _component_el_table_column = _resolveComponent("el-table-column")
  const _component_Edit = _resolveComponent("Edit")
  const _component_el_icon = _resolveComponent("el-icon")
  const _component_Delete = _resolveComponent("Delete")
  const _component_el_popconfirm = _resolveComponent("el-popconfirm")
  const _component_el_table = _resolveComponent("el-table")
  const _component_el_pagination = _resolveComponent("el-pagination")
  const _component_OrgMemberRoleEditDialog = _resolveComponent("OrgMemberRoleEditDialog")
  const _directive_loading = _resolveDirective("loading")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('organization.orgMembers')), 1 /* TEXT */),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t('organization.members.title')), 1 /* TEXT */),
            _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t('organization.members.description')), 1 /* TEXT */)
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_InviteMember, {
              "org-name": $props.organization.name,
              onResetMemberList: $options.fetchMembers,
              admin: $props.admin
            }, null, 8 /* PROPS */, ["org-name", "onResetMemberList", "admin"])
          ])
        ]),
        _createElementVNode("div", _hoisted_10, [
          _withDirectives((_openBlock(), _createBlock(_component_el_table, {
            data: $data.members,
            stripe: "",
            style: {"width":"100%"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_table_column, {
                label: _ctx.$t('organization.members.username'),
                "label-class-name": "indent-3 text-[12px] font-[400] leading-[18px] text-[#475467]"
              }, {
                default: _withCtx((scope) => [
                  _createElementVNode("div", _hoisted_11, [
                    _createVNode(_component_el_avatar, {
                      size: 40,
                      src: scope.row.avatar
                    }, null, 8 /* PROPS */, ["src"]),
                    _createElementVNode("div", _hoisted_12, [
                      (!!scope.row.nickname)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_13, _toDisplayString(scope.row.nickname), 1 /* TEXT */))
                        : _createCommentVNode("v-if", true),
                      (!!scope.row.name)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_14, " @" + _toDisplayString(scope.row.name), 1 /* TEXT */))
                        : _createCommentVNode("v-if", true)
                    ])
                  ])
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["label"]),
              _createVNode(_component_el_table_column, {
                label: _ctx.$t('organization.members.role'),
                "label-class-name": "text-[12px] font-[400] leading-[18px] text-[#475467]"
              }, {
                default: _withCtx((scope) => [
                  _createElementVNode("div", _hoisted_15, _toDisplayString(scope.row.role), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["label"]),
              _createCommentVNode(" 上次登录时间 "),
              _createVNode(_component_el_table_column, {
                label: _ctx.$t('organization.members.last_login_at'),
                "label-class-name": "text-[12px] font-[400] leading-[18px] text-[#475467]"
              }, {
                default: _withCtx((scope) => [
                  _createElementVNode("div", _hoisted_16, _toDisplayString($options.formatDate(scope.row.last_login_at)), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["label"]),
              ($props.admin)
                ? (_openBlock(), _createBlock(_component_el_table_column, {
                    key: 0,
                    width: "100",
                    align: "center",
                    fixed: "right"
                  }, {
                    default: _withCtx((scope) => [
                      _createElementVNode("div", _hoisted_17, [
                        _createVNode(_component_el_icon, {
                          size: 16,
                          class: "cursor-pointer",
                          onClick: $event => ($options.handleToRoleEdit(scope.row))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_Edit)
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"]),
                        _createVNode(_component_el_popconfirm, {
                          title: _ctx.$t('organization.members.deleteConfirmTitle', {username: scope.row.nickname || scope.row.name, orgName: $props.organization.name}),
                          "confirm-button-text": _ctx.$t('organization.members.confirm'),
                          "cancel-button-text": _ctx.$t('organization.members.cancel'),
                          "confirm-button-type": "danger",
                          icon: "el-icon-info",
                          width: 180,
                          onConfirm: $event => ($options.handleDelete(scope.row))
                        }, {
                          reference: _withCtx(() => [
                            _createVNode(_component_el_icon, {
                              size: 16,
                              class: "cursor-pointer"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_Delete)
                              ]),
                              _: 1 /* STABLE */
                            })
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["title", "confirm-button-text", "cancel-button-text", "onConfirm"])
                      ])
                    ]),
                    _: 1 /* STABLE */
                  }))
                : _createCommentVNode("v-if", true)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["data"])), [
            [_directive_loading, $data.loading]
          ]),
          _createElementVNode("div", _hoisted_18, [
            _createVNode(_component_el_pagination, {
              background: "",
              "hide-on-single-page": "",
              "current-page": $data.searchForm.page,
              "onUpdate:currentPage": [
                _cache[0] || (_cache[0] = $event => (($data.searchForm.page) = $event)),
                $options.fetchMembers
              ],
              "default-current-page": 1,
              "page-size": $data.searchForm.per,
              layout: "prev, pager, next",
              total: $data.totalCount,
              onCurrentPage: $options.fetchMembers
            }, null, 8 /* PROPS */, ["current-page", "page-size", "total", "onCurrentPage", "onUpdate:currentPage"])
          ]),
          _createVNode(_component_OrgMemberRoleEditDialog, {
            visible: $data.roleEditDialog,
            formDataRaw: $data.editRow,
            organization: $props.organization,
            onClose: _cache[1] || (_cache[1] = $event => ($data.roleEditDialog = false)),
            onSubmit: $options.fetchMembers
          }, null, 8 /* PROPS */, ["visible", "formDataRaw", "organization", "onSubmit"])
        ])
      ])
    ])
  ]))
}