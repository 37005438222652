<template>
  <div class="specialShowHead">
    <div class="specialShowHeadBack" />
    <div class="textHead">
      <div class="table">{{ showName }}</div>
      <div class="data">
        <el-tooltip
          class="box-item"
          effect="dark"
          :content="showIntroduce"
          placement="top-end"
      >
        {{ showIntroduce }}
        </el-tooltip>
      </div>
    </div>
  </div>
  <div class="specialShowMain h-full">
      <repo-item v-for="model in showModels" :repo="model" repo-type="model"></repo-item>
  </div>
</template>
<script setup>
import {inject, onMounted,ref} from 'vue'
import { ElMessage } from "element-plus";
import RepoItem from "../shared/RepoItem.vue"

const csghubServer = inject('csghubServer')
const showName = ref('')
const showIntroduce = ref('')
const showModels = ref([])

const getUrlVal = () => {
  const url = window.location.search
  const reg = new RegExp("(^|&)id=([^&]*)(&|$)");
  const result =url.substr(1).match(reg);
  return result ? result[2] : ''
}

// 该页面和其他页面布局不一致进行适配性修改
const setFather = () => {
  const box = document.querySelector('.mainNavber')
  const nav = document.querySelector('.navbarBack')
  box.style.width = '100%'
  box.style.margin = 0

  nav.style.backgroundColor = '#FFFFFF'
  nav.style.opacity = 1
}

const getSpecial = async () => {
  const response = await fetch(
      `${csghubServer}/api/v1/topics/queryModel?id=${getUrlVal()}`
  )
  if (response.ok) {
    response.json().then((body) => {
      showName.value = body.data.name
      showIntroduce.value = body.data.introduce
      showModels.value = body.data.models
    })
  } else {
    ElMessage.error('数据繁忙请稍后在试！')
  }
}

onMounted(()=>{
  getSpecial()
  setFather()
})
</script>

<style scoped>
.textHead{
  width: 1080px;
  margin: 0 auto;
  position: relative;
  z-index: 10;
}
.table{
  width: 100%;
  font-family: PingFang SC;
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  text-align: center;
  color: #FFFFFF;
}
.data{
  width: 100%;
  font-family: PingFang SC;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #FFFFFF;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}
.specialShowMain{
  position: relative;
  background: #FFFFFFB2;
  border: 1px solid #FFFFFF;
  padding: 16px;
  border-radius: 2px;
  margin-top: -104px;
  z-index: 10;
  backdrop-filter: blur(20px);
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  gap: 16px;
  min-height: calc(100vh - 352px);
}
.specialShowList{
  //position: absolute;
  //top: -104px;


}
</style>