import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f170c9b4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "w-[640px] m-auto flex flex-col items-center md:w-full md:p-5" }
const _hoisted_2 = { class: "text-[#303133] text-xl font-semibold mt-6 mb-3" }
const _hoisted_3 = { class: "text-[#606266] text-base font-medium md:text-center" }
const _hoisted_4 = { class: "mt-9" }
const _hoisted_5 = { class: "w-full flex sm:flex-col gap-2 mb-9 md:gap-9" }
const _hoisted_6 = { class: "flex-1" }
const _hoisted_7 = { class: "text-[#303133] text-sm mb-2" }
const _hoisted_8 = { class: "flex-1" }
const _hoisted_9 = { class: "text-[#303133] text-sm mb-2" }
const _hoisted_10 = { class: "mt-9" }
const _hoisted_11 = { class: "w-full flex sm:flex-col gap-2 mb-9 md:gap-9" }
const _hoisted_12 = { class: "flex-1" }
const _hoisted_13 = { class: "mb-2 text-[#303133]" }
const _hoisted_14 = { class: "flex-1" }
const _hoisted_15 = { class: "mb-2 text-[#303133]" }
const _hoisted_16 = { class: "mb-9 text-sm w-full" }
const _hoisted_17 = { class: "mb-2 text-[#303133]" }
const _hoisted_18 = { class: "mb-9 text-sm w-full" }
const _hoisted_19 = { class: "mb-2 text-[#303133]" }
const _hoisted_20 = { class: "text-[#475467] mt-2 font-light" }
const _hoisted_21 = { class: "text-[#475467] font-light" }
const _hoisted_22 = { class: "mb-9 text-sm w-full" }
const _hoisted_23 = { class: "mb-2 text-[#303133]" }
const _hoisted_24 = { class: "mb-9" }
const _hoisted_25 = { class: "whitespace-normal text-[#475467] font-light" }
const _hoisted_26 = { class: "whitespace-normal text-[#475467] font-light" }
const _hoisted_27 = { class: "flex justify-end" }
const _hoisted_28 = ["disabled"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")
  const _component_el_autocomplete = _resolveComponent("el-autocomplete")
  const _component_el_option = _resolveComponent("el-option")
  const _component_el_select = _resolveComponent("el-select")
  const _component_el_radio = _resolveComponent("el-radio")
  const _component_el_radio_group = _resolveComponent("el-radio-group")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createVNode(_component_SvgIcon, {
        name: "endpoint",
        width: "36",
        height: "36"
      })
    ]),
    _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.$t("endpoints.new.title")), 1 /* TEXT */),
    _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t("endpoints.new.desc")), 1 /* TEXT */),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t("endpoints.new.name")), 1 /* TEXT */),
          _createVNode($setup["ElInput"], {
            modelValue: $setup.endpointName,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.endpointName) = $event)),
            placeholder: _ctx.$t('rule.nameRule'),
            "input-style": "width: 100%"
          }, null, 8 /* PROPS */, ["modelValue", "placeholder"])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.$t("endpoints.new.modelId")), 1 /* TEXT */),
          _createVNode(_component_el_autocomplete, {
            clearable: "",
            modelValue: $setup.modelId,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.modelId) = $event)),
            "fetch-suggestions": $setup.fetchModels,
            placeholder: _ctx.$t('endpoints.new.modelIdTip')
          }, null, 8 /* PROPS */, ["modelValue", "placeholder"])
        ])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("p", _hoisted_13, _toDisplayString(_ctx.$t("endpoints.new.minReplica")), 1 /* TEXT */),
            _createVNode(_component_el_select, {
              modelValue: $setup.minReplica,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($setup.minReplica) = $event)),
              placeholder: _ctx.$t('all.select'),
              size: "large",
              style: {"width":"100%"}
            }, {
              default: _withCtx(() => [
                (_openBlock(), _createElementBlock(_Fragment, null, _renderList($setup.replicaRanges, (item) => {
                  return _createVNode(_component_el_option, {
                    key: item,
                    label: item,
                    value: item
                  }, null, 8 /* PROPS */, ["label", "value"])
                }), 64 /* STABLE_FRAGMENT */))
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["modelValue", "placeholder"])
          ]),
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("p", _hoisted_15, _toDisplayString(_ctx.$t("endpoints.new.maxReplica")), 1 /* TEXT */),
            _createVNode(_component_el_select, {
              modelValue: $setup.maxReplica,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => (($setup.maxReplica) = $event)),
              placeholder: _ctx.$t('all.select'),
              size: "large",
              style: {"width":"100%"}
            }, {
              default: _withCtx(() => [
                (_openBlock(), _createElementBlock(_Fragment, null, _renderList($setup.replicaRanges, (item) => {
                  return _createVNode(_component_el_option, {
                    key: item,
                    label: item,
                    value: item
                  }, null, 8 /* PROPS */, ["label", "value"])
                }), 64 /* STABLE_FRAGMENT */))
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["modelValue", "placeholder"])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_16, [
        _createElementVNode("p", _hoisted_17, _toDisplayString(_ctx.$t("endpoints.new.cluster")), 1 /* TEXT */),
        _createVNode(_component_el_select, {
          modelValue: $setup.endpointCluster,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => (($setup.endpointCluster) = $event)),
          placeholder: _ctx.$t('all.select'),
          size: "large",
          style: {"width":"100%"}
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.endpointClusters, (item) => {
              return (_openBlock(), _createBlock(_component_el_option, {
                key: item.cluster_id,
                label: item.region,
                value: item.cluster_id
              }, null, 8 /* PROPS */, ["label", "value"]))
            }), 128 /* KEYED_FRAGMENT */))
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue", "placeholder"])
      ]),
      _createElementVNode("div", _hoisted_18, [
        _createElementVNode("p", _hoisted_19, _toDisplayString(_ctx.$t("endpoints.new.resource")), 1 /* TEXT */),
        _createVNode(_component_el_select, {
          modelValue: $setup.endpointResource,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => (($setup.endpointResource) = $event)),
          placeholder: _ctx.$t('all.select'),
          size: "large",
          style: {"width":"100%"}
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.endpointResources, (item) => {
              return (_openBlock(), _createBlock(_component_el_option, {
                key: item.name,
                label: item.name,
                value: item.id
              }, null, 8 /* PROPS */, ["label", "value"]))
            }), 128 /* KEYED_FRAGMENT */))
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue", "placeholder"]),
        _createElementVNode("p", _hoisted_20, _toDisplayString(_ctx.$t("endpoints.new.resourceTip1")), 1 /* TEXT */),
        _createElementVNode("p", _hoisted_21, _toDisplayString(_ctx.$t("endpoints.new.resourceTip2")), 1 /* TEXT */)
      ]),
      _createElementVNode("div", _hoisted_22, [
        _createElementVNode("p", _hoisted_23, _toDisplayString(_ctx.$t("endpoints.new.framework")), 1 /* TEXT */),
        _createVNode(_component_el_select, {
          modelValue: $setup.endpointFramework,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => (($setup.endpointFramework) = $event)),
          placeholder: _ctx.$t('all.select'),
          size: "large",
          style: {"width":"100%"}
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.endpointFrameworks, (item) => {
              return (_openBlock(), _createBlock(_component_el_option, {
                key: item.id,
                label: item.frame_name,
                value: item.id
              }, null, 8 /* PROPS */, ["label", "value"]))
            }), 128 /* KEYED_FRAGMENT */))
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue", "placeholder"])
      ]),
      _createElementVNode("div", _hoisted_24, [
        _createVNode(_component_el_radio_group, {
          modelValue: $setup.visibility,
          "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => (($setup.visibility) = $event)),
          class: "!block"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_radio, {
              class: "w-full mr-0 mb-9 !rounded-xl !h-auto !items-start !p-4",
              value: "public",
              size: "large",
              border: ""
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("endpoints.new.public")) + " ", 1 /* TEXT */),
                _createElementVNode("p", _hoisted_25, _toDisplayString(_ctx.$t("endpoints.new.publicDesc")), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_el_radio, {
              class: "w-full mr-0 !rounded-xl !h-auto !items-start !p-4",
              value: "private",
              size: "large",
              border: ""
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("endpoints.new.private")) + " ", 1 /* TEXT */),
                _createElementVNode("p", _hoisted_26, _toDisplayString(_ctx.$t("endpoints.new.privateDesc")), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_27, [
        _createElementVNode("button", {
          class: "bg-[#3250BD] w-[118px] h-9 rounded-lg text-white flex items-center justify-center border disabled:text-[#98A2B3] disabled:bg-[#F2F4F7] disabled:border-[#EAECF0]",
          disabled: !$setup.canCreateEndpoint || $setup.hasCreateEndpoint,
          onClick: $setup.createEndpoint
        }, _toDisplayString(_ctx.$t("endpoints.new.createEndpoint")), 9 /* TEXT, PROPS */, _hoisted_28)
      ])
    ])
  ]))
}