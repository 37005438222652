import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d18fc2a2"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]
const _hoisted_2 = { class: "industrData" }
const _hoisted_3 = { class: "industrNumber" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.industryTagsList, (item) => {
    return (_openBlock(), _createElementBlock("div", {
      key: item.id,
      class: _normalizeClass($props.SelectedID === item.id ? 'industrTag  activationTag' : 'industrTag'),
      onClick: $event => ($setup.industryClick(item))
    }, [
      _createElementVNode("div", {
        class: "icon",
        style: _normalizeStyle({background: item.industry_color})
      }, null, 4 /* STYLE */),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", {
          class: _normalizeClass($props.SelectedID === item.id ? 'industrLable activationLable' : 'industrLable')
        }, _toDisplayString(item.show_name), 3 /* TEXT, CLASS */),
        _createElementVNode("div", _hoisted_3, "已发布个" + _toDisplayString(item.count) + "模型", 1 /* TEXT */)
      ])
    ], 10 /* CLASS, PROPS */, _hoisted_1))
  }), 128 /* KEYED_FRAGMENT */))
}