import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a8339960"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href"]
const _hoisted_2 = { class: "searchLiTag" }
const _hoisted_3 = { class: "lable" }
const _hoisted_4 = { class: "text" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("a", { href: $setup.detailLink }, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString($props.item.nickname), 1 /* TEXT */),
      _createElementVNode("div", _hoisted_4, _toDisplayString($props.item.path), 1 /* TEXT */)
    ])
  ], 8 /* PROPS */, _hoisted_1))
}