<template>
  <el-button @click="openVisible" class="Default">引用到算法平台</el-button>
  <el-dialog v-model="modleVisible" title="引用到算法平台" class="w-[300px] dialogLoading" :before-close="beroreClose">
    <el-form ref="ruleFormRef" :model="form" :rules="rules" label-width="auto">
      <el-form-item label="名称" prop="modelName">
        <el-input v-model="form.modelName" placeholder="请输入名称" />
      </el-form-item>
      <el-form-item label="框架" prop="modelFramework" v-if="repoType === 'model'">
        <el-select v-model="form.modelFramework" placeholder="请选择框架">
          <el-option v-for="item in frameTypeList" :key="item.value" :label="item.label" :value="item.label" />
        </el-select>
      </el-form-item>
      <el-form-item label="模型格式" prop="modelFormat" v-if="repoType === 'model'">
        <el-select v-model="form.modelFormat" placeholder="请选择模型格式">
          <el-option v-for="item in modelTypeList" :key="item.value" :label="item.label" :value="item.label" />
        </el-select>
      </el-form-item>
      <el-form-item label="模型类别" prop="modelCategory" v-if="repoType === 'model'">
        <el-select v-model="form.modelCategory" placeholder="请选择模型类别">
          <el-option v-for="item in modelClassList" :key="item.value" :label="item.label" :value="item.label" />
        </el-select>
      </el-form-item>
    </el-form>
    <div class="text-right">
      <el-button type="primary" @click="submitForm">
        提交
      </el-button>
      <el-button @click="resetForm">取消</el-button>
    </div>
  </el-dialog>
</template>
<script setup>
import { reactive, ref, onMounted, inject } from 'vue'
import jwtFetch from "../../packs/jwtFetch";
import { ElMessage, ElLoading, ElMessageBox } from "element-plus";
import { getRepoType } from '../../stores/utils'

const modleVisible = ref(false)
const ruleFormRef = ref(null)
const csghubServer = inject('csghubServer')
const frameTypeList = ref([])
const modelTypeList = ref([])
const modelClassList = ref([])
const props = defineProps({
  modelName: String,
  repoType: String,
  modelPath: String,
  userName: String
})
const form = reactive({
  modelName: "",
  modelFramework: "",
  modelFormat: "",
  modelCategory: ""
})
const rules = reactive({
  modelName: [
    { required: true, message: '请输入模型名称', trigger: 'blur' },
  ],
  modelFramework: [
    { required: true, message: '请选择框架', trigger: 'blur' },
  ],
  modelFormat: [
    { required: true, message: '请选择模型格式', trigger: 'blur' },
  ],
  modelCategory: [
    { required: true, message: '请选择模型类别', trigger: 'blur' },
  ],
})



const submitForm = async () => {
  await ruleFormRef.value.validate(async (valid, fields) => {
    if (valid) {
      let loading = ElLoading.service({ target: '.dialogLoading', background: 'rgba(246, 246, 246, 0.7)' })
      if (props.repoType === 'model') modelSubmitForm(loading)
      if (props.repoType === 'dataset') datasetSubmitForm(loading)
    }
  })
}

const beroreClose = () => {
  ruleFormRef.value.resetFields()
  modleVisible.value = false
}

const modelSubmitForm = async (loading) => {
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      modelPath: props.modelPath,
      ...form
    })
  }
  const responseUrl = csghubServer + '/api/v1/modelTrial/modelSite'
  const res = await jwtFetch(responseUrl, options)
  if (!res.ok) {
    // res.json().then(err => {
    //   ElMessage({ message: err.msg, type: "warning" })
    // })
  } else {
    res.json().then((body) => {
      ElMessage({ message: body.data, type: body.code != 200 ? "warning" : "success" })
      ruleFormRef.value.resetFields()
      modleVisible.value = false
    })
  }
  loading.close()
}
const datasetSubmitForm = async (loading) => {
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      datasetPath: props.modelPath,
      datasetName: form.modelName
    })
  }
  const responseUrl = csghubServer + '/api/v1/modelTrial/dataSite'
  const res = await jwtFetch(responseUrl, options)
  if (!res.ok) {
    ElMessage({ message: res.statusText, type: "warning" })
    // ElMessage({message: t('application_spaces.new.failedFetchResources'), type: "warning"})
  } else {
    res.json().then((body) => {
      ElMessage({ message: body.data, type: body.code != 200 ? "warning" : "success" })
      ruleFormRef.value.resetFields()
      modleVisible.value = false
    })
  }
  loading.close()
}
const resetForm = () => {
  modleVisible.value = false
  ruleFormRef.value.resetFields()
}

const openVisible = () => {
  if (!props.userName) {
    return ElMessageBox.alert(`引用到算法平台前请登录`, '提示', {
      confirmButtonText: '确定'
    })
  }
  form.modelName = props.modelName
  modleVisible.value = true
}
const getDictData = async (dictName, list) => {
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  }
  const responseUrl = csghubServer + '/api/v1/modelTrial/dict?dictName=' + dictName
  const res = await jwtFetch(responseUrl, options)
  if (!res.ok) {
    ElMessage({ message: t('application_spaces.new.failedFetchResources'), type: "warning" })
  } else {
    res.json().then((body) => {
      list.value = body.data.dictDetails
    })
  }
}
onMounted(() => {
  if (props.repoType === 'model') {
    getDictData('frame_type', frameTypeList)
    getDictData('model_type', modelTypeList)
    getDictData('model_class', modelClassList)
  }
})
</script>
<style></style>