<template>
  <backPublic label="注册">
    <div>
      <el-form ref="ruleFormRef" :model="form" :rules="rules" label-width="auto">
        <el-form-item prop="name">
          <el-input v-model="form.name" placeholder="请输入用户名(必填)" class="publicFromInput" />
        </el-form-item>
        <el-form-item prop="password">
          <el-input v-model="form.password" type="password" placeholder="请输入密码(必填)" class="publicFromInput" />
        </el-form-item>
        <el-form-item prop="email">
          <el-input v-model="form.email" placeholder="请输入邮箱(必填)" class="publicFromInput" />
        </el-form-item>
        <el-form-item prop="phone">
          <el-input v-model="form.phone" placeholder="请输入电话(必填)" class="publicFromInput" />
        </el-form-item>
      </el-form>
      <el-button type="primary" class="publicButton" @click="signup">注册</el-button>
      <div class="bottonText">
        <a class="bottomButtom" href="/login">去登录</a>
      </div>
    </div>
  </backPublic>
</template>
<script setup>
import { reactive, ref } from 'vue'
import backPublic from './public.vue'
import csrfFetch from "../../packs/csrfFetch"
import { ElMessage } from "element-plus"

const ruleFormRef = ref(null)
const form = reactive({
  name: '',
  password: '',
  email:'',
  phone:''
})
const validatePhone = (rule, value, callback) => {
  const phoneRegex = /^(13[0-9]|14[5|7|9]|15[0-3|5-9]|16[2|5|6|7]|17[0-8]|18[0-9]|19[8|9])\d{8}$/
  if (!value) {
    return callback(new Error('电话不能为空'))
  } else if (!phoneRegex.test(value)) {
    return callback(new Error('电话格式不正确'))
  } else {
    return callback()
  }
}
const validateEmail = (rule, value, callback) => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
  if (!value) {
    return callback(new Error('邮箱不能为空'))
  } else if (!emailRegex.test(value)) {
    return callback(new Error('邮箱格式不正确'))
  } else {
    return callback()
  }
}

const validateName = (rule, value, callback) =>{
  if(!(value.length >= 2 && value.length <= 64)){
    callback(new Error('用户名长度必须在 2 到 64 个字符之间'))
  }else if(!/^[a-zA-Z]/.test(value)){
    callback(new Error('用户名必须以字母开头'))
  }else if(/[-_.]{2}/.test(value)){
    callback(new Error('用户名不能有连续的特殊字符 - _ .'))
  }else if(!/^[a-zA-Z0-9_.-]+$/.test(value)){
    callback(new Error('用户名只能包含字母、数字、-、_、.'))
  }else if(!/[a-zA-Z0-9]$/.test(value)){
    callback(new Error('用户名必须以字母或数字结尾'))
  }else {
    callback()
  }
}
const rules = reactive({
  name: [
    {
      required: true,
      message: '用户名不能为空',
      trigger: 'blur',
    },
    { validator: validateName, trigger: 'blur' }
  ],
  password: [
    {
      required: true,
      message: '密码不能为空',
      trigger: 'blur',
    },
  ],
  email: [
    { validator: validateEmail, trigger: 'blur' }
  ],
  phone: [
    { validator: validatePhone, trigger: 'blur' }
  ],
})

const signup = async () => {
  if (!ruleFormRef.value) return
  await ruleFormRef.value.validate(async (valid, fields) => {
    if (valid) {
      const formData = new FormData();
      formData.append("name", form.name);
      formData.append("password", form.password);
      formData.append("email", form.email);
      formData.append("phone", form.phone);
      const options = {
        method: "POST",
        body: formData
      }
      const response = await csrfFetch('/signup', options);
      if (!response.ok) {
        response.json().then((val) => {
          ElMessage({
            message: val.message,
            type: "warning",
          })
        })
      } else {
        response.json().then((val) => {
          ElMessage({
            message: '注册成功',
            type: "success",
          })
          window.location.href = '/models';
        })
      }
    }
  })
}

</script>
<style scoped></style>