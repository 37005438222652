<template>
  <el-button class="Default" @click="clickLike">
    <img :src="!userLiked ? '/images/Collection.png' : '/images/Collection-like.png'" class="img">
    收藏
  </el-button>
</template>
<script setup>
import { ref,inject } from 'vue'
import jwtFetch from '../../packs/jwtFetch'
import { ElMessage } from 'element-plus'

const props = defineProps({
  hasLike: Boolean,
  name: String,
  repoId: Number,
})
const userLiked = ref(props.hasLike)
const csghubServer = inject('csghubServer')

const clickLike = () => {
  userLiked.value === true ? removeLike() : addLike()
}

const removeLike = async () => {
  const options = { method: 'DELETE' }
  const response = await jwtFetch(
      `${csghubServer}/api/v1/user/${props.name}/likes/${props.repoId}`,
      options
  )
  if (!response.ok) {
    response.json().then((data) => {
      ElMessage({
        type: 'warning',
        message: data
      })
    })
  } else {
    userLiked.value = false
  }
}

const addLike = async () => {
  const options = { method: 'PUT' }
  const response = await jwtFetch(
      `${csghubServer}/api/v1/user/${props.name}/likes/${props.repoId}`,
      options,
      true
  )
  if (!response.ok) {
    response.json().then((data) => {
      ElMessage({
        type: 'warning',
        message: data
      })
    })
  } else {
    userLiked.value = true
  }
}
</script>
<style scoped>
.img{
  margin-right: 3px;
}
</style>