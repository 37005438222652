import { createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-38c8b6f3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-[20px] text-[#303133] flex items-center gap-[8px]" }
const _hoisted_2 = {
  key: 0,
  class: "w-[100%] gap-[16px] mt-[16px] main-box"
}
const _hoisted_3 = {
  key: 1,
  class: "flex flex-wrap gap-4 mb-4 mt-[16px]"
}
const _hoisted_4 = { class: "mt-[32px]" }
const _hoisted_5 = { class: "text-[20px] text-[#303133] flex items-center gap-[8px]" }
const _hoisted_6 = {
  key: 0,
  class: "w-[100%] gap-[16px] mt-[16px] main-box"
}
const _hoisted_7 = {
  key: 1,
  class: "flex flex-wrap gap-4 mb-4 mt-[16px]"
}
const _hoisted_8 = { class: "mt-[32px]" }
const _hoisted_9 = { class: "text-[20px] text-[#303133] flex items-center gap-[8px]" }
const _hoisted_10 = {
  key: 0,
  class: "w-[100%] gap-[16px] mt-[16px] main-box"
}
const _hoisted_11 = {
  key: 1,
  class: "flex flex-wrap gap-4 mb-4 mt-[16px]"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_skeleton = _resolveComponent("el-skeleton")

  return (_openBlock(), _createElementBlock("div", {
    class: "w-[100%] roll",
    style: _normalizeStyle({minHeight: $setup.innerHeight + 'px'})
  }, [
    _createCommentVNode(" models "),
    _createElementVNode("div", null, [
      _createElementVNode("h3", _hoisted_1, [
        _createCommentVNode(" <SvgIcon name=\"models\" width=\"18\" height=\"18\" /> "),
        _createElementVNode("span", null, _toDisplayString(_ctx.$t("organization.model")), 1 /* TEXT */)
      ]),
      ($setup.hasModels)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.models.data, (model) => {
              return (_openBlock(), _createBlock($setup["RepoItem"], {
                repo: model,
                "repo-type": "model"
              }, null, 8 /* PROPS */, ["repo"]))
            }), 256 /* UNKEYED_FRAGMENT */))
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.$t("all.noData")), 1 /* TEXT */)),
      ($setup.models.more)
        ? (_openBlock(), _createBlock($setup["ViewMore"], {
            key: 2,
            target: "models",
            onViewMoreTargets: $setup.viewMoreTargets
          }))
        : _createCommentVNode("v-if", true),
      ($setup.modelsLoading)
        ? (_openBlock(), _createBlock(_component_el_skeleton, {
            key: 3,
            class: "pr-6",
            rows: 2,
            animated: ""
          }))
        : _createCommentVNode("v-if", true)
    ]),
    _createCommentVNode(" datasets "),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("h3", _hoisted_5, [
        _createCommentVNode(" <SvgIcon name=\"datasets\" width=\"18\" height=\"18\" /> "),
        _createElementVNode("span", null, _toDisplayString(_ctx.$t("organization.dataset")), 1 /* TEXT */)
      ]),
      ($setup.hasDatasets)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.datasets.data, (dataset) => {
              return (_openBlock(), _createBlock($setup["RepoItem"], {
                repo: dataset,
                "repo-type": "dataset"
              }, null, 8 /* PROPS */, ["repo"]))
            }), 256 /* UNKEYED_FRAGMENT */))
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.$t("all.noData")), 1 /* TEXT */)),
      ($setup.datasets.more)
        ? (_openBlock(), _createBlock($setup["ViewMore"], {
            key: 2,
            target: "datasets",
            onViewMoreTargets: $setup.viewMoreTargets
          }))
        : _createCommentVNode("v-if", true),
      ($setup.datasetsLoading)
        ? (_openBlock(), _createBlock(_component_el_skeleton, {
            key: 3,
            class: "pr-6",
            rows: 2,
            animated: ""
          }))
        : _createCommentVNode("v-if", true)
    ]),
    _createCommentVNode(" code repo "),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("h3", _hoisted_9, [
        _createCommentVNode(" <SvgIcon name=\"codes\" width=\"18\" height=\"18\" /> "),
        _createElementVNode("span", null, _toDisplayString(_ctx.$t("organization.code")), 1 /* TEXT */)
      ]),
      ($setup.hasCodes)
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.codes.data, (code) => {
              return (_openBlock(), _createBlock($setup["RepoItem"], {
                repo: code,
                "repo-type": "code"
              }, null, 8 /* PROPS */, ["repo"]))
            }), 256 /* UNKEYED_FRAGMENT */))
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(_ctx.$t("all.noData")), 1 /* TEXT */)),
      ($setup.codes.more)
        ? (_openBlock(), _createBlock($setup["ViewMore"], {
            key: 2,
            target: "codes",
            onViewMoreTargets: $setup.viewMoreTargets
          }))
        : _createCommentVNode("v-if", true),
      ($setup.codeLoading)
        ? (_openBlock(), _createBlock(_component_el_skeleton, {
            key: 3,
            class: "pr-6",
            rows: 2,
            animated: ""
          }))
        : _createCommentVNode("v-if", true)
    ]),
    _createCommentVNode(" spaces "),
    _createCommentVNode("    <div class=\"mt-[32px]\">"),
    _createCommentVNode("      <h3 class=\"text-[20px] text-[#303133] flex items-center gap-[8px]\">"),
    _createCommentVNode("        &lt;!&ndash; <SvgIcon name=\"spaces\" width=\"18\" height=\"18\" /> &ndash;&gt;"),
    _createCommentVNode("        <span>{{ $t(\"organization.space\") }}</span>"),
    _createCommentVNode("      </h3>"),
    _createCommentVNode("      <div v-if=\"hasSpaces\" class=\"grid grid-cols-2 xl:grid-cols-1 gap-4 mb-4 mt-[16px]\">"),
    _createCommentVNode("        <application-space-item v-for=\"repo in spaces.data\" :repo=\"repo\" repo-type=\"space\" />"),
    _createCommentVNode("      </div>"),
    _createCommentVNode("      <div v-else class=\"flex flex-wrap gap-4 mb-4 mt-[16px]\">"),
    _createCommentVNode("        {{ $t(\"all.noData\") }}"),
    _createCommentVNode("      </div>"),
    _createCommentVNode("      <view-more v-if=\"spaces.more\" target=\"spaces\" @view-more-targets=\"viewMoreTargets\"></view-more>"),
    _createCommentVNode("      <el-skeleton class=\"pr-6\" v-if=\"spacesLoading\" :rows=\"2\" animated />"),
    _createCommentVNode("    </div>"),
    _createCommentVNode(" endpoints "),
    _createCommentVNode("    <div v-if=\"hasEndpoints\" class=\"mt-[32px]\">"),
    _createCommentVNode("      <h3 class=\"text-[20px] text-[#303133] flex items-center gap-[8px]\">"),
    _createCommentVNode("        &lt;!&ndash; <SvgIcon name=\"endpoint\" width=\"18\" height=\"18\" /> &ndash;&gt;"),
    _createCommentVNode("        <span>{{ $t(\"endpoints.title\") }}</span>"),
    _createCommentVNode("      </h3>"),
    _createCommentVNode("      <div v-if=\"hasEndpoints\" class=\"grid grid-cols-2 lg:grid-cols-1 gap-4 mb-4 mt-[16px]\">"),
    _createCommentVNode("        <EndpointItem v-for=\"endpoint in endpoints.data\" :endpoint=\"endpoint\" :namespace=\"name\" />"),
    _createCommentVNode("      </div>"),
    _createCommentVNode("      <div v-else class=\"flex flex-wrap gap-4 mb-4 mt-[16px]\">"),
    _createCommentVNode("        {{ $t(\"all.noData\") }}"),
    _createCommentVNode("      </div>"),
    _createCommentVNode("      <view-more"),
    _createCommentVNode("        v-if=\"endpoints.more\""),
    _createCommentVNode("        target=\"endpoints\""),
    _createCommentVNode("        @view-more-targets=\"viewMoreTargets\""),
    _createCommentVNode("      ></view-more>"),
    _createCommentVNode("      <el-skeleton class=\"pr-6\" v-if=\"endpointsLoading\" :rows=\"2\" animated />"),
    _createCommentVNode("    </div>"),
    _createCommentVNode(" finetunes "),
    _createCommentVNode("    <div>"),
    _createCommentVNode("      <h3 class=\"text-[20px] text-[#303133] flex items-center gap-[8px]\">"),
    _createCommentVNode("        &lt;!&ndash; <SvgIcon name=\"profile_finetune\" width=\"18\" height=\"18\" /> &ndash;&gt;"),
    _createCommentVNode("        <span>{{ $t(\"finetune.title\") }}</span>"),
    _createCommentVNode("      </h3>"),
    _createCommentVNode("      <div v-if=\"hasFinetune\" class=\"grid grid-cols-2 xl:grid-cols-1 gap-4 mb-4 mt-[16px]\">"),
    _createCommentVNode("        <FinetuneItem v-for=\"finetune in finetunes.data\" :repo=\"finetune\" repo-type=\"finetune\" />"),
    _createCommentVNode("      </div>"),
    _createCommentVNode("      <div v-else class=\"flex flex-wrap gap-4 mb-4 mt-[16px]\">"),
    _createCommentVNode("        {{ $t(\"all.noData\") }}"),
    _createCommentVNode("      </div>"),
    _createCommentVNode("      <view-more v-if=\"finetunes.more\" target=\"finetunes\" @view-more-targets=\"viewMoreTargets\"></view-more>"),
    _createCommentVNode("      <el-skeleton class=\"pr-6\" v-if=\"finetunesLoading\" :rows=\"2\" animated />"),
    _createCommentVNode("    </div>")
  ], 4 /* STYLE */))
}