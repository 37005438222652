<template>
  <a :href="detailLink">
  <div class="searchLiTag" >
    <div class="lable">
      {{item.nickname}}
    </div>
    <div class="text">
      {{item.path}}
    </div>
  </div>
  </a>
</template>
<script setup>
import {computed} from "vue";

const props = defineProps({
  item:Object,
  repoType:String
})

const detailLink = computed(() => {
  switch (props.repoType) {
    case 'model':
      return `/models/${props.item.path}`
    case 'dataset':
      return `/datasets/${props.item.path}`
    case 'space':
      return `/spaces/${props.item.path}`
    case 'code':
      return `/codes/${props.item.path}`
    default:
      return ''
  }
})
</script>
<style scoped>
.searchLiTag{
  width: 100%;
  height: 40px;
  padding: 4px 0;
  border-bottom: 1px solid  #E5E6EB;
}
.lable{
  color: #27254c;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-wrap: normal;
}
.lable:hover{
  color: #208DE7;
}
.text{
  color: #9c9da2;
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-wrap: normal;
}
</style>