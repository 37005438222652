<template>
  <div class="repo-tabs">
  <div class="relative">
    <el-tabs
      v-model="activeName"
      :beforeLeave="handleTabLeave"
      @tabClick="tabClick"
      class="modelTabs"
    >
      <!-- repo/endpoint summary -->
      <el-tab-pane
        :label="reoiTypeName + summaryLabel"
        name="summary"
      >
        <slot name="summary"></slot>
      </el-tab-pane>

      <!-- repo files -->
      <el-tab-pane
        v-if="showFiles"
        :label="reoiTypeName + $t('all.files')"
        name="files"
      >
        <slot name="files"></slot>
      </el-tab-pane>

      <!-- repo community -->
<!--      <el-tab-pane-->
<!--        v-if="repoType !== 'endpoint' && !!localRepoId"-->
<!--        :label="$t('all.community')"-->
<!--        name="community"-->
<!--        class="min-h-[300px]"-->
<!--      >-->
<!--        <slot name="community"></slot>-->
<!--      </el-tab-pane>-->

      <!-- endpoint logs -->
      <el-tab-pane
        v-if="repoType === 'endpoint'"
        :label="reoiTypeName + $t('all.logs')"
        name="logs"
      >
        <slot name="logs"></slot>
      </el-tab-pane>

       <!-- billing -->
       <el-tab-pane
        v-if="(repoType === 'endpoint' || repoType === 'space') && settingsVisibility"
        :label="$t('billing.billing')"
        name="billing"
      >
        <slot name="billing"></slot>
      </el-tab-pane>

      <!-- repo settings -->
      <el-tab-pane
        v-if="settingsVisibility"
        :label="reoiTypeName + $t('all.settings')"
        name="settings"
      >
        <slot name="settings"></slot>
      </el-tab-pane>
    </el-tabs>
  </div>
  </div>
</template>

<script setup>
  import { ref, computed } from 'vue'
  import { useI18n } from 'vue-i18n'

  const { t } = useI18n()

  const props = defineProps({
    defaultTab: String,
    settingsVisibility: Boolean,
    repoType: String,
    sdk: String,
    localRepoId: String,
    repo: Object,
    userName:String
  })

  const summaryLabel = computed(() => {
    if (props.repoType === 'space') {
      return t('application_spaces.app')
    } else if (props.repoType === 'endpoint') {
      return t('endpoints.summary')
    } else {
      return t('all.summary')
    }
  })

  const reoiTypeName = computed(() => {
    if(props.repoType === 'model'){
      return '模型'
    }else if (props.repoType === 'dataset') {
      return '数据集'
    }else if (props.repoType === 'code') {
      return '代码'
    }else {
      return ''
    }
  })

  const showFiles = computed(() => {
    if (props.repoType === 'endpoint') return false
    if (props.repoType === 'space' && props.sdk === 'nginx') return props.settingsVisibility

    return true
  });

  const activeName = ref(props.defaultTab)

  const emit = defineEmits(['tabChange']);

  const handleTabLeave = (tab) => {
    // emit('tabChange', tab)
    console.log(tab)
    handleTabClick(tab)
    return false
  }
  const tabClick = (tab) => {
    if (tab.paneName === activeName.value) {
      handleTabClick(tab.paneName)
    }
  }

  const repoNamespace = computed(() => {
    if (!!props.repo.path) {
      return props.repo.path.split('/')[0]
    } else if (!!props.repo.model_id) {
      return props.userName
    } else {
      return ''
    }
  })

  const summaryUrl = () => {
    if (props.repoType === 'endpoint') {
      return `/${props.repoType}s/${repoNamespace.value}/${props.repo.deploy_name}/${props.repo.deploy_id}`
    } else {
      return `/${props.repoType}s/${props.repo.path}`
    }
  }

  const handleTabClick = (tab) => {
      switch (tab) {
        case 'summary':
          location.href = summaryUrl()
          break
        case 'files':
          location.href = `/${props.repoType}s/${props.repo.path}/files/main`
          break
        case 'community':
          location.href = `/${props.repoType}s/${props.repo.path}/community`
          break
        case 'settings':
          if (props.repoType === 'endpoint') {
            location.href = `/${props.repoType}s/${repoNamespace.value}/${props.repo.deploy_name}/${props.repo.deploy_id}/settings`
          } else {
            location.href = `/${props.repoType}s/${props.repo.path}/settings`
          }
          break
        case 'logs':
          location.href = `/${props.repoType}s/${repoNamespace.value}/${props.repo.deploy_name}/${props.reprepo.deploy_id}/logs`
          break
        case 'billing':
          if (props.repoType === 'endpoint') {
            location.href = `/${props.repoType}s/${repoNamespace.value}/${props.repo.deploy_name}/${props.repo.deploy_id}/billing`
          } else {
            location.href = `/${props.repoType}s/${props.repo.path}/billing`
          }
          break
        default:
          break
      }
  }
</script>

<style scoped>
/deep/ .el-tabs__item.is-top.is-active{
  //styleName: H6/CN-Bold;
  font-family: PingFang SC;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #323232;
  padding: 8px 16px !important;
  //gap: 15.5px;
}
/deep/ .el-tabs__item.is-top{
  font-family: PingFang SC;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #6B7785;
  padding: 8px 16px 8px 16px;
  padding-left: 16px!important;
  //gap: 15.5px;
}
/deep/ .el-tabs__item.is-top:hover {
  color: #1D2129;
}
.modelTabs{
  --el-tabs-header-height:32px;
}

</style>
