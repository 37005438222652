import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex md:px-5 md:flex-col-reverse min-h-[calc(100vh-341px)]" }
const _hoisted_2 = { class: "sm:max-w-[100%] pt-4 pb-10 pr-5 sm:pr-0 break-words flex-1 border-t border-[#EBEEF5] md:border-t-0" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_skeleton = _resolveComponent("el-skeleton")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      ($setup.loading)
        ? (_openBlock(), _createBlock(_component_el_skeleton, {
            key: 0,
            class: "mt-4",
            rows: 5,
            animated: ""
          }))
        : _createCommentVNode("v-if", true),
      ($setup.previewData.data)
        ? (_openBlock(), _createBlock($setup["ParquetViewer"], {
            key: 1,
            previewData: $setup.previewData.data
          }, null, 8 /* PROPS */, ["previewData"]))
        : _createCommentVNode("v-if", true),
      (!$setup.loading)
        ? (_openBlock(), _createBlock($setup["MarkdownViewer"], {
            key: 2,
            content: $setup.readmeContent,
            setDefaultText: true
          }, null, 8 /* PROPS */, ["content"]))
        : _createCommentVNode("v-if", true)
    ]),
    _createCommentVNode("    <div class=\"w-[40%] sm:w-[100%] border-l border-[#EBEEF5] md:border-l-0 md:border-b md:w-full md:pl-0\">"),
    _createCommentVNode("      <div class=\"p-[16px]\">"),
    _createCommentVNode("        <div class=\"text-[#606266] text-base font-medium leading-[22px] md:pl-0\">{{ $t('all.downloadCount') }}</div>"),
    _createCommentVNode("        <div class=\"text-[#303133] text-base font-semibold leading-6 mt-1 md:pl-0\">{{ downloadCount }}</div>"),
    _createCommentVNode("      </div>"),
    _createCommentVNode("      <QuestionAnswer v-if=\"inferenceStatus === 'RUNNING' && widgetType === 'generation'\""),
    _createCommentVNode("                      :namespacePath=\"namespacePath\""),
    _createCommentVNode("                      :currentBranch=\"currentBranch\""),
    _createCommentVNode("      />"),
    _createCommentVNode("      <SpaceRelationsCard v-if=\"relations['spaces'] && relations['spaces'].length !== 0\""),
    _createCommentVNode("                          :namespacePath=\"namespacePath\""),
    _createCommentVNode("                          :spaces=\"relations['spaces']\""),
    _createCommentVNode("      />"),
    _createCommentVNode("      <CodeRelationsCard v-if=\"relations['codes'] && relations['codes'].length !== 0\""),
    _createCommentVNode("                          :namespacePath=\"namespacePath\""),
    _createCommentVNode("                          :codes=\"relations['codes']\""),
    _createCommentVNode("      />"),
    _createCommentVNode("      <DatasetRelationsCard v-if=\"relations['datasets'] && relations['datasets'].length !== 0\""),
    _createCommentVNode("                            :namespacePath=\"namespacePath\""),
    _createCommentVNode("                            :datasets=\"relations['datasets']\""),
    _createCommentVNode("      />"),
    _createCommentVNode("      <ModelRelationsCard v-if=\"relations['models'] && relations['models'].length !== 0\""),
    _createCommentVNode("                          :namespacePath=\"namespacePath\""),
    _createCommentVNode("                          :models=\"relations['models']\""),
    _createCommentVNode("      />"),
    _createCommentVNode("    </div>")
  ]))
}