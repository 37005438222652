<template>
  <div v-for="item in industryTagsList" :key="item.id"
    :class="SelectedID === item.id ? 'industrTag  activationTag' : 'industrTag'" @click="industryClick(item)">
    <div class="icon" :style="{background: item.industry_color}" />
    <div class="industrData">
      <div :class="SelectedID === item.id ? 'industrLable activationLable' : 'industrLable'">{{ item.show_name }}</div>
      <div class="industrNumber">已发布个{{ item.count }}模型</div>
    </div>
  </div>
</template>
<script setup>
const props = defineProps({
  industryTagsList: Array,
  SelectedID: Number
})

console.log(props.industryTagsList);


const emit = defineEmits(['industryClick'])

const industryClick = (row) => {
  emit('industryClick', row)
}

</script>
<style scoped >
.icon {
  background: linear-gradient(90deg, rgba(91, 143, 249, 0.6) 0%, rgba(149, 128, 249, 0.6) 100%);
  width: 48px;
  height: 48px;
  border-radius: 50%;
}

.industrTag {
  display: flex;
  padding: 20px 16px;
  border-bottom: 1px solid #E5E6EB;
  gap: 16px;
  position: relative;
  cursor: pointer;
}

.industrTag:hover::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background: linear-gradient(90deg, #2047D3 0%, #925FFF 100%);
}



.activationTag {
  border: none;
}

.activationTag::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background: linear-gradient(90deg, #2047D3 0%, #925FFF 100%);
}

.industrData {}

.industrLable {
  font-family: PingFang SC;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #1D2129;
}

.industrNumber {
  font-family: PingFang SC;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #6B7785;
}
.activationLable {
  font-family: PingFang SC;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #1D2129;
}
</style>