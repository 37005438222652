import { createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2eb4df4a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "w-full bg-[#FCFCFD] pt-9 pb-[60px] xl:px-10 md:px-0 md:pb-6 md:h-auto" }
const _hoisted_2 = { class: "mx-auto max-w-[1280px]" }
const _hoisted_3 = { class: "mx-auto max-w-[1280px] mt-[-40px] xl:px-10 md:px-0" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode($setup["RepoHeader"], {
          private: $props.endpoint.data.private,
          name: $props.endpoint.data.deploy_name,
          path: $props.endpoint.data.model_id,
          appStatus: $setup.appStatus,
          "space-resource": $props.endpoint.data.hardware,
          avatar: $props.avatar,
          "owner-url": $props.ownerUrl,
          "repo-type": "endpoint",
          repoId: $props.endpoint.data.repository_id
        }, null, 8 /* PROPS */, ["private", "name", "path", "appStatus", "space-resource", "avatar", "owner-url", "repoId"])
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode($setup["RepoTabs"], {
        "repo-detail": $props.endpoint.data,
        appStatus: $setup.appStatus,
        appEndpoint: $setup.appEndpoint,
        "current-path": $props.currentPath,
        "default-tab": $props.defaultTab,
        actionName: $props.actionName,
        settingsVisibility: $props.settingsVisibility,
        "can-write": $props.canWrite,
        "repo-type": "endpoint",
        hardware: $props.endpoint.data.hardware,
        modelId: $props.endpoint.data.model_id,
        private: $props.endpoint.data.private,
        endpointReplica: $props.endpoint.data.actual_replica,
        endpointName: $props.endpoint.data.deploy_name,
        endpointId: $props.endpoint.data.deploy_id,
        userName: $props.userName,
        replicaList: $setup.replicaList
      }, null, 8 /* PROPS */, ["repo-detail", "appStatus", "appEndpoint", "current-path", "default-tab", "actionName", "settingsVisibility", "can-write", "hardware", "modelId", "private", "endpointReplica", "endpointName", "endpointId", "userName", "replicaList"])
    ])
  ], 64 /* STABLE_FRAGMENT */))
}