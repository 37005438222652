import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "text-right" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_input = _resolveComponent("el-input")
  const _component_el_form_item = _resolveComponent("el-form-item")
  const _component_el_option = _resolveComponent("el-option")
  const _component_el_select = _resolveComponent("el-select")
  const _component_el_form = _resolveComponent("el-form")
  const _component_el_dialog = _resolveComponent("el-dialog")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_button, {
      onClick: $setup.openVisible,
      class: "Default"
    }, {
      default: _withCtx(() => [
        _createTextVNode("引用到算法平台")
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_el_dialog, {
      modelValue: $setup.modleVisible,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => (($setup.modleVisible) = $event)),
      title: "引用到算法平台",
      class: "w-[300px] dialogLoading",
      "before-close": $setup.beroreClose
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_form, {
          ref: "ruleFormRef",
          model: $setup.form,
          rules: $setup.rules,
          "label-width": "auto"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, {
              label: "名称",
              prop: "modelName"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: $setup.form.modelName,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.form.modelName) = $event)),
                  placeholder: "请输入名称"
                }, null, 8 /* PROPS */, ["modelValue"])
              ]),
              _: 1 /* STABLE */
            }),
            ($props.repoType === 'model')
              ? (_openBlock(), _createBlock(_component_el_form_item, {
                  key: 0,
                  label: "框架",
                  prop: "modelFramework"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_select, {
                      modelValue: $setup.form.modelFramework,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.form.modelFramework) = $event)),
                      placeholder: "请选择框架"
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.frameTypeList, (item) => {
                          return (_openBlock(), _createBlock(_component_el_option, {
                            key: item.value,
                            label: item.label,
                            value: item.label
                          }, null, 8 /* PROPS */, ["label", "value"]))
                        }), 128 /* KEYED_FRAGMENT */))
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }))
              : _createCommentVNode("v-if", true),
            ($props.repoType === 'model')
              ? (_openBlock(), _createBlock(_component_el_form_item, {
                  key: 1,
                  label: "模型格式",
                  prop: "modelFormat"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_select, {
                      modelValue: $setup.form.modelFormat,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($setup.form.modelFormat) = $event)),
                      placeholder: "请选择模型格式"
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.modelTypeList, (item) => {
                          return (_openBlock(), _createBlock(_component_el_option, {
                            key: item.value,
                            label: item.label,
                            value: item.label
                          }, null, 8 /* PROPS */, ["label", "value"]))
                        }), 128 /* KEYED_FRAGMENT */))
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }))
              : _createCommentVNode("v-if", true),
            ($props.repoType === 'model')
              ? (_openBlock(), _createBlock(_component_el_form_item, {
                  key: 2,
                  label: "模型类别",
                  prop: "modelCategory"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_select, {
                      modelValue: $setup.form.modelCategory,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => (($setup.form.modelCategory) = $event)),
                      placeholder: "请选择模型类别"
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.modelClassList, (item) => {
                          return (_openBlock(), _createBlock(_component_el_option, {
                            key: item.value,
                            label: item.label,
                            value: item.label
                          }, null, 8 /* PROPS */, ["label", "value"]))
                        }), 128 /* KEYED_FRAGMENT */))
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }))
              : _createCommentVNode("v-if", true)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["model", "rules"]),
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_el_button, {
            type: "primary",
            onClick: $setup.submitForm
          }, {
            default: _withCtx(() => [
              _createTextVNode(" 提交 ")
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_el_button, { onClick: $setup.resetForm }, {
            default: _withCtx(() => [
              _createTextVNode("取消")
            ]),
            _: 1 /* STABLE */
          })
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue"])
  ], 64 /* STABLE_FRAGMENT */))
}