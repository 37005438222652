<template>
  <div class="speciaListTag setWidth">
    <div class="lableBox" :style="item.style.style">
      <div class="topLableBox">
        <div class="icon" :style="{backgroundColor: item.style.imgColor  }">
          <img src="/images/imgIcon.svg" alt="" style="color: #ccc">
        </div>
        <div class="leble">
          {{item.name}}
        </div>
        <div class="moreButton" @click="tagClick(item)">
          查看更多
          <div class="rightArrow">
            <el-icon><ArrowRight /></el-icon>
          </div>
        </div>
      </div>
      <div class="lebleBottom">
        {{ item.outline }}
      </div>
    </div>
    <div class="bottomBox">
      <div class="abstract">
        <div class="abstractIcon">
          <img src="/images/abstractIcon.png">
        </div>
        <div class="abstractLable">专题内容摘要</div>
      </div>
      <div class="abstractText" ref="abstractTextRef">
        <el-tooltip
          class="box-item"
          effect="dark"
          :content="item.introduce"
          placement="top-end"
          :disabled="!isOverflowDescription"
      >
        {{ item.introduce }}
        </el-tooltip>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref,onMounted } from 'vue'
const props = defineProps({
  item:Object
})
const isOverflowDescription = ref(false)
const abstractTextRef = ref(null)

const tagClick = (item) => {
  if(item.href) return location.href = item.href
  location.href = `/special?id=${item.id}`
}

const isTextClamped = (element) => {
  const lineHeight = parseFloat(window.getComputedStyle(element).lineHeight);
  const maxHeight = lineHeight * parseInt(window.getComputedStyle(element)['-webkit-line-clamp'], 10);

  return element.scrollHeight > maxHeight;
}

const isOverflow = () => {
  if(abstractTextRef.value){
    isOverflowDescription.value = isTextClamped(abstractTextRef.value)
  }
}

onMounted(() => {
  isOverflow()
})
</script>

<style scoped>
.speciaListTag{
  height: 220px;
  padding: 16px;
  gap: 12px;
  border-radius: 2px;
  border: 1px solid #F2F3F5;
  box-shadow: 0px 2px 4px 0px #001C4D08;
  background: #FFFFFF;
}
.lableBox{
  width: 100%;
  height: 108px;
  padding: 16px 20px;
  background: #5B8FF91A;
  box-shadow: 0px 0px 32px 0px #5B8FF933 inset;
}
.icon{
  width: 16px;
  height: 16px;
  border-radius: 2px;
}
img{
  width: 100%;
  height: 100%;
}
.leble{
  font-family: PingFang SC;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  width: calc(100% - 116px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.moreButton{
  padding: 0 8px;
  height: 24px;
  background-color: #2047D3;
  border-radius: 2px;
  font-family: PingFang SC;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.moreButton:hover{
  background-color: #4369DC;
}
.moreButton:focus{
  background-color: #1431B1;
}
.rightArrow{
  height: 16px;
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
}
.topLableBox{
  display: flex;
  gap: 8px;
  align-items: center;
}
.lebleBottom{
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #1D2129;
  margin-top: 8px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}
.bottomBox{
  margin-top: 12px;
}
.abstract{
  display: flex;
  align-items: center;
}
.abstractIcon{
  width: 12px;
  height: 12px;
  margin-right: 8px;
}
.abstractLable{
  font-family: PingFang SC;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #1D2129;
}
.abstractText{
  margin-top: 8px;
  margin-left: 20px;
  font-family: PingFang SC;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #6B7785;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>