<template>
  <div class="pagination">
    <el-pagination
        v-model:current-page="theCurrentPage"
        :total="total"
        :page-size="perPage"
        layout="prev, pager, next"
        @update:current-page="currentChange"
        class="pagination-item"
        v-show="total !== 0"
    />
  </div>
</template>

<script setup>
import { ref, watch } from "vue";
import Previous from "./Previous.vue";
import Next from "./Next.vue";
const props = defineProps({
  perPage: Number,
  total: Number,
  currentPage: Number,
});
const emit = defineEmits(["currentChange"]);

const theCurrentPage = ref(props.currentPage);
const prevIcon = ref(Previous);
const nextIcon = ref(Next);
const currentChange = (currentPage) => {
  emit("currentChange", currentPage);
};

// 监听 props.currentPage 的变化来更新 theCurrentPage
watch(() => props, (newPage) => {
  if(newPage.currentPage !== theCurrentPage.value){
    theCurrentPage.value = newPage.currentPage;
  }
}, {
  deep: true
});
</script>
<style scoped>
.pagination{
  width: 100%;
  padding: 16px;
  padding-bottom: 0px;
  display: flex;
  justify-content: flex-end;
  z-index: 5;
  height: 48px;
  //position: absolute;
  //bottom: 0;
  //left: 0;
}
.pagination-item{
  --el-pagination-hover-color: #2047D3;
}
.pagination-item /deep/.el-pager{
  gap: 8px;
  margin: 0px 8px;
}
.pagination-item /deep/ .el-pager .number{
  color: #6B7785;
}
.pagination-item /deep/ .el-pager .number:hover{
  color: #2047D3;
}
.pagination-item /deep/ .el-pager .is-active{
  color: #2047D3;
  background-color: #E8F1FF;
  font-weight: 600;
}

</style>