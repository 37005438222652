import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-[8px] w-[260px] p-[20px] border rounded-[10px] bg-[#F4F4F6]" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_RouterLink = _resolveComponent("RouterLink")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_RouterLink, {
      to: "/new_admin/",
      class: "underline"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('admin.dashboard.title')), 1 /* TEXT */)
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_RouterLink, {
      to: "/new_admin/system_config",
      class: "underline"
    }, {
      default: _withCtx(() => [
        _createTextVNode(" System Config ")
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_RouterLink, {
      to: "/new_admin/users",
      class: "underline"
    }, {
      default: _withCtx(() => [
        _createTextVNode(" Users ")
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_RouterLink, {
      to: "/new_admin/models",
      class: "underline"
    }, {
      default: _withCtx(() => [
        _createTextVNode(" Models ")
      ]),
      _: 1 /* STABLE */
    }),
    (!$setup.onPremise)
      ? (_openBlock(), _createBlock(_component_RouterLink, {
          key: 0,
          to: "/new_admin/codes",
          class: "underline"
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Codes ")
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true),
    ($setup.onPremise)
      ? (_openBlock(), _createBlock(_component_RouterLink, {
          key: 1,
          to: "/new_admin/sync",
          class: "underline"
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Sync Setting ")
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true),
    ($setup.onPremise === 'false')
      ? (_openBlock(), _createBlock(_component_RouterLink, {
          key: 2,
          to: "/new_admin/email_sending",
          class: "underline"
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Email Sending ")
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true)
  ]))
}