import { createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("span", {
    ref: "TagItem",
    class: "px-[8px] py-[2px] rounded-[2px] cursor-pointer flex items-center gap-[10px] tab-class",
    onClick: _cache[0] || (_cache[0] = $event => ($setup.emit('handleTagClick', $props.tag))),
    style: _normalizeStyle({backgroundColor: $props.active?$props.color?.color:$props.color?.backgroundColor,color: $props.active ? '#fff':$props.color?.color})
  }, [
    _createCommentVNode("    <img :src=\"'/images/tags/' + tag.name + '.svg'\""),
    _createCommentVNode("         class=\"w-[14px] h-[14px]\""),
    _createCommentVNode("         :style=\"active ? 'filter: drop-shadow(1000px 0 0 white); transform: translate(-1000px);' : ''\""),
    _createCommentVNode("         alt=\"\""),
    _createCommentVNode("         onerror=\"this.style.display='none'\" />"),
    _createTextVNode(" " + _toDisplayString($setup.tagName), 1 /* TEXT */)
  ], 4 /* STYLE */))
}