import { createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9587de06"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-right" }
const _hoisted_2 = { class: "rounded-t border-t border-x border-[#EBEEF5] mt-4" }
const _hoisted_3 = { class: "flex items-center px-3 py-2 bg-[#F5F7FA] text-[#303133]" }
const _hoisted_4 = { class: "flex flex-col gap-1 px-3 py-2 border-t border-[#EBEEF5] bg-[#ffffff] text-[#303133] break-all" }
const _hoisted_5 = {
  key: 0,
  class: "my-[4px]"
}
const _hoisted_6 = { class: "text-[#909399]" }
const _hoisted_7 = { class: "text-[#909399]" }
const _hoisted_8 = { class: "flex flex-col gap-1 px-3 py-2 border-t border-[#EBEEF5] bg-[#ffffff] text-[#303133] break-all" }
const _hoisted_9 = { class: "text-[#909399]" }
const _hoisted_10 = { class: "text-[#909399]" }
const _hoisted_11 = { class: "message" }
const _hoisted_12 = { class: "dialog-footer" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_alert = _resolveComponent("el-alert")
  const _component_el_input = _resolveComponent("el-input")
  const _component_el_form_item = _resolveComponent("el-form-item")
  const _component_el_form = _resolveComponent("el-form")
  const _component_el_dialog = _resolveComponent("el-dialog")
  const _component_el_checkbox = _resolveComponent("el-checkbox")
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")
  const _component_el_tabs = _resolveComponent("el-tabs")

  return (_openBlock(), _createElementBlock("div", null, [
    ($setup.showSyncButton)
      ? (_openBlock(), _createBlock(_component_el_button, {
          key: 0,
          type: "default",
          class: "!rounded-lg",
          disabled: $setup.syncInprogress,
          onClick: $setup.handleSyncRepo
        }, {
          default: _withCtx(() => [
            _createCommentVNode("      <SvgIcon name=\"sync\" class=\"mr-2\" />"),
            _createTextVNode(" " + _toDisplayString($setup.syncInprogress ? _ctx.$t("repo.source.syncing") : _ctx.$t("repo.source.syncButton")), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["disabled"]))
      : _createCommentVNode("v-if", true),
    ($props.repoType === 'model' && $props.admin && $props.enableEndpoint && !!$props.httpCloneUrl)
      ? (_openBlock(), _createBlock($setup["DeployDropdown"], {
          key: 1,
          modelId: $props.namespacePath
        }, null, 8 /* PROPS */, ["modelId"]))
      : _createCommentVNode("v-if", true),
    ($props.repoType === 'model' && $props.admin && $props.enableFinetune && !!$props.httpCloneUrl)
      ? (_openBlock(), _createBlock(_component_el_button, {
          key: 2,
          type: "primary",
          onClick: $setup.toFinetunePage,
          style: {"width":"100%"}
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('finetune.title')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true),
    _createCommentVNode("    <div"),
    _createCommentVNode("      class=\"flex px-[12px] py-[5px] mr-4 justify-center items-center gap-1 rounded-lg bg-[#FFF] border border-[#D0D5DD] shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] cursor-pointer\""),
    _createCommentVNode("      v-if=\"repoType === 'model' && admin && enableFinetune && !!httpCloneUrl\""),
    _createCommentVNode("      @click=\"toFinetunePage\""),
    _createCommentVNode("    >"),
    _createCommentVNode("&lt;!&ndash;      <SvgIcon&ndash;&gt;"),
    _createCommentVNode("&lt;!&ndash;        name=\"model_finetune_create\"&ndash;&gt;"),
    _createCommentVNode("&lt;!&ndash;        class=\"mr-1\"&ndash;&gt;"),
    _createCommentVNode("&lt;!&ndash;      />&ndash;&gt;"),
    _createCommentVNode("      <div class=\"text-sm\"></div>"),
    _createCommentVNode("    </div>"),
    (!!$props.httpCloneUrl)
      ? (_openBlock(), _createBlock(_component_el_button, {
          key: 3,
          type: "primary",
          onClick: $setup.clickDownload,
          style: {"width":"100%"}
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t($setup.downloadButtonKey)), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true),
    _createCommentVNode("    <div"),
    _createCommentVNode("      v-if=\"!!httpCloneUrl\""),
    _createCommentVNode("      class=\"flex px-[12px] py-[5px] justify-center items-center gap-1 rounded-lg bg-[#3250BD] shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] cursor-pointer\""),
    _createCommentVNode("      @click=\"clickDownload\""),
    _createCommentVNode("    >"),
    _createCommentVNode("&lt;!&ndash;      <SvgIcon&ndash;&gt;"),
    _createCommentVNode("&lt;!&ndash;        name=\"download\"&ndash;&gt;"),
    _createCommentVNode("&lt;!&ndash;        class=\"mr-1\"&ndash;&gt;"),
    _createCommentVNode("&lt;!&ndash;      />&ndash;&gt;"),
    _createCommentVNode("      <div class=\"text-[#fff] text-sm\">{{ $t(downloadButtonKey) }}</div>"),
    _createCommentVNode("    </div>"),
    _createVNode(_component_el_dialog, {
      modelValue: $setup.informationVisible,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => (($setup.informationVisible) = $event)),
      title: "完善信息",
      class: "w-[300px]",
      "before-close": $setup.beroreClose
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_alert, {
          title: '完善信息后即可下载' + $setup.getRepoType($props.repoType),
          type: "warning",
          "show-icon": "",
          closable: false,
          class: "alertClass"
        }, null, 8 /* PROPS */, ["title"]),
        _createVNode(_component_el_form, {
          ref: "ruleFormRef",
          model: $setup.form,
          rules: $setup.rules,
          "label-width": "auto"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, {
              label: "企业名称",
              prop: "enterpriseName"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: $setup.form.enterpriseName,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.form.enterpriseName) = $event)),
                  placeholder: "请输入企业名称"
                }, null, 8 /* PROPS */, ["modelValue"])
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_el_form_item, {
              label: "联系人姓名",
              prop: "contactName"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: $setup.form.contactName,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.form.contactName) = $event)),
                  placeholder: "请输入联系人姓名"
                }, null, 8 /* PROPS */, ["modelValue"])
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_el_form_item, {
              label: "联系人电话",
              prop: "phone"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: $setup.form.phone,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($setup.form.phone) = $event)),
                  placeholder: "请输入联系人电话"
                }, null, 8 /* PROPS */, ["modelValue"])
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_el_form_item, {
              label: "联系人职位",
              prop: "position"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: $setup.form.position,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => (($setup.form.position) = $event)),
                  placeholder: "请输入联系人职位"
                }, null, 8 /* PROPS */, ["modelValue"])
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["model", "rules"]),
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_el_button, {
            type: "primary",
            onClick: $setup.submitForm
          }, {
            default: _withCtx(() => [
              _createTextVNode(" 提交 ")
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_el_button, { onClick: $setup.resetForm }, {
            default: _withCtx(() => [
              _createTextVNode("取消")
            ]),
            _: 1 /* STABLE */
          })
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue"]),
    _createVNode(_component_el_dialog, {
      modelValue: $setup.cloneRepositoryVisible,
      "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => (($setup.cloneRepositoryVisible) = $event)),
      title: "下载模型",
      class: "md:!w-[80%]"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createCommentVNode("          <SvgIcon"),
            _createCommentVNode("            name=\"clone\""),
            _createCommentVNode("            class=\"mr-1\""),
            _createCommentVNode("          />"),
            _createTextVNode(" " + _toDisplayString(_ctx.$t($setup.downloadModalKey)), 1 /* TEXT */)
          ])
        ]),
        _createVNode(_component_el_tabs, {
          modelValue: $setup.activeCloneType,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => (($setup.activeCloneType) = $event)),
          class: "border border-[#EBEEF5] mb-8 clone-tabs"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_tab_pane, {
              label: "HTTPS",
              name: "https"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_4, [
                  ($setup.currentUser)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                        _createVNode(_component_el_checkbox, {
                          modelValue: $setup.useToken,
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => (($setup.useToken) = $event)),
                          label: _ctx.$t('application_spaces.gradioGuide.useToken'),
                          size: "large"
                        }, null, 8 /* PROPS */, ["modelValue", "label"])
                      ]))
                    : _createCommentVNode("v-if", true),
                  _createElementVNode("div", _hoisted_6, "# " + _toDisplayString(_ctx.$t('all.lfsTips')), 1 /* TEXT */),
                  ($setup.useToken)
                    ? (_openBlock(), _createBlock($setup["MarkdownViewer"], {
                        key: 1,
                        content: $setup.httpsCloneCodeWithTokenMarkdown
                      }, null, 8 /* PROPS */, ["content"]))
                    : (_openBlock(), _createBlock($setup["MarkdownViewer"], {
                        key: 2,
                        content: $setup.httpsCloneCodeMarkdown
                      }, null, 8 /* PROPS */, ["content"])),
                  _createElementVNode("div", _hoisted_7, "# " + _toDisplayString(_ctx.$t('all.lfsTips2')), 1 /* TEXT */),
                  _createVNode($setup["MarkdownViewer"], {
                    content: $setup.getMarkdownCode('  GIT_LFS_SKIP_SMUDGE=1', 'bash')
                  }, null, 8 /* PROPS */, ["content"])
                ])
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_el_tab_pane, {
              label: "SSH",
              name: "ssh"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, "# " + _toDisplayString(_ctx.$t('all.lfsTips')), 1 /* TEXT */),
                  _createVNode($setup["MarkdownViewer"], { content: $setup.sshCloneCodeMarkdown }, null, 8 /* PROPS */, ["content"]),
                  _createElementVNode("div", _hoisted_10, "# " + _toDisplayString(_ctx.$t('all.lfsTips2')), 1 /* TEXT */),
                  _createVNode($setup["MarkdownViewer"], {
                    content: $setup.getMarkdownCode('  GIT_LFS_SKIP_SMUDGE=1', 'bash')
                  }, null, 8 /* PROPS */, ["content"])
                ])
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue"]),
    _createVNode(_component_el_dialog, {
      modelValue: $setup.MessageBoxVisible,
      "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => (($setup.MessageBoxVisible) = $event)),
      title: "提示",
      width: "420"
    }, {
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_12, [
          _createVNode(_component_el_button, {
            type: "primary",
            onClick: _cache[8] || (_cache[8] = $event => ($setup.MessageBoxVisible = false))
          }, {
            default: _withCtx(() => [
              _createTextVNode(" 确定 ")
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_el_button, {
            onClick: _cache[9] || (_cache[9] = $event => ($setup.MessageBoxVisible = false))
          }, {
            default: _withCtx(() => [
              _createTextVNode("取消")
            ]),
            _: 1 /* STABLE */
          })
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_11, "下载" + _toDisplayString($setup.getRepoType($props.repoType)) + "前请先登录", 1 /* TEXT */)
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue"])
  ]))
}