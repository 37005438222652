import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = { class: "flex justify-between items-center mb-2" }
const _hoisted_3 = { class: "w-full flex items-center justify-between" }
const _hoisted_4 = { class: "flex-1 text-[#303133] font-semibold leading-6 truncate mr-[8px]" }
const _hoisted_5 = { class: "flex gap-2" }
const _hoisted_6 = { class: "px-[8px] py-[3px] flex items-center justify-center border rounded-md text-[#344054] text-[12px]" }
const _hoisted_7 = { class: "text-sm leading-snug text-[#606266] mb-3" }
const _hoisted_8 = { class: "text-sm leading-snug text-[#606266] flex justify-between" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("a", {
    href: `/endpoints/${$props.namespace}/${$props.endpoint.deploy_name}/${$props.endpoint.deploy_id}`,
    class: "focus:outline focus:outline-4 focus:outline-[#EAECF0] hover:shadow-md border border-gray-200 rounded-xl p-4 w-full"
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h3", _hoisted_4, _toDisplayString($props.endpoint.deploy_name), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("span", _hoisted_6, _toDisplayString($props.endpoint.private ? _ctx.$t("all.private") : _ctx.$t("all.public")), 1 /* TEXT */),
          _createVNode($setup["AppStatus"], {
            appStatus: $props.endpoint.status,
            spaceResource: $props.endpoint.hardware
          }, null, 8 /* PROPS */, ["appStatus", "spaceResource"])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", null, _toDisplayString(_ctx.$t("models.title")) + ": " + _toDisplayString($props.endpoint.model_id), 1 /* TEXT */),
      _createElementVNode("div", null, _toDisplayString(_ctx.$t("endpoints.resourceType")) + ": " + _toDisplayString($props.endpoint.hardware?.gpu ? "GPU" : "CPU"), 1 /* TEXT */)
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("div", null, _toDisplayString(_ctx.$t("all.lastTime")) + ": " + _toDisplayString($props.endpoint.updated_at.substring(0, 10)), 1 /* TEXT */),
      _createElementVNode("div", null, _toDisplayString(_ctx.$t("endpoints.replica")) + ": " + _toDisplayString($props.endpoint.max_replica), 1 /* TEXT */)
    ])
  ], 8 /* PROPS */, _hoisted_1))
}