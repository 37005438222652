import { createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "repo-Data" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 2 }
const _hoisted_5 = { key: 3 }
const _hoisted_6 = { key: 4 }
const _hoisted_7 = { key: 5 }
const _hoisted_8 = { key: 6 }
const _hoisted_9 = { key: 7 }
const _hoisted_10 = { key: 8 }
const _hoisted_11 = { key: 9 }
const _hoisted_12 = { key: 10 }
const _hoisted_13 = { key: 11 }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createCommentVNode("    <RepoClone"),
    _createCommentVNode("      v-if=\"repoType !== 'endpoint'\""),
    _createCommentVNode("      :repoType=\"repoType\""),
    _createCommentVNode("      :httpCloneUrl=\"repoDetail.repository.http_clone_url\""),
    _createCommentVNode("      :sshCloneUrl=\"repoDetail.repository.ssh_clone_url\""),
    _createCommentVNode("      :userName=\"userName\""),
    _createCommentVNode("      :namespacePath=\"repoDetail.path\""),
    _createCommentVNode("      :admin=\"admin\""),
    _createCommentVNode("      :repo=\"repoDetail\""),
    _createCommentVNode("      :enableEndpoint=\"repoDetail.enable_inference\""),
    _createCommentVNode("      :enableFinetune=\"repoDetail.enable_finetune\""),
    _createCommentVNode("    />"),
    _createCommentVNode("   {{actionName}}"),
    _createCommentVNode("    <tab-container"),
    _createCommentVNode("      :default-tab=\"defaultTab\""),
    _createCommentVNode("      :settingsVisibility=\"settingsVisibility\""),
    _createCommentVNode("      :repoType=\"repoType\""),
    _createCommentVNode("      :localRepoId=\"localRepoId\""),
    _createCommentVNode("      :sdk=\"sdk\""),
    _createCommentVNode("      :repo=\"repoDetail\""),
    _createCommentVNode("      @tabChange=\"tabChange\""),
    _createCommentVNode("    >"),
    _createCommentVNode(" summary "),
    ($props.defaultTab === 'summary')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          ($props.repoType === 'space' && $props.appStatus === 'NoAppFile')
            ? (_openBlock(), _createBlock($setup["InitializeGuide"], {
                key: 0,
                "http-clone-url": $props.repoDetail.repository.http_clone_url,
                "ssh-clone-url": $props.repoDetail.repository.ssh_clone_url,
                sdk: $props.sdk,
                "user-name": $props.userName,
                style: _normalizeStyle({ minHeight: $setup.innerHeight + 'px' })
              }, null, 8 /* PROPS */, ["http-clone-url", "ssh-clone-url", "sdk", "user-name", "style"]))
            : ($props.repoType === 'space' && $props.appStatus === 'Running')
              ? (_openBlock(), _createBlock($setup["ApplicationPage"], {
                  key: 1,
                  appEndpoint: $props.appEndpoint,
                  style: _normalizeStyle({ minHeight: $setup.innerHeight + 'px' })
                }, null, 8 /* PROPS */, ["appEndpoint", "style"]))
              : ($props.repoType === 'space' && ($props.appStatus === 'Stopped' || $props.appStatus === 'Sleeping'))
                ? (_openBlock(), _createBlock($setup["StoppedPage"], {
                    key: 2,
                    appStatus: $props.appStatus,
                    canWrite: $props.canWrite,
                    path: $props.repoDetail.path,
                    style: _normalizeStyle({ minHeight: $setup.innerHeight + 'px' })
                  }, null, 8 /* PROPS */, ["appStatus", "canWrite", "path", "style"]))
                : ($props.repoType === 'space')
                  ? (_openBlock(), _createBlock($setup["BuildAndErrorPage"], {
                      key: 3,
                      appStatus: $props.appStatus,
                      canWrite: $props.canWrite,
                      onShowSpaceLogs: $setup.showSpaceLogs,
                      style: _normalizeStyle({ minHeight: $setup.innerHeight + 'px' })
                    }, null, 8 /* PROPS */, ["appStatus", "canWrite", "style"]))
                  : ($props.repoType === 'endpoint')
                    ? (_openBlock(), _createBlock($setup["EndpointPage"], {
                        key: 4,
                        appEndpoint: $props.appEndpoint,
                        modelId: $props.modelId,
                        private: $props.private,
                        endpointReplica: $props.endpointReplica,
                        hardware: $props.hardware,
                        replicaList: $props.replicaList,
                        style: _normalizeStyle({ minHeight: $setup.innerHeight + 'px' })
                      }, null, 8 /* PROPS */, ["appEndpoint", "modelId", "private", "endpointReplica", "hardware", "replicaList", "style"]))
                    : (_openBlock(), _createBlock($setup["RepoSummary"], {
                        key: 5,
                        "repo-type": $props.repoType,
                        "namespace-path": $props.repoDetail.path,
                        "download-count": $props.repoDetail.downloads,
                        currentBranch: $props.currentBranch,
                        "widget-type": $props.repoDetail.widget_type,
                        "inference-status": $props.repoDetail.status,
                        style: _normalizeStyle({ minHeight: $setup.innerHeight + 'px' })
                      }, null, 8 /* PROPS */, ["repo-type", "namespace-path", "download-count", "currentBranch", "widget-type", "inference-status", "style"]))
        ]))
      : _createCommentVNode("v-if", true),
    _createCommentVNode(" files "),
    ($props.actionName === 'blob' && $props.defaultTab === 'files')
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode($setup["Blob"], {
            content: $setup.decodedContent,
            "last-commit": $props.blob.commit,
            branches: $props.branches,
            "current-branch": $props.currentBranch,
            "current-path": $props.currentPath,
            "namespace-path": $props.repoDetail.path,
            size: $props.blob.size,
            "can-write": $props.canWrite,
            path: $props.blob.path,
            lfs: $props.blob.lfs,
            "lfs-pointer-size": $props.blob.lfs_pointer_size,
            "lfs-relative-path": $props.blob.lfs_relative_path,
            style: _normalizeStyle({ minHeight: $setup.innerHeight + 'px' })
          }, null, 8 /* PROPS */, ["content", "last-commit", "branches", "current-branch", "current-path", "namespace-path", "size", "can-write", "path", "lfs", "lfs-pointer-size", "lfs-relative-path", "style"])
        ]))
      : _createCommentVNode("v-if", true),
    ($props.actionName === 'new_file' && $props.defaultTab === 'files')
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode($setup["NewFile"], {
            "current-branch": $props.currentBranch,
            "repo-name": $props.repoDetail.name,
            "namespace-path": $props.repoDetail.path,
            originalCodeContent: ""
          }, null, 8 /* PROPS */, ["current-branch", "repo-name", "namespace-path"])
        ]))
      : _createCommentVNode("v-if", true),
    ($props.actionName === 'edit_file' && $props.defaultTab === 'files')
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode($setup["EditFile"], {
            "current-branch": $props.currentBranch,
            "current-path": $props.currentPath,
            "repo-name": $props.repoDetail.name,
            "namespace-path": $props.repoDetail.path,
            originalCodeContent: $setup.decodedContent,
            sha: $props.blob.sha
          }, null, 8 /* PROPS */, ["current-branch", "current-path", "repo-name", "namespace-path", "originalCodeContent", "sha"])
        ]))
      : _createCommentVNode("v-if", true),
    ($props.actionName === 'upload_file' && $props.defaultTab === 'files')
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createVNode($setup["UploadFile"], {
            "current-branch": $props.currentBranch,
            "repo-name": $props.repoDetail.name,
            "namespace-path": $props.repoDetail.path
          }, null, 8 /* PROPS */, ["current-branch", "repo-name", "namespace-path"])
        ]))
      : _createCommentVNode("v-if", true),
    ($props.actionName === 'commits' && $props.defaultTab === 'files')
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createVNode($setup["RepoCommits"], {
            branches: $props.branches,
            currentBranch: $props.currentBranch,
            namespacePath: $props.repoDetail.path,
            repoType: $props.repoType,
            style: _normalizeStyle({ minHeight: $setup.innerHeight + 'px' })
          }, null, 8 /* PROPS */, ["branches", "currentBranch", "namespacePath", "repoType", "style"])
        ]))
      : _createCommentVNode("v-if", true),
    ($props.actionName === 'commit' && $props.defaultTab === 'files')
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createVNode($setup["RepoCommit"], {
            namespacePath: $props.repoDetail.path,
            repoType: $props.repoType,
            commitId: $props.commitId
          }, null, 8 /* PROPS */, ["namespacePath", "repoType", "commitId"])
        ]))
      : _createCommentVNode("v-if", true),
    (($props.actionName === 'show' || $props.actionName === 'files') && $props.defaultTab === 'files')
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createVNode($setup["RepoFiles"], {
            branches: $props.branches,
            "current-branch": $props.currentBranch,
            "current-path": $props.currentPath,
            "namespace-path": $props.repoDetail.path,
            "can-write": $props.canWrite,
            "repo-type": $props.repoType,
            style: _normalizeStyle({ minHeight: $setup.innerHeight + 'px' })
          }, null, 8 /* PROPS */, ["branches", "current-branch", "current-path", "namespace-path", "can-write", "repo-type", "style"])
        ]))
      : _createCommentVNode("v-if", true),
    _createCommentVNode(" logs "),
    ($props.repoType === 'endpoint' && $props.actionName === 'logs' && $props.defaultTab === 'logs')
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _createVNode($setup["EndpointLogs"], {
            instances: $props.repoDetail.instances,
            modelId: $props.repoDetail.model_id,
            deployId: $props.repoDetail.deploy_id
          }, null, 8 /* PROPS */, ["instances", "modelId", "deployId"])
        ]))
      : _createCommentVNode("v-if", true),
    _createCommentVNode(" community "),
    ($props.defaultTab === 'community')
      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
          _createVNode($setup["CommunityPage"], {
            type: $setup.repoTypeClass,
            localModelId: $props.localRepoId
          }, null, 8 /* PROPS */, ["type", "localModelId"])
        ]))
      : _createCommentVNode("v-if", true),
    _createCommentVNode(" billing "),
    ($props.settingsVisibility && $props.defaultTab === 'billing')
      ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
          _createVNode($setup["BillingDetail"], {
            type: $props.repoType,
            instanceName: $props.repoDetail.svc_name
          }, null, 8 /* PROPS */, ["type", "instanceName"])
        ]))
      : _createCommentVNode("v-if", true),
    _createCommentVNode(" settings "),
    ($props.settingsVisibility && $props.defaultTab === 'settings')
      ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
          ($props.repoType === 'model')
            ? (_openBlock(), _createBlock($setup["ModelSettings"], {
                key: 0,
                path: $props.repoDetail.path,
                "model-nickname": $props.repoDetail.nickname,
                "model-desc": $props.repoDetail.description,
                default_branch: $props.repoDetail.default_branch,
                "tag-list": $props.tagList,
                tags: $props.tags,
                style: _normalizeStyle({ minHeight: $setup.innerHeight + 'px' })
              }, null, 8 /* PROPS */, ["path", "model-nickname", "model-desc", "default_branch", "tag-list", "tags", "style"]))
            : _createCommentVNode("v-if", true),
          ($props.repoType === 'dataset')
            ? (_openBlock(), _createBlock($setup["DatasetSettings"], {
                key: 1,
                path: $props.repoDetail.path,
                "dataset-nickname": $props.repoDetail.nickname,
                "dataset-desc": $props.repoDetail.description,
                default_branch: $props.repoDetail.default_branch,
                "tag-list": $props.tagList,
                tags: $props.tags,
                style: _normalizeStyle({ minHeight: $setup.innerHeight + 'px' })
              }, null, 8 /* PROPS */, ["path", "dataset-nickname", "dataset-desc", "default_branch", "tag-list", "tags", "style"]))
            : _createCommentVNode("v-if", true),
          ($props.repoType === 'space')
            ? (_openBlock(), _createBlock($setup["ApplicationSpaceSettings"], {
                key: 2,
                path: $props.repoDetail.path,
                "application-space-nickname": $props.repoDetail.nickname,
                "application-space-desc": $props.repoDetail.description,
                default_branch: $props.repoDetail.default_branch,
                appStatus: $props.appStatus,
                cloudResource: $props.repoDetail.sku || $props.repoDetail.hardware,
                coverImage: $props.repoDetail.cover_image_url,
                onShowSpaceLogs: $setup.showSpaceLogs,
                style: _normalizeStyle({ minHeight: $setup.innerHeight + 'px' })
              }, null, 8 /* PROPS */, ["path", "application-space-nickname", "application-space-desc", "default_branch", "appStatus", "cloudResource", "coverImage", "style"]))
            : _createCommentVNode("v-if", true),
          ($props.repoType === 'code')
            ? (_openBlock(), _createBlock($setup["CodeSettings"], {
                key: 3,
                path: $props.repoDetail.path,
                "code-nickname": $props.repoDetail.nickname,
                "code-desc": $props.repoDetail.description,
                default_branch: $props.repoDetail.default_branch,
                license: $props.repoDetail.license,
                style: _normalizeStyle({ minHeight: $setup.innerHeight + 'px' })
              }, null, 8 /* PROPS */, ["path", "code-nickname", "code-desc", "default_branch", "license", "style"]))
            : _createCommentVNode("v-if", true),
          ($props.repoType === 'endpoint')
            ? (_openBlock(), _createBlock($setup["EndpointSettings"], {
                key: 4,
                endpointName: $props.endpointName,
                endpointId: $props.endpointId,
                appStatus: $props.appStatus,
                modelId: $props.modelId,
                userName: $props.userName,
                cloudResource: $props.repoDetail.sku || $props.repoDetail.hardware,
                framework: $props.repoDetail.runtime_framework,
                maxReplica: $props.repoDetail.max_replica,
                minReplica: $props.repoDetail.min_replica
              }, null, 8 /* PROPS */, ["endpointName", "endpointId", "appStatus", "modelId", "userName", "cloudResource", "framework", "maxReplica", "minReplica"]))
            : _createCommentVNode("v-if", true)
        ]))
      : _createCommentVNode("v-if", true),
    _createCommentVNode("    </tab-container>")
  ]))
}