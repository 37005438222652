import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1f76c710"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pagination" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_pagination = _resolveComponent("el-pagination")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createVNode(_component_el_pagination, {
      "current-page": $setup.theCurrentPage,
      "onUpdate:currentPage": [
        _cache[0] || (_cache[0] = $event => (($setup.theCurrentPage) = $event)),
        $setup.currentChange
      ],
      total: $props.total,
      "page-size": $props.perPage,
      layout: "prev, pager, next",
      class: "pagination-item"
    }, null, 8 /* PROPS */, ["current-page", "total", "page-size"]), [
      [_vShow, $props.total !== 0]
    ])
  ]))
}