<template>
  <div class="flex items-center flex-wrap gap-4 md:px-5 md:mb-[30px] md:flex-col md:items-start">
    <!-- Task -->
<!--    <div-->
<!--      v-if="taskTags.length"-->
<!--      class="text-sm text-[#909399]"-->
<!--    >-->
<!--      {{ $t('all.tasks') }}:-->
<!--    </div>-->
<!--    <tag-item-->
<!--      v-for="tag in theTaskTags.theTags"-->
<!--      :tag="tag"-->
<!--      @handleTagClick="searchByTag"-->
<!--    />-->

    <div class="formatTaskTags" ref="TagItemBox">
        <TagItem v-for="(item,index) in VisibleTag" :tag="{ name: item.name, zh_name: item.show_name, label: null }"
          :color="{ backgroundColor: item.color?.split(',')[0], color: item.color?.split(',')[1] }" />
        <template v-if="hasMore">
          <el-popover placement="bottom" trigger="hover" width="auto" popper-class="popperClass">
            <template #reference>
              <span
                class="text-[14px] bg-[#E8F1FF] text-[#344054] p-[8px] rounded-[4px] cursor-pointer flex items-center gap-[10px]">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_13936_3164)">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M5 8C5 8.55228 4.55228 9 4 9C3.44772 9 3 8.55228 3 8C3 7.44772 3.44772 7 4 7C4.55228 7 5 7.44772 5 8ZM9 8C9 8.55228 8.55228 9 8 9C7.44772 9 7 8.55228 7 8C7 7.44772 7.44772 7 8 7C8.55228 7 9 7.44772 9 8ZM12 9C12.5523 9 13 8.55228 13 8C13 7.44772 12.5523 7 12 7C11.4477 7 11 7.44772 11 8C11 8.55228 11.4477 9 12 9Z"
                      fill="#2047D3" />
                  </g>
                  <defs>
                    <clipPath id="clip0_13936_3164">
                      <rect width="16" height="16" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </span>
              <!--              数据中有一条脏数据，多减一-->
            </template>
            <div class="popoverCard">
              <TagItem v-for="item in hideTage" :tag="{ name: item.name, zh_name: item.show_name, label: null }"
                :color="{ backgroundColor: item.color?.split(',')[0], color: item.color?.split(',')[1] }" />
            </div>
          </el-popover>
      </template>
    </div>

    <!-- <div v-for="tag in theTaskTags.theTags" class="tagItem">
      {{tagName(tag) }}
    </div>
    <MoreTags
      v-if="theTaskTags.moreTags"
      :num="taskTags.length - 3"
      target="task"
      @view-more-targets="viewMoreTargets"
    /> -->
    <!-- Framework -->
    <!-- <div
      v-if="frameworkTags.length"
      class="text-sm text-[#909399]"
    >
      {{ $t('all.framework') }}:
    </div>
    <a
      v-for="tag in theFrameworkTags.theTags"
      :href="`/${prefix}?tag=${tag.name}&tag_type=Framework`"
    >
      <PyTorch v-if="tag.name.toLowerCase() === 'pytorch'" />
      <TensorFlow v-if="tag.name.toLowerCase() === 'tensorflow'" />
      <Safetensors v-if="tag.name.toLowerCase() === 'safetensors'" />
      <ONNX v-if="tag.name.toLowerCase() === 'onnx'" />
      <JAX v-if="tag.name.toLowerCase() === 'jax'" />
      <PaddlePaddle v-if="tag.name.toLowerCase() === 'paddlepaddle'" />
      <GGUF v-if="tag.name.toLowerCase() === 'gguf'" />
      <Joblib v-if="tag.name.toLowerCase() === 'joblib'" />
    </a>
    <MoreTags
      v-if="theFrameworkTags.moreTags"
      :num="frameworkTags.length - 3"
      target="framework"
      @view-more-targets="viewMoreTargets"
    /> -->
    <!-- Language -->
    <div
      v-if="languageTags.length"
      class="flex items-center text-sm text-[#909399]"
    >
      {{ $t('all.languages') }}:
    </div>
    <a
      v-for="tag in theLanguageTags.theTags"
      :href="`/${prefix}?tag=${tag.label}&tag_type=Language`"
      :style="`color: ${tag.color}`"
      class="text-sm text-[#087443] px-[8px] py-[4px] rounded cursor-pointer flex items-center gap-1 bg-[#F6FEF9]"
    >
      <SvgIcon name="language_tag" />
      {{ this.$i18n.locale === 'zh' ? tag.zh_name || tag.name : tag.name }}
    </a>
    <MoreTags
      v-if="theLanguageTags.moreTags"
      :num="languageTags.length - 3"
      target="language"
      @view-more-targets="viewMoreTargets"
    />
    <!-- industryTags -->
    <!-- <div
      v-if="industryTags.length"
      class="text-sm text-[#909399]"
    >
      {{ $t('all.industry') }}:
    </div>
    <div
      v-for="tag in theIndustryTags.theTags"
      class="tagItem"
    >
      {{ tag.show_name || tag.name }}
    </div>
    <MoreTags
      v-if="theIndustryTags.moreTags"
      :num="industryTags.length - 3"
      target="industry"
      @view-more-targets="viewMoreTargets"
    /> -->
    <!-- Other -->
    <!-- <div
      v-if="otherTags.length"
      class="text-sm text-[#909399]"
    >
      {{ $t('all.others') }}:
    </div>
    <div
      v-for="tag in theOtherTags.theTags"
      class="text-sm text-[#303133] px-[8px] py-[4px] rounded flex items-center border gap-1"
    >
      {{ tag.name }}
    </div> -->
    <!-- <MoreTags
      v-if="theOtherTags.moreTags"
      :num="otherTags.length - 3"
      target="other"
      @view-more-targets="viewMoreTargets"
    /> -->
    <!-- License -->
    <!-- <div
      v-if="licenseTags.length"
      class="text-sm text-[#909399]"
    >
      License:
    </div>
    <a
      v-for="tag in theLicenseTags.theTags"
      :href="`/${prefix}?tag=${tag.name}&tag_type=License`"
      class="text-sm text-[#303133] px-[8px] py-[3px] rounded cursor-pointer flex items-center border gap-1"
    >
      <SvgIcon name="repo_header_license_icon" />
      {{ tag.zh_name }}
    </a>
    <MoreTags
      v-if="theLicenseTags.moreTags"
      :num="licenseTags.length - 3"
      target="license"
      @view-more-targets="viewMoreTargets"
    /> -->
  </div>
</template>

<script setup>
import {computed, ref,onMounted,nextTick} from 'vue'
  import PyTorch from '../../components/tags/frameworks/PyTorch.vue'
  import TensorFlow from '../../components/tags/frameworks/TensorFlow.vue'
  import Safetensors from '../../components/tags/frameworks/Safetensors.vue'
  import JAX from '../../components/tags/frameworks/JAX.vue'
  import ONNX from '../../components/tags/frameworks/ONNX.vue'
  import PaddlePaddle from '../../components/tags/frameworks/PaddlePaddle.vue'
  import GGUF from '../../components/tags/frameworks/GGUF.vue'
  import Joblib from '../../components/tags/frameworks/Joblib.vue'
  import { useI18n } from 'vue-i18n'
  import TagItem from '../tags/TagItem.vue'
  import MoreTags from './MoreTags.vue'

  const props = defineProps({
    taskTags: Array,
    frameworkTags: Array,
    languageTags: Array,
    licenseTags: Array,
    industryTags: Array,
    otherTags: Array,
    prefix: {
      type: String,
      default: ''
    }
  })
  
  const { t, locale } = useI18n()
  const ItemTags = ref([]) 
  const TagItemBox = ref(null)
  const hasMore = ref(false)// 是否有更多盒子需要显示
const VisibleTag = ref([])  // 当前显示的盒子
const hideTage = ref([])// 当前隐藏的盒子
  //先定义一个生产参数的方法
  const createTagRefs = (tagType) => {
    const moreTags = ref(props[`${tagType}Tags`]?.length > 3)
    const theTags = ref(props[`${tagType}Tags`]?.slice(0, 3))
    return { moreTags, theTags }
  }

  const getTags = () => {
    ItemTags.value = [...props.taskTags,...props.industryTags]
    nextTick(()=>{
      getVisibleTagBox()
    })
  }

  // 获取文字宽度  + 14 为左右边距
const getTextWidth = (text, font = '14px PingFang SC') => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  context.font = font;
  const metrics = context.measureText(text);
  return metrics.width + 14;
}

  const getVisibleTagBox = () => {
  let totalWidth = 0;
  const maxWidth = TagItemBox.value.offsetWidth - 40; // 获取容器宽度
  VisibleTag.value = ItemTags.value.filter((box => {
    if(box.show_name){
      // 每个盒子有8的边距
      const boxWidth = getTextWidth(box.show_name) + 8
      const willFit = totalWidth + boxWidth <= maxWidth;
      if (willFit) {
        totalWidth +=boxWidth;
      }
      return willFit;
    }
  }))
  hasMore.value = ItemTags.value.length > VisibleTag.value.length;
  if(hasMore.value) {
    // 隐藏的盒子取显示盒子后面的数据
    const uniqueData = ItemTags.value.filter((item) => {
      const found = VisibleTag.value.find((i) => i.name === item.name);
      return !found;
    });
    hideTage.value = uniqueData
  }
  // console.log(hasMore.value)
}

  const theTaskTags = ref(createTagRefs('task'))
  const theFrameworkTags = ref(createTagRefs('framework'))
  const theLanguageTags = ref(createTagRefs('language'))
  const theLicenseTags = ref(createTagRefs('license'))
  const theIndustryTags = ref(createTagRefs('industry'))
  const theOtherTags = ref(createTagRefs('other'))

  const tagGroups = {
    task: theTaskTags,
    framework: theFrameworkTags,
    language: theLanguageTags,
    license: theLicenseTags,
    industry: theIndustryTags,
    other: theOtherTags
  }

  const tagName = (tag) => {
    return locale.value === 'en' ? tag.name.replace(/-/g, ' ') :tag.show_name || tag.zh_name
  }

  const viewMoreTargets = (target) => {
    const tagRef = tagGroups[target]
    if (tagRef) {
      tagRef.value.theTags = props[`${target}Tags`]
      tagRef.value.moreTags = false
    }
  }

  const searchByTag = (tag) => {
    window.location.href = `/${props.prefix}?tag=${tag.name}&tag_type=Task`
  }
  onMounted(() => {
    getTags()
  })
</script>
<style scoped>
.tagItem{
  padding: 3px 8px;
  gap: 12px;
  border-radius: 4px ;
  border: 1px solid #C9CDD4;
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #6B7785;
}
.formatTaskTags{
  width: 50%;
  height: 26px;
  /* margin: 16px 0; */
  display: flex;
  gap: 8px;
}
.popoverCard {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
</style>
