<template>
  <div :style="{minHeight: innerHeight + 'px'}" class="AllSpecial">
    <speciaListTag :item="item" v-for="item in SpecialList" :key="item.id"></speciaListTag>
  </div>
</template>
<script setup>
import { ref, onMounted,inject } from "vue"
import speciaListTag from './components/speciaListTag.vue'
import { ElMessage } from "element-plus";

const innerHeight = ref(0)
const csghubServer = inject('csghubServer')
const SpecialList = ref({})
const backColors = ref([
  {style:{background: "#5B8FF91A",boxShadow: "0px 0px 32px 0px #5B8FF933 inset"},imgColor:'#2047D3'},
  {style:{background: "#9580F91A",boxShadow: "0px 0px 32px 0px #9580F933 inset"},imgColor:'#9580F9'},
  {style:{background: "#FA61781A",boxShadow: "0px 0px 32px 0px #FA617833 inset"},imgColor:'#FA6178'},
  {style:{background: "#E8794A1A",boxShadow: "0px 0px 32px 0px #E8794A33 inset"},imgColor:'#E8794A'},
  {style:{background: "#5AD8A629",boxShadow: "0px 0px 32px 0px #5AD8A633 inset"},imgColor:'#5AD8A6'},
  {style:{background: "#5D70921A",boxShadow: "0px 0px 32px 0px #5D709233 inset"},imgColor:'#99A3B5'},
])

const getInnerHeight = () => {
  innerHeight.value = window.innerHeight - 144
  // console.log(innerHeight)
}

const getAllSpecial = async () => {
  const response = await fetch(
      `${csghubServer}/api/v1/topics`
  )
  if (response.ok) {
    response.json().then((body) => {
      SpecialList.value = body.data ? body.data : []
      let index = 0
      SpecialList.value.forEach((item) => {
        item.style = backColors.value[index]
        index++
        if(index === 6) index = 0
      })
      console.log(SpecialList.value)
    })
  } else {
    ElMessage.error('数据繁忙请稍后在试！')
  }
}

onMounted(() => {
  getAllSpecial()
  getInnerHeight()
})
</script>
<style scoped>
.AllSpecial{
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  gap: 16px;
}
</style>