import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, normalizeStyle as _normalizeStyle } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle({minHeight: $setup.innerHeight + 'px'}),
    class: "AllSpecial"
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.SpecialList, (item) => {
      return (_openBlock(), _createBlock($setup["speciaListTag"], {
        item: item,
        key: item.id
      }, null, 8 /* PROPS */, ["item"]))
    }), 128 /* KEYED_FRAGMENT */))
  ], 4 /* STYLE */))
}