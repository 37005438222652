import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_option = _resolveComponent("el-option")
  const _component_el_select = _resolveComponent("el-select")

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_select, {
      modelValue: $setup.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.value) = $event)),
      class: "selectClass",
      placeholder: "Select",
      size: "large",
      style: {"width":"320px"},
      onChange: $setup.handleClick
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.branches, (branch) => {
          return (_openBlock(), _createBlock(_component_el_option, {
            key: branch.name,
            label: branch.name,
            value: branch.name
          }, null, 8 /* PROPS */, ["label", "value"]))
        }), 128 /* KEYED_FRAGMENT */))
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue"]),
    _createCommentVNode(" <el-dropdown class=\"select\">\n      <el-button type=\"default\" class=\"selectButton\">\n        {{ currentBranch }}\n        <el-icon class=\"ml-1 el-icon--right\">\n          <arrow-down />\n        </el-icon>\n      </el-button>\n      <template #dropdown>\n        <el-dropdown-menu>\n          <el-dropdown-item v-for=\"branch in branches\" :key=\"branch.name\" @click=\"handleClick(branch.name)\">\n            {{ branch.name }}\n          </el-dropdown-item>\n        </el-dropdown-menu>\n      </template>\n    </el-dropdown> ")
  ]))
}