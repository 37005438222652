<template>
  <div class="pubic-main">
    <div class="login-pubic">
      <img src="/images/login-public.png" alt="" class="backPublic" />
      <img src="/images/opencsg_logo.png" alt="" class="backLogo">
    </div>
    <div class="from-box">
      <div>
        <span class="messages">
          欢迎{{label}}算法仓库
        </span>
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script setup>
const props = defineProps({
  label: String
})
</script>
<style scoped>
.pubic-main {
  display: flex;
  width: 100%;
  height: 100%;
}

.login-pubic {
  width: calc(100% - 560px);
  height: 100%;
  position: relative;
}

.backPublic {
  width: 100%;
  height: 100%;
}

.from-box {
  height: 100%;
  width: 560px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.messages {
  font-family: PingFang SC;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  color: #1D2129;
  margin-bottom: 32px;
  display: inline-block;
}
.backLogo{
  position: absolute;
  top: 66px;
  left: 66px;
}
</style>