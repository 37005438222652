<template>
  <div class="modelBack" :style="{ height: isRoll ? '156px' : '222px' }" />
  <div class="w-full mode-header">
    <div class="mx-auto flex justify-between">
      <repo-header :private="dataset.data.private" :license="dataset.data.license" :name="dataset.data.name"
        :nickname="dataset.data.nickname" :desc="dataset.data.description" :path="dataset.data.path" :avatar="avatar"
        :tags="tags" :owner-url="ownerUrl" :updatedAt="dataset.data.updated_at" :repoId="dataset.data.repository_id"
        :totalLikes="dataset.data.likes" :hasLike="dataset.data.user_likes" :totalDownloads="dataset.data.downloads" repo-type="dataset" :isRoll="isRoll" class="content-box" />
      <div class="button-box flex">
        <RepoClone repoType="dataset" :httpCloneUrl="dataset.data.repository.http_clone_url"
          :sshCloneUrl="dataset.data.repository.ssh_clone_url" :userName="userName" :namespacePath="dataset.data.path"
          :admin="admin" :repo="dataset.data" :enableEndpoint="dataset.data.enable_inference"
          :enableFinetune="dataset.data.enable_finetune" :improveInformation="improveInformation" />
        <quotePlatform repoType="dataset" :modelName="dataset.data.name" :modelPath="dataset.data.path" :userName="userName" />
        <Collection :hasLike="dataset.data.user_likes" :name="dataset.data.name" :repoId="dataset.data.repository_id" />
      </div>
    </div>
    <tab-container :default-tab="defaultTab" :settingsVisibility="settingsVisibility" repoType="dataset"
                   :localRepoId="localRepoId" :repo="dataset.data" :userName="userName" />
  </div>
  <div class="mx-auto xl:px-10 md:px-0">
    <repo-tabs :blob="blob.data" :local-repo-id="localRepoId" :repo-detail="dataset.data" :last-commit="lastCommit.data"
      :branches="branches.data" :current-branch="currentBranch" :current-path="currentPath" :default-tab="defaultTab"
      :actionName="actionName" :settingsVisibility="settingsVisibility" :can-write="canWrite" :tags="tags"
      :tag-list="tagList" :userName="userName" repo-type="dataset" :commitId="commitId" />
  </div>
</template>

<script setup>
import { ref,onMounted,onBeforeUnmount } from 'vue'
import RepoHeader from '../shared/RepoHeader.vue';
import RepoTabs from '../shared/RepoTabs.vue'
import useRepoDetailStore from '../../stores/RepoDetailStore'
import RepoClone from "../shared/RepoClone.vue";
import TabContainer from "../shared/TabContainer.vue";
import quotePlatform from '../shared/quotePlatform.vue'
import Collection from '../shared/Collection.vue'

const props = defineProps({
  dataset: Object,
  files: Object,
  lastCommit: Object,
  branches: Object,
  localRepoId: String,
  currentBranch: String,
  currentPath: String,
  defaultTab: String,
  blob: Object,
  actionName: String,
  avatar: String,
  settingsVisibility: Boolean,
  tags: Object,
  tagList: Object,
  ownerUrl: String,
  canWrite: Boolean,
  userName: String,
  commitId: String,
  improveInformation: Boolean,
})
console.log(props)
const repoDetailStore = useRepoDetailStore()
repoDetailStore.initialize(props.dataset.data)
const isRoll = ref(false)
const handleScroll = () => {
    // 获取滚动条距离顶部的距离
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;

    if (scrollTop > 60 && !isRoll.value) {
    isRoll.value = true;
  } else if (scrollTop <= 60 && isRoll.value) {
    isRoll.value = false;
  }
}

function throttle(func, limit) {
  let inThrottle;
  return function() {
    const args = arguments;
    const context = this;
    if (!inThrottle) {
      func.apply(context, args);
      inThrottle = true;
      setTimeout(() => inThrottle = false, limit);
    }
  }
}1
onMounted(() => {
  window.addEventListener("scroll", throttle(handleScroll,100))
})
onBeforeUnmount(() =>{
  window.removeEventListener("scroll", handleScroll)
})
</script>

<style scoped>
.button-box {
  width: 450px;
  margin-top: 4px;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

.content-box {
  width: calc(100% - 450px);
}
</style>
