import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-38f933cb"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "w-full mode-header" }
const _hoisted_2 = { class: "mx-auto flex justify-between" }
const _hoisted_3 = { class: "button-box flex" }
const _hoisted_4 = { class: "mx-auto xl:px-10 md:px-0" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: "modelBack",
      style: _normalizeStyle({ height: $setup.isRoll ? '156px' : '222px' })
    }, null, 4 /* STYLE */),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode($setup["RepoHeader"], {
          private: $props.dataset.data.private,
          license: $props.dataset.data.license,
          name: $props.dataset.data.name,
          nickname: $props.dataset.data.nickname,
          desc: $props.dataset.data.description,
          path: $props.dataset.data.path,
          avatar: $props.avatar,
          tags: $props.tags,
          "owner-url": $props.ownerUrl,
          updatedAt: $props.dataset.data.updated_at,
          repoId: $props.dataset.data.repository_id,
          totalLikes: $props.dataset.data.likes,
          hasLike: $props.dataset.data.user_likes,
          totalDownloads: $props.dataset.data.downloads,
          "repo-type": "dataset",
          isRoll: $setup.isRoll,
          class: "content-box"
        }, null, 8 /* PROPS */, ["private", "license", "name", "nickname", "desc", "path", "avatar", "tags", "owner-url", "updatedAt", "repoId", "totalLikes", "hasLike", "totalDownloads", "isRoll"]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode($setup["RepoClone"], {
            repoType: "dataset",
            httpCloneUrl: $props.dataset.data.repository.http_clone_url,
            sshCloneUrl: $props.dataset.data.repository.ssh_clone_url,
            userName: $props.userName,
            namespacePath: $props.dataset.data.path,
            admin: _ctx.admin,
            repo: $props.dataset.data,
            enableEndpoint: $props.dataset.data.enable_inference,
            enableFinetune: $props.dataset.data.enable_finetune,
            improveInformation: $props.improveInformation
          }, null, 8 /* PROPS */, ["httpCloneUrl", "sshCloneUrl", "userName", "namespacePath", "admin", "repo", "enableEndpoint", "enableFinetune", "improveInformation"]),
          _createVNode($setup["quotePlatform"], {
            repoType: "dataset",
            modelName: $props.dataset.data.name,
            modelPath: $props.dataset.data.path,
            userName: $props.userName
          }, null, 8 /* PROPS */, ["modelName", "modelPath", "userName"]),
          _createVNode($setup["Collection"], {
            hasLike: $props.dataset.data.user_likes,
            name: $props.dataset.data.name,
            repoId: $props.dataset.data.repository_id
          }, null, 8 /* PROPS */, ["hasLike", "name", "repoId"])
        ])
      ]),
      _createVNode($setup["TabContainer"], {
        "default-tab": $props.defaultTab,
        settingsVisibility: $props.settingsVisibility,
        repoType: "dataset",
        localRepoId: $props.localRepoId,
        repo: $props.dataset.data,
        userName: $props.userName
      }, null, 8 /* PROPS */, ["default-tab", "settingsVisibility", "localRepoId", "repo", "userName"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode($setup["RepoTabs"], {
        blob: $props.blob.data,
        "local-repo-id": $props.localRepoId,
        "repo-detail": $props.dataset.data,
        "last-commit": $props.lastCommit.data,
        branches: $props.branches.data,
        "current-branch": $props.currentBranch,
        "current-path": $props.currentPath,
        "default-tab": $props.defaultTab,
        actionName: $props.actionName,
        settingsVisibility: $props.settingsVisibility,
        "can-write": $props.canWrite,
        tags: $props.tags,
        "tag-list": $props.tagList,
        userName: $props.userName,
        "repo-type": "dataset",
        commitId: $props.commitId
      }, null, 8 /* PROPS */, ["blob", "local-repo-id", "repo-detail", "last-commit", "branches", "current-branch", "current-path", "default-tab", "actionName", "settingsVisibility", "can-write", "tags", "tag-list", "userName", "commitId"])
    ])
  ], 64 /* STABLE_FRAGMENT */))
}