<template>
  <div>
    <el-select v-model="value" class="selectClass" placeholder="Select" size="large" style="width: 320px" @change="handleClick">
      <el-option v-for="branch in branches" :key="branch.name" :label="branch.name" :value="branch.name" />
    </el-select>
    <!-- <el-dropdown class="select">
      <el-button type="default" class="selectButton">
        {{ currentBranch }}
        <el-icon class="ml-1 el-icon--right">
          <arrow-down />
        </el-icon>
      </el-button>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item v-for="branch in branches" :key="branch.name" @click="handleClick(branch.name)">
            {{ branch.name }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown> -->
  </div>
</template>

<script setup>
import { ref } from 'vue'

const props = defineProps({
  currentBranch: String,
  branches: Array
})
const value = ref(props.currentBranch)
const emit = defineEmits(['changeBranch'])

const handleClick = (branch) => {
  emit('changeBranch', branch)
}
</script>
<style scoped>
.select {
  width: 320px;
}

.selectButton {
  width: 100%;
  padding: 8px;
}

.selectButton /deep/ span {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.selectClass{
  height: 32px;
}
.selectClass /deep/ .el-select__wrapper.el-tooltip__trigger.el-tooltip__trigger{
  min-height: auto;
  gap: 0;
}
</style>
