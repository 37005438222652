import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();
import { ElMessageBox } from "element-plus";
import { user_sessions as sessions_en } from "../../../config/locales/en_js/user_sessions.js"
import { user_sessions as sessions_zh } from "../../../config/locales/zh_js/user_sessions.js"

const goLogin = () => {
  const sessionLocale = cookies.get('locale') === 'en' ? sessions_en : sessions_zh

  ElMessageBox.alert(sessionLocale.expiredDesc, sessionLocale.expiredTitle, {
    'show-close': false,
    confirmButtonText: sessionLocale.reLogin,
    callback: () => {
      window.location.href = "/login"
    },
  })
}

const jwtFetch = async (url, options = {}, forceLogin = false) => {
  const jwtToken = cookies.get('user_token')
  if (forceLogin && !jwtToken) {
    goLogin()
  }
  options.headers = options.headers || {}
  if (jwtToken) {
    options.headers = { "Authorization": `Bearer ${jwtToken}`, ...options.headers };
  }
  const response = await fetch(url, options)
  // 全局抱错捕捉
  if (response.ok) return response
  // 判断是否为token抱错
  const err = await response.json()
  // 原response.json只能调用一次，从新填充json方法
  const json = () => {
    return new Promise((resolve, reject) => {
      resolve(err)
    })
  }
  if (err.code === 401) {
    goLogin()
  }
  // 抱错返回
  return { ...response, json }
};

export default jwtFetch;