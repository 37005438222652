import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9b73bd81"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_button = _resolveComponent("el-button")

  return (_openBlock(), _createBlock(_component_el_button, {
    class: "Default",
    onClick: $setup.clickLike
  }, {
    default: _withCtx(() => [
      _createElementVNode("img", {
        src: !$setup.userLiked ? '/images/Collection.png' : '/images/Collection-like.png',
        class: "img"
      }, null, 8 /* PROPS */, _hoisted_1),
      _createTextVNode(" 收藏 ")
    ]),
    _: 1 /* STABLE */
  }))
}