<template>
  <div class="modelBack" :style="{height: isRoll ? '156px' : '222px' }" />
  <div class="w-full mode-header">
    <div class="mx-auto flex justify-between">
      <repo-header :license="model.data.license" :name="model.data.name" :nickname="model.data.nickname"
        :desc="model.data.description" :path="model.data.path" :tags="tags" :avatar="avatar" :owner-url="ownerUrl"
        :updatedAt="model.data.updated_at" :repoId="model.data.repository_id" :totalLikes="model.data.likes"
        :totalDownloads="model.data.downloads" :hasLike="model.data.user_likes" repo-type="model" :isRoll="isRoll" class="content-box" />
      <div class="button-box flex">
        <RepoClone repoType="model" :httpCloneUrl="model.data.repository.http_clone_url"
          :sshCloneUrl="model.data.repository.ssh_clone_url" :userName="userName" :namespacePath="model.data.path"
          :admin="admin" :repo="model.data" :enableEndpoint="model.data.enable_inference"
          :enableFinetune="model.data.enable_finetune" :improveInformation="improveInformation" />
        <quotePlatform repoType="model" :modelName="model.data.name" :modelPath="model.data.path" :userName="userName" />
        <onlineTrial :local-repo-id="localRepoId" :modelPath="model.data.path" />
        <Collection :hasLike="model.data.user_likes" :name="model.data.name" :repoId="model.data.repository_id" />
      </div>
    </div>
    <tab-container :default-tab="defaultTab" :settingsVisibility="settingsVisibility" repoType="model"
                   :localRepoId="localRepoId" :repo="model.data" :userName="userName" />
  </div>
  <div class="mx-auto xl:px-10 md:px-0">
    <repo-tabs :blob="blob.data" :local-repo-id="localRepoId" :repo-detail="model.data" :last-commit="lastCommit.data"
      :branches="branches.data" :current-branch="currentBranch" :current-path="currentPath" :default-tab="defaultTab"
      :actionName="actionName" :settingsVisibility="settingsVisibility" :can-write="canWrite" :tags="tags"
      :tag-list="tagList" :userName="userName" :commitId="commitId" repo-type="model" :admin="admin" />
  </div>
</template>

<script setup>
import { ref,onMounted,onBeforeUnmount } from 'vue'
import RepoHeader from '../shared/RepoHeader.vue'
import RepoTabs from '../shared/RepoTabs.vue'
import useRepoDetailStore from '../../stores/RepoDetailStore'
import RepoClone from "../shared/RepoClone.vue";
import onlineTrial from '../shared/onlineTrial.vue'
import quotePlatform from '../shared/quotePlatform.vue'
import TabContainer from "../shared/TabContainer.vue";
import Collection from '../shared/Collection.vue'

const props = defineProps({
  localRepoId: String,
  defaultTab: String,
  model: Object,
  tagList: Object,
  localModel: Object,
  lastCommit: Object,
  branches: Object,
  tags: Object,
  currentBranch: String,
  currentPath: String,
  blob: Object,
  actionName: String,
  settingsVisibility: Boolean,
  avatar: String,
  ownerUrl: String,
  canWrite: Boolean,
  userName: String,
  commitId: String,
  admin: Boolean,
  improveInformation: Boolean,
})
console.log(props)
const repoDetailStore = useRepoDetailStore()
repoDetailStore.initialize(props.model.data)
const scrollContainer = ref(null)
const isRoll = ref(false)
const handleScroll = () => {
    // 获取滚动条距离顶部的距离
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;

    if (scrollTop > 60 && !isRoll.value) {
    isRoll.value = true;
  } else if (scrollTop <= 60 && isRoll.value) {
    isRoll.value = false;
  }
}

function throttle(func, limit) {
  let inThrottle;
  return function() {
    const args = arguments;
    const context = this;
    if (!inThrottle) {
      func.apply(context, args);
      inThrottle = true;
      setTimeout(() => inThrottle = false, limit);
    }
  }
}1
onMounted(() => {
  window.addEventListener("scroll", throttle(handleScroll,100))
})
onBeforeUnmount(() =>{
  window.removeEventListener("scroll", handleScroll)
})
</script>

<style scoped>
.button-box {
  width: 450px;
  margin-top: 4px;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

.content-box {
  width: calc(100% - 450px);
}
</style>