<template>
  <span ref="TagItem" class="px-[8px] py-[2px] rounded-[2px] cursor-pointer flex items-center gap-[10px] tab-class"
        @click="emit('handleTagClick', tag)"
        :style="{backgroundColor: active?color?.color:color?.backgroundColor,color: active ? '#fff':color?.color}">
<!--    <img :src="'/images/tags/' + tag.name + '.svg'"-->
<!--         class="w-[14px] h-[14px]"-->
<!--         :style="active ? 'filter: drop-shadow(1000px 0 0 white); transform: translate(-1000px);' : ''"-->
<!--         alt=""-->
<!--         onerror="this.style.display='none'" />-->
    {{ tagName }}
  </span>
</template>

<script setup>
  import { computed,ref } from 'vue'
  import { useI18n } from 'vue-i18n'

  const props = defineProps({
    tag: Object,
    active: Boolean,
    color: Object
  })
const TagItem = ref(null)
  const { locale } = useI18n()

  const tagName = computed(() => {
    return locale.value === 'en' ? props.tag.name.replace(/-/g, ' ') : props.tag.show_name || props.tag.zh_name
  })

  const emit = defineEmits(['handleTagClick'])
</script>
<style scoped>
.tab-class{
  //styleName: Small Text/CN-Regular;
  font-family: PingFang SC;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}
.tab-class:hover{
  font-weight: 600;
}
</style>
